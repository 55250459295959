import { configureStore } from "@reduxjs/toolkit"
import AuthReducer from "./reducers/AuthReducer"
import userReducer from "./reducers/UserReducer";
import layoutReducer from "./reducers/LayoutReducer";
import stateReducer from "./reducers/StateReducer";
import cityReducer from "./reducers/CityReducer";
import zoneReducer from "./reducers/ZoneReducer";
import userTypeReducer from "./reducers/UserTypeReducer";
import masterReducer from "./reducers/MasterReducer";
import geofenceReducer from "./reducers/GeofenceReducer";
import hospitalReducer from "./reducers/HospitalReducer";
import doctorReducer from "./reducers/DoctorReducer";
import pathologyReducer from "./reducers/PathologyReducer";
import radiologyReducer from "./reducers/RadiologyReducer";
import pharmacyReducer from "./reducers/PharmacyReducer";
import agentBrokerReducer from "./reducers/AgentBrokerReducer";
import corporateReducer from "./reducers/CorporateReducer";
import basicSaveReducer from "./reducers/BasicSaveReducer";
import designationReducer from "./reducers/DesignationReducer";
import educationReducer from "./reducers/EducationReducer";
import incomeReducer from "./reducers/IncomeReducer";
import jobReducer from "./reducers/JobReducer";
import patientDataReducer from "./reducers/PatientDataReducer";
import localityReducer from "./reducers/LocalityReducer";
import occupationReducer from "./reducers/OccupationReducer";
import relationshipReducer from "./reducers/RelationshipReducer";
import checklistReducer from "./reducers/ChecklistReducer";
import subChecklistReducer from "./reducers/SubChecklistReducer";
import typeOfClaimReducer from "./reducers/TypeOfClaimReducer";
import insuranceReducer from "./reducers/InsuranceReducer";
import insuranceManagerReducer from "./reducers/InsuranceManagerReducer";
import roomTypeReducer from "./reducers/RoomTypeReducer";
import hospitalizationReducer from "./reducers/HospitalizationReducer";
import injuryTypeReducer from "./reducers/InjuryTypeReducer";
import generalSettingReducer from "./reducers/GeneralSettingReducer";
import userTypePermissionReducer from "./reducers/UserTypePermissionReducer";
import userPermissionReducer from "./reducers/UserPermissionReducer";
import caseDetailsReducer from "./reducers/CaseDetailsReducer";
import healthReducer from "./reducers/HealthReducer";

import {thunk} from 'redux-thunk';
const Store = configureStore({
    reducer: {
        AuthReducer,
        userReducer,  
        layoutReducer,
        stateReducer,
        cityReducer,
        zoneReducer,
        masterReducer,
        geofenceReducer,
        userTypeReducer,
        generalSettingReducer,
        hospitalReducer,
        doctorReducer,
        pathologyReducer,
        radiologyReducer,
        pharmacyReducer,
        agentBrokerReducer,
        corporateReducer,
        basicSaveReducer,
        patientDataReducer,
        designationReducer,
        educationReducer,
        incomeReducer,
        jobReducer,
        localityReducer,
        occupationReducer,
        relationshipReducer,
        checklistReducer,
        subChecklistReducer,
        typeOfClaimReducer,
        userTypePermissionReducer,
        userPermissionReducer,
        insuranceReducer,
        insuranceManagerReducer,
        roomTypeReducer,
        hospitalizationReducer,
        injuryTypeReducer,
        caseDetailsReducer,
        healthReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
})

export default Store