import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Table, Button, Accordion, Spinner, Form, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getCorporateListById, saveCorporateData, updateCorporateData } from "../../redux/actions/CorporateActions";
import FormInput from "../FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllCityStateWiseList, getAllStateList, getMasterDesignationList } from "../../redux/actions/MasterActions";
import Preloader from "../Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import CustomToggle from "../CustomToggle";
import '../../styles/common.css'

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddCorporate = ({ show, onHide, corporateId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});

  const companyTypeList = [
    {
      id: "Proprietor",
      name: 'Proprietor'
    },
    {
      id: "Partership",
      name: 'Partership'
    },
    {
      id: "NGO",
      name: 'NGO'
    },
    {
      id: "Public LTD",
      name: 'Public LTD'
    },
    {
      id: "Private LTD",
      name: 'Private LTD'
    },
    {
      id: "LLP",
      name: 'LLP'
    }
  ]

  const natureEmploymentList = [
    {
      id: "Permanent",
      name: 'Permanent'
    },
    {
      id: "Contractual",
      name: 'Contractual'
    },
    {
      id: "Part-Time",
      name: 'Part-Time'
    },
    {
      id: "Temporary",
      name: 'Temporary'
    },
    {
      id: "Internship",
      name: 'Internship'
    },
    {
      id: "Apprenticeship",
      name: 'Apprenticeship'
    },
    {
      id: "Freelance",
      name: 'Freelance'
    },
    {
      id: "Consultant",
      name: 'Consultant'
    },
    {
      id: "Probationary",
      name: 'Probationary'
    }
  ]

  const countryList = [
    {
      id: "India",
      name: 'India'
    }
  ]

  const salutationList = [
    {
      id: "Mr",
      name: 'Mr'
    },
    {
      id: "Mrs",
      name: 'Mrs'
    }
  ]

  const registrationList = [
    {
      id: "Employee-Employer",
      name: 'Employee-Employer'
    },
    {
      id: "Bank Account Holder",
      name: 'Bank Account Holder'
    },
    {
      id: "NGO Members",
      name: 'NGO Members'
    },
    {
      id: "Association Members",
      name: 'Association Members'
    }
  ]
  

  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    corporateReducer: {
      loadingCorporate,
      errorsCorporate,
      corporateLists,
      changedCorporateStatus,
    },
    masterReducer: {
      loadingMaster,
      masterStateLists,
      masterCityStateWiseLists,
      masterDesignationList
    },
  } = useSelector((state) => state);


  useEffect(() => {
    if (auth?.token)
    {
      dispatch(getAllStateList(auth.token));
      dispatch(getMasterDesignationList(auth.token));
    }
  }, [auth?.token]);


  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);


  const [validated, setValidated] = useState(false);
  const autocompleteRef = useRef(null);
  

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(corporateId){
      dispatch(updateCorporateData(auth?.token, formData, corporateId));
    } else {
      dispatch(saveCorporateData(auth?.token, formData));
    }
  };


  useEffect(() => {
    dispatch(getAllStateList(auth.token));
    setFormData({})
    if(corporateId) {
      dispatch(getCorporateListById(auth?.token, corporateId));
    }
  }, [auth, corporateId]);


  useEffect(() => {
    if(Object.keys(placeData).length > 0 && Object.keys(masterCityStateWiseLists).length>0) {
        const addressComponents = placeData?.address_components;
        const city_name = getAddressComponent(addressComponents, 'administrative_area_level_3');
        const city_code=masterCityStateWiseLists?.filter(data => data.city_name == getAddressComponent(addressComponents, 'administrative_area_level_3'))
        setFormData({ ...formData, 'city_code': city_code[0]?.city_code });
    }
  },[masterCityStateWiseLists])


  useEffect(() => {
    if(corporateId && corporateLists && Object.keys(corporateLists).length > 0) {
      setFormData({
        lab_name: corporateLists?.lab_name,
        lat: corporateLists?.lat,
        lng: corporateLists?.lng,
        address: corporateLists?.address,
        email: corporateLists?.email || "",
        mobile_number: corporateLists?.mobile_number,
        tpa_name: corporateLists?.tpa_name,
        tpa_number: corporateLists?.tpa_number,
        state_code: corporateLists?.lab_cities?.cities_state?.state_code,
        city_code: corporateLists?.lab_cities?.city_code,
      });
    }
  }, [corporateLists]);


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide();
    }
  }, [errors, dispatch, onHide]);


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };


  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };


  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);


  const handleInputChange = async (e) => {
    const input = e.target.value;
    setFormData({ ...formData, search_name: input });

    if (input.length > 2)
    {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({
        input,
        componentRestrictions: { country: "IN" },
        types: ["doctor"],
      }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
          setShowSuggestions(true);
        }
      });
    }
    else
    {
      setShowSuggestions(false);
    }
  };


  const handlePlaceSelected = async (placeId) => {
    const service = new window.google.maps.places.PlacesService(document.createElement("div"));
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setPlaceData(place)
        const addressComponents = place?.address_components;
        const state = getAddressComponent(addressComponents, 'administrative_area_level_1');
        const city = getAddressComponent(addressComponents, 'administrative_area_level_3');
        const state_code=masterStateLists?.filter(state => state.state_name == getAddressComponent(addressComponents, 'administrative_area_level_1'))
        setFormData({
          ...formData,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          lab_name: place.name || "",
          mobile_number: (place.formatted_phone_number || "").replace(/\s/g, '').replace(/^0/, ''),
          address: place.formatted_address || "",
          state_code:state_code[0]?.state_code,
          city_name: city || "",
        });
        setShowSuggestions(false);
      }
    });
  };


  const getAddressComponent = (components, type) => {
    const component = components.find(comp => comp.types.includes(type));
    return component ? component.long_name : '';
  };


  useEffect(() => {
    if (changedCorporateStatus?.success)
    {
      onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedCorporateStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }, [changedCorporateStatus, dispatch]);



  const formattedExpiryDate = formData.expiry_date ? formData.expiry_date.split('T')[0] : '';
  const formattedRenewalDate = formData.renewal_date ? formData.renewal_date.split('T')[0] : '';


  return (
    <Modal show={show} onHide={onHide} size="xl" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add or Edit Corporate")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorsCorporate?.data?.message && (
          <div className="alert alert-danger">
            {errorsCorporate?.data?.message}
          </div>
        )}

        <Accordion id="accordion" className="mb-3">
          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey="0"
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Basic Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={`Name of Corporate`}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.name_of_corporate}
                      name="name_of_corporate"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Row>
                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Corporate </span>
                  <Col lg={4}>
                    <FormInput
                      label={t("Pincode")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_pincode}
                      name="hospital_pincode"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Country")}</Form.Label>
                      <Select
                        options={countryList}
                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                        name="country_name"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "country_name")
                        }}
                        styles={null}
                      />
                      
                      {errorsCorporate?.data?.country_name && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsCorporate?.data?.country_name}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                      <Select
                        options={masterStateLists}
                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                        name="state_code"
                        labelField={"state_name"}
                        valueField={"state_code"}
                        searchBy={"state_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.state_code, "state_code")
                          setStateId(selectedData[0]?.state_code);
                        }}
                        styles={null}
                      />
                      
                      {errorsCorporate?.data?.state_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsCorporate?.data?.state_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("District")}</Form.Label>
                      <Select
                        options={masterCityStateWiseLists}
                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                        name="city_code"
                        labelField={"city_name"}
                        valueField={"city_code"}
                        searchBy={"city_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.city_code, "city_code")
                        }}
                        styles={null}
                      />
                      
                      {errorsCorporate?.data?.city_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsCorporate?.data?.city_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Taluka / Sub-District")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Village / City / Town")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Flat / House Number / Building Name")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Street Name / Road Name")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Area / Locality")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Landmark")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={`Corporate Contact No.`}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.treating_last_name}
                      name="treating_last_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={4}>
                    
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`Corporate Email ID`}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.lab_name}
                      name="lab_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={4}>
                    
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`Number of Employee`}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.agent_photo}
                      name="agent_photo"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Row>
                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px", marginTop: "14px"}}>HR Manager Details </span>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{`HR Manager Salutation`}</Form.Label>
                      <Select
                        options={salutationList}
                        values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                        name="issuing_salutation"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "issuing_salutation")
                        }}
                        styles={null}
                      />
                      
                      {errorsCorporate?.data?.issuing_salutation && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsCorporate?.data?.issuing_salutation}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`HR Manager First Name`}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_owner_first}
                      name="hospital_owner_first"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`HR Manager Middle Name`}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_owner_middle}
                      name="hospital_owner_middle"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`HR Manager Last Name`}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_owner_last}
                      name="hospital_owner_last"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`HR Manager Contact No.`}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.treating_last_name}
                      name="treating_last_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`HR Manager Email ID`}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.lab_name}
                      name="lab_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={4}>
                    
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`GPS Photo`}
                      type="file"
                      errors={errorsCorporate?.data}
                      value={formData.agent_photo}
                      name="agent_photo"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Accordion>
                  <Card className="mt-3">
                    <Card.Header>
                      <CustomToggle
                        eventKey="0-1"
                        containerClass="m-0"
                        linkClass="text-dark"
                      >
                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                        HR Manager Social Media
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0-1">
                      <Card.Body>
                        <Row>
                          <Col lg={4}>
                            <FormInput
                              label={t("Whatsapp")}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.hospital_whatsapp_link}
                              name="hospital_whatsapp_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Instagram Link")}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.hospital_instagram_link}
                              name="hospital_instagram_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Facebook Link")}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.hospital_facebook_link}
                              name="hospital_facebook_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Linkdin Link")}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.hospital_linkdin_link}
                              name="hospital_linkdin_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Twitter Link")}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.hospital_twitter_link}
                              name="hospital_twitter_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Justdial Link")}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.hospital_jusrtdial_link}
                              name="hospital_jusrtdial_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Truecaller Link")}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.hospital_truecaller_link}
                              name="hospital_truecaller_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Eyecon Link")}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.hospital_eyecon_link}
                              name="hospital_eyecon_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Other Link")}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.hospital_eyecon_link}
                              name="hospital_eyecon_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={1}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Company Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={1}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Establish Year")}
                      type="number"
                      errors={errorsCorporate?.data}
                      value={formData.establish_year}
                      name="establish_year"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                      min={1900}
                      max={new Date().getFullYear()}
                      placeholder="YYYY"
                    />
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Company Type")}</Form.Label>
                      <Select
                        options={companyTypeList}
                        values={companyTypeList?.filter((comp) => comp?.id === formData?.company_type) || []}
                        name="company_type"
                        placeholder={t("Select Company Type")}
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "company_type")
                        }}
                      />
                      {errorsCorporate?.data?.company_type && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">
                            {errorsCorporate?.data?.company_type}
                          </p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Accordion>
                  <Card className="mt-3">
                    <Card.Header>
                      <CustomToggle
                        eventKey={1-1}
                        containerClass="m-0"
                        linkClass="text-dark"
                      >
                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                        Director of Company Details
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={1-1}>
                      <Card.Body>
                        <Row>
                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{`Director of Company Salutation`}</Form.Label>
                              <Select
                                options={salutationList}
                                values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                                name="issuing_salutation"
                                labelField={"name"}
                                valueField={"id"}
                                searchBy={"name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.id, "issuing_salutation")
                                }}
                                styles={null}
                              />
                              
                              {errorsCorporate?.data?.issuing_salutation && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsCorporate?.data?.issuing_salutation}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={`Director of Company First Name`}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.hospital_owner_first}
                              name="hospital_owner_first"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={`Director of Company Middle Name`}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.hospital_owner_middle}
                              name="hospital_owner_middle"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={`Director of Company Last Name`}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.hospital_owner_last}
                              name="hospital_owner_last"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Designation of Director")}</Form.Label>
                              <Select
                                options={masterDesignationList}
                                values={masterDesignationList?.filter(state => state.id === formData.designation_id)}
                                name="designation_id"
                                placeholder={t("Select Designation of Director")}
                                labelField={"name"}
                                valueField={"id"}
                                searchBy={"name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.id, "designation_id")
                                }}
                              />
                              
                              {errorsCorporate?.data?.designation_id && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsCorporate?.data?.designation_id}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={`Director of Company Contact No.`}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.treating_last_name}
                              name="treating_last_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4} style={{marginTop: '28px'}}>
                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                              {t("Save")}
                            </Button>
                          </Col>

                          <Col lg={4}>
                            
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={`Director of Company Email ID`}
                              type="text"
                              errors={errorsCorporate?.data}
                              value={formData.lab_name}
                              name="lab_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4} style={{marginTop: '28px'}}>
                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                              {t("Save")}
                            </Button>
                          </Col>

                          <Col lg={4}>
                            
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={`Photo of Director`}
                              type="file"
                              errors={errorsCorporate?.data}
                              value={formData.agent_photo}
                              name="agent_photo"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>
                        </Row>

                        <div className="d-flex justify-content-center">
                          {loading ? 
                            <Button color="primary" disabled>
                              <Spinner animation="border" size="sm" />
                              {t("Please wait ...")}
                            </Button>
                          :
                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                              {t("Submit")}
                            </Button>
                          }
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={`Company Registration Number`}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.company_registration_number}
                      name="company_registration_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`Corporate Identification Number (CIN)`}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.corporate_identification_number}
                      name="corporate_identification_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{`Group Relationship`}</Form.Label>
                      <Select
                        options={registrationList}
                        values={registrationList?.filter(regis => regis?.id === formData?.type_of_registration)}
                        name="type_of_registration"
                        placeholder={t("Select Group Relationship")}
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "type_of_registration")
                        }}
                        styles={null}
                      />
                      
                      {errorsCorporate?.data?.type_of_registration && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsCorporate?.data?.type_of_registration}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <label>{t(`Corporate Establish With EPFO`)}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="establish_epso"
                        value="Yes"
                        checked={formData.establish_epso === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="establish_epso"
                        value="No"
                        checked={formData.establish_epso === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Nature of Employment")}</Form.Label>
                      <Select
                        options={natureEmploymentList}
                        values={natureEmploymentList?.filter((nature) => nature?.id === formData?.nature_employment) || []}
                        name="nature_employment"
                        placeholder={t("Select Nature of Employment")}
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "nature_employment")
                        }}
                      />
                      {errorsCorporate?.data?.nature_employment && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">
                            {errorsCorporate?.data?.nature_employment}
                          </p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={2}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Online Verification
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={2}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <div
                      style={{ position: "relative" }}
                      onClick={() => window.open(formData?.rohini_id_link || "https://www.zaubacorp.com/", "_blank")}
                    >
                      <FormInput
                        label={t("Check on Zauba Corporate")}
                        type="text"
                        errors={errorsCorporate?.data}
                        value={formData?.rohini_id_link || "https://www.zaubacorp.com/"}
                        name="rohini_id_link"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                        readOnly
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Zauba Corporate Uplaod")}
                      type="file"
                      errors={errorsCorporate?.data}
                      value={formData.irda_link_upload}
                      name="irda_link_upload"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Zauba Corporate Comment")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.irda_link_comment}
                      name="irda_link_comment"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={3}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Social Media
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={3}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Profesional Website")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_whatsapp_link}
                      name="hospital_whatsapp_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Whatsapp")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_whatsapp_link}
                      name="hospital_whatsapp_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Instagram Link")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_instagram_link}
                      name="hospital_instagram_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Facebook Link")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_facebook_link}
                      name="hospital_facebook_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Linkdin Link")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_linkdin_link}
                      name="hospital_linkdin_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Twitter Link")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_twitter_link}
                      name="hospital_twitter_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Justdial Link")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_justdial_link}
                      name="hospital_justdial_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Eyecon Link")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_eyecon_link}
                      name="hospital_eyecon_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Other Link")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.hospital_eyecon_link}
                      name="hospital_eyecon_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={4}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Alert
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={4}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator Username")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.alert_creator_username}
                      name="alert_creator_username"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator ID")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.alert_creator_id}
                      name="alert_creator_id"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Date & Time of Alert")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.date_time_alert}
                      name="date_time_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Comment on Alert")}
                      type="text"
                      errors={errorsCorporate?.data}
                      value={formData.comment_alert}
                      name="comment_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={5}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Blacklisted
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={5}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <label>{t("Backlisted Status")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="backlisted_status"
                        value="Yes"
                        checked={formData.backlisted_status === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="backlisted_status"
                        value="No"
                        checked={formData.backlisted_status === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Data Upload")}
                      type="file"
                      errors={errorsCorporate?.data}
                      value={formData.blacklisted_insurance_data}
                      name="blacklisted_insurance_data"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={6}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Grievance Management
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={6}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <label>{t("Grievance and Escalation")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Grievance")}
                        name="grievance_status"
                        value="Grievance"
                        checked={formData.grievance_status === "Grievance"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("Escalation")}
                        name="grievance_status"
                        value="Escalation"
                        checked={formData.grievance_status === "Escalation"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                <br />
                {formData.grievance_status && (<>
                  <Row>
                    <Col lg={4}>
                      <FormInput
                        label={t(`Type of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsCorporate?.data}
                        value={formData.type_of_grievance}
                        name="type_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Date of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsCorporate?.data}
                        value={formData.date_of_grievance}
                        name="date_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Notification of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsCorporate?.data}
                        value={formData.notification_of_grievance}
                        name="notification_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Reason of the ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsCorporate?.data}
                        value={formData.reason_of_grievance}
                        name="reason_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Status of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsCorporate?.data}
                        value={formData.status_of_grievance}
                        name="status_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Final Conclusion of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsCorporate?.data}
                        value={formData.final_of_grievance}
                        name="final_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>
                  </Row>
                </>)}

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Modal.Body>
      {loadingCorporate && <Preloader />}
    </Modal>
  );
};

export default AddCorporate;
