import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Table, Button, Accordion, Spinner, Form, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getBasicSaveListById, saveBasicSaveData, updateBasicSaveData } from "../../redux/actions/BasicSaveActions";
import FormInput from "../FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllStateList, getAllCityStateWiseList, getMasterDesignationList, getMasterInsuranceList, 
  getMasterQualificationList, getMasterClaimTypeList, getMasterHospitalList, getAllInsuranceManagerWiseList } from "../../redux/actions/MasterActions";
import Preloader from "../Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import CustomToggle from "../CustomToggle";
import '../../styles/common.css'
import AddHospital from "../../Components/FormComponent/AddHospital";
import AddInsuranceManagerManager from "../../Components/FormComponent/AddInsuranceManager";
import AddInsuranceCompany from "../../Components/FormComponent/AddInsurance";

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddBasicSave = ({ show, onHide, basicSaveId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [formData, setFormData] = useState({});
  const [insuranceId, setInsuranceId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [isClientTypeSelected, setIsClientTypeSelected] = useState(false);

  const clientTypeList = [
    {
      id: "Insurance Company",
      name: 'Insurance Company'
    },
    {
      id: "TPA",
      name: 'TPA'
    },
    {
      id: "Vendor",
      name: 'Vendor'
    },
    {
      id: "Others",
      name: 'Others'
    }
  ]

  const groupClaimsTypeList = [
    {
      id: "Health",
      name: 'Health'
    },
    {
      id: "Life",
      name: 'Life'
    },
    {
      id: "Motor",
      name: 'Motor'
    }
  ]

  const salutationList = [
    {
      id: "Mr",
      name: 'Mr'
    },
    {
      id: "Mrs",
      name: 'Mrs'
    }
  ]

  const countryList = [
    {
      id: "India",
      name: 'India'
    }
  ]


  const [showHospitalModel, setShowHospitalModel] = useState(false);
  const hideHospitalModel = () => {
    setShowHospitalModel(false);
    dispatch(getMasterHospitalList(auth.token));
  };

  const [showInsuranceCompanyModel, setShowInsuranceCompanyModel] = useState(false);
  const hideInsuranceCompanyModel = () => {
    setShowInsuranceCompanyModel(false);
    dispatch(getMasterInsuranceList(auth.token));
  };

  const [showInsuranceManagerModel, setShowInsuranceManagerModel] = useState(false);
  const hideInsuranceManagerModel = () => {
    setShowInsuranceManagerModel(false);
    dispatch(getAllInsuranceManagerWiseList(auth.token, insuranceId));
  };
  

  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    basicSaveReducer: {
      loadingBasicSave,
      errorsBasicSave,
      basicSaveLists,
      changedBasicSaveStatus,
    },
    masterReducer: {
      loadingMaster,
      masterStateLists,
      masterCityStateWiseLists,
      masterInsuranceList,
      masterClaimTypeList,
      masterHospitalList,
      masterDesignationList,
    },
  } = useSelector((state) => state);


  useEffect(() => {
    if (auth?.token)
    {
      dispatch(getAllStateList(auth.token));
      dispatch(getMasterInsuranceList(auth.token));
      dispatch(getMasterQualificationList(auth.token));
      dispatch(getMasterDesignationList(auth.token));
      dispatch(getMasterClaimTypeList(auth.token));
      dispatch(getMasterHospitalList(auth.token));
    }
  }, [auth?.token]);

  useEffect(() => {
      if (auth?.token && stateId) {
        dispatch(getAllCityStateWiseList(auth.token, stateId));
      }
    }, [auth?.token, stateId, dispatch]);


  const [validated, setValidated] = useState(false);
  const autocompleteRef = useRef(null);
  

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(basicSaveId){
      dispatch(updateBasicSaveData(auth?.token, formData, basicSaveId));
    } else {
      dispatch(saveBasicSaveData(auth?.token, formData));
    }
  };


  useEffect(() => {
    setFormData({})
    if(basicSaveId) {
      dispatch(getBasicSaveListById(auth?.token, basicSaveId));
    }
  }, [auth, basicSaveId]);


  useEffect(() => {
    if(basicSaveId && basicSaveLists && Object.keys(basicSaveLists).length > 0) {
      setFormData({
        lab_name: basicSaveLists?.lab_name,
        lat: basicSaveLists?.lat,
        lng: basicSaveLists?.lng,
        address: basicSaveLists?.address,
        email: basicSaveLists?.email || "",
        mobile_number: basicSaveLists?.mobile_number,
        tpa_name: basicSaveLists?.tpa_name,
        tpa_number: basicSaveLists?.tpa_number,
        state_code: basicSaveLists?.lab_cities?.cities_state?.state_code,
        city_code: basicSaveLists?.lab_cities?.city_code,
      });
    }
  }, [basicSaveLists]);


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };


  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };


  const handleClientTypeChange = (selectedData) => {
    const clientTypeId = selectedData[0]?.id;
    selectHandler2(clientTypeId, "client_type");
    setIsClientTypeSelected(!!clientTypeId);
  };


  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);


  const handleInputChange = async (e) => {
    const input = e.target.value;
    setFormData({ ...formData, search_name: input });

    if (input.length > 2)
    {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({
        input,
        componentRestrictions: { country: "IN" },
        types: ["doctor"],
      }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
          setShowSuggestions(true);
        }
      });
    }
    else
    {
      setShowSuggestions(false);
    }
  };


  useEffect(() => {
    if (changedBasicSaveStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedBasicSaveStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }, [changedBasicSaveStatus, dispatch]);


  return (
    <Modal show={show} onHide={onHide} size="xl" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add or Edit Basic Save")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorsBasicSave?.data?.message && (
          <div className="alert alert-danger">
            {errorsBasicSave?.data?.message}
          </div>
        )}

        <Accordion id="accordion" className="mb-3">
          <Card>
            <Card.Body>
              <Row>
                <Col lg={4}>
                  <FormInput
                    label={t("Case Creation Date")}
                    type="date"
                    errors={errorsBasicSave?.data}
                    value={formData.case_creation_date}
                    name="case_creation_date"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Referral Date")}
                    type="date"
                    errors={errorsBasicSave?.data}
                    value={formData.referral_date}
                    name="referral_date"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Referral Time")}
                    type="time"
                    errors={errorsBasicSave?.data}
                    value={formData.referral_time}
                    name="referral_time"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Ageing in Days")}
                    type="text"
                    errors={errorsBasicSave?.data}
                    value={formData.ageing_days}
                    name="ageing_days"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Ageing in Hours")}
                    type="text"
                    errors={errorsBasicSave?.data}
                    value={formData.ageing_hours}
                    name="ageing_hours"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <label>{t("Source of Claims")}</label>
                  <div style={{ marginTop: '7%' }}>
                    <Form.Check
                      type="radio"
                      label={t("Email")}
                      name="source_of_claims"
                      value="Email"
                      checked={formData.source_of_claims === "Email"}
                      onChange={inputHandler}
                      inline
                    />
                    <Form.Check
                      type="radio"
                      label={t("Insurance Company Portal")}
                      name="source_of_claims"
                      value="Insurance Company Portal"
                      checked={formData.source_of_claims === "Insurance Company Portal"}
                      onChange={inputHandler}
                      inline
                    />
                  </div>
                </Col>

                {formData?.source_of_claims === "Insurance Company Portal"  && (
                  <Col lg={4}>
                    <FormInput
                      label={t("Insurance Company Portal Name")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.insurance_company_portal_name}
                      name="insurance_company_portal_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                )}

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Client Type")}</Form.Label>
                    <Select
                      options={clientTypeList}
                      values={clientTypeList?.filter(client => client?.id === formData?.client_type)}
                      name="client_type"
                      labelField={"name"}
                      valueField={"id"}
                      searchBy={"name"}
                      loading={false}
                      onChange={handleClientTypeChange}
                      styles={null}
                    />
                    
                    {errorsBasicSave?.data?.client_type && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsBasicSave?.data?.client_type}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={1}
                containerClass="m-0"
                linkClass="text-dark"
                onClick={handleClientTypeChange}
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Client Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={1} in={isClientTypeSelected}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label>{t("Client Company Name")}</Form.Label>
                        <button 
                          className="btn btn-link p-0 text-decoration-none" 
                          onClick={() => setShowInsuranceCompanyModel(true)}
                          type="button"
                        >
                          {t("Add New Client Name")}
                        </button>
                      </div>
                      <Select
                        options={masterInsuranceList}
                        values={masterInsuranceList?.filter(ins => ins.id === formData.client_company_name)}
                        name="client_company_name"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "client_company_name")
                          setInsuranceId(selectedData[0]?.id);
                        }}
                      />
                      
                      {errorsBasicSave?.data?.client_company_name && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsBasicSave?.data?.client_company_name}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Name of Insurance Company")}</Form.Label>
                      <Select
                        options={masterInsuranceList}
                        values={masterInsuranceList?.filter(ins => ins.id === formData.insurance_company)}
                        name="insurance_company"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "insurance_company")
                        }}
                      />
                      
                      {errorsBasicSave?.data?.insurance_company && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsBasicSave?.data?.insurance_company}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={2}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Claims Type
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={2}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Types of Services")}</Form.Label>
                      <Select
                        options={groupClaimsTypeList}
                        values={groupClaimsTypeList?.filter(grp => grp?.id === formData?.type_of_services)}
                        name="type_of_services"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "type_of_services")
                        }}
                        styles={null}
                      />
                      
                      {errorsBasicSave?.data?.type_of_services && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsBasicSave?.data?.type_of_services}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Type Of Claims")}</Form.Label>
                      <Select
                        options={masterClaimTypeList}
                        values={masterClaimTypeList?.filter(type => type?.id === formData?.type_of_claims)}
                        name="type_of_claims"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "type_of_claims")
                        }}
                        styles={null}
                      />
                      
                      {errorsBasicSave?.data?.type_of_claims && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsBasicSave?.data?.type_of_claims}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Owner Of the Claim (Client Name)</span>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label>{t("Client Name")}</Form.Label>
                        <button 
                          className="btn btn-link p-0 text-decoration-none" 
                          onClick={() => setShowInsuranceManagerModel(true)}
                          type="button"
                        >
                          {t("Add New Client Name")}
                        </button>
                      </div>
                      <Select
                        options={masterInsuranceList}
                        values={masterInsuranceList?.filter(cli => cli?.id === formData?.client_name_insurance)}
                        name="client_name_insurance"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "client_name_insurance")
                        }}
                        styles={null}
                      />
                      
                      {errorsBasicSave?.data?.client_name_insurance && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsBasicSave?.data?.client_name_insurance}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{`Salutation`}</Form.Label>
                      <Select
                        options={salutationList}
                        values={salutationList?.filter(salutation => salutation?.id === formData?.owner_of_claim_salutation)}
                        name="owner_of_claim_salutation"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "owner_of_claim_salutation")
                        }}
                        styles={null}
                      />
                      
                      {errorsBasicSave?.data?.owner_of_claim_salutation && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsBasicSave?.data?.owner_of_claim_salutation}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`First Name`}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.owner_of_claim_first}
                      name="owner_of_claim_first"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`Middle Name`}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.owner_of_claim_middle}
                      name="owner_of_claim_middle"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`Last Name`}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.owner_of_claim_last}
                      name="owner_of_claim_last"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Designation")}</Form.Label>
                      <Select
                        options={masterDesignationList}
                        values={masterDesignationList?.filter(state => state.id === formData.owner_of_claim_designation)}
                        name="owner_of_claim_designation"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "owner_of_claim_designation")
                        }}
                      />
                      
                      {errorsBasicSave?.data?.owner_of_claim_designation && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsBasicSave?.data?.owner_of_claim_designation}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={3}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Case Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={3}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Claim No.")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.claim_number}
                      name="claim_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Policy No.")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.policy_number}
                      name="policy_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("CLID / Claim id / CCN No.")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.claim_id}
                      name="claim_id"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{`Salutation of Patient`}</Form.Label>
                      <Select
                        options={salutationList}
                        values={salutationList?.filter(salutation => salutation?.id === formData?.salutation_of_patient)}
                        name="salutation_of_patient"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "salutation_of_patient")
                        }}
                        styles={null}
                      />
                      
                      {errorsBasicSave?.data?.salutation_of_patient && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsBasicSave?.data?.salutation_of_patient}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`First Name of Patient`}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.first_of_patient}
                      name="first_of_patient"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`Middle Name of Patient`}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.middle_of_patient}
                      name="middle_of_patient"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`Last Name of Patient`}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.last_of_patient}
                      name="last_of_patient"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label>{t("Hospital Name")}</Form.Label>
                        <button 
                          className="btn btn-link p-0 text-decoration-none" 
                          onClick={() => setShowHospitalModel(true)}
                          type="button"
                        >
                          {t("Add New Hospital")}
                        </button>
                      </div>
                      <Select
                        options={masterHospitalList}
                        values={masterHospitalList?.filter(name => name?.id === formData?.hospital_name)}
                        name="hospital_name"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "hospital_name")
                        }}
                        styles={null}
                      />
                      
                      {errorsBasicSave?.data?.hospital_name && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsBasicSave?.data?.hospital_name}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Date Of Admission")}
                      type="date"
                      errors={errorsBasicSave?.data}
                      value={formData.admission_date}
                      name="admission_date"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Date Of Discharge")}
                      type="date"
                      errors={errorsBasicSave?.data}
                      value={formData.discharge_date}
                      name="discharge_date"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Claim Amount")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.claim_amount}
                      name="claim_amount"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <div style={{ marginTop: '7%' }}>
                      <Form.Check
                        type="radio"
                        label={t("Retails")}
                        name="retails_of_group"
                        value="Retails"
                        checked={formData.retails_of_group === "Retails"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check
                        type="radio"
                        label={t("Group")}
                        name="retails_of_group"
                        value="Group"
                        checked={formData.retails_of_group === "Group"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                {formData?.retails_of_group === "Group" && (
                  <>
                    <Row>
                      <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Group</span>
                      <Col lg={4}>
                        <FormInput
                          label={`Name of Corporate`}
                          type="text"
                          errors={errorsBasicSave?.data}
                          value={formData.corporate_name}
                          name="corporate_name"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Contact No.")}
                          type="text"
                          errors={errorsBasicSave?.data}
                          value={formData?.sent_to_contact_no}
                          name="sent_to_contact_no"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4} style={{marginTop: '28px'}}>
                        <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                          {t("Save")}
                        </Button>
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Email ID")}
                          type="text"
                          errors={errorsBasicSave?.data}
                          value={formData.sent_to_email}
                          name="sent_to_email"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4} style={{marginTop: '28px'}}>
                        <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                          {t("Save")}
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Corporate</span>
                      <Col lg={4}>
                        <FormInput
                          label={t("Pincode")}
                          type="text"
                          errors={errorsBasicSave?.data}
                          value={formData.group_corporate_pincode}
                          name="group_corporate_pincode"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("Country")}</Form.Label>
                          <Select
                            options={countryList}
                            values={countryList?.filter(country => country?.id === formData?.group_corporate_country_name)}
                            name="group_corporate_country_name"
                            labelField={"name"}
                            valueField={"id"}
                            searchBy={"name"}
                            loading={loadingMaster}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.id, "group_corporate_country_name")
                            }}
                            styles={null}
                          />
                          
                          {errorsBasicSave?.data?.group_corporate_country_name && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsBasicSave?.data?.group_corporate_country_name}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("State / Union Territory")}</Form.Label>
                          <Select
                            options={masterStateLists}
                            values={masterStateLists?.filter(state => state?.state_code === formData?.group_corporate_state_code)}
                            name="group_corporate_state_code"
                            labelField={"state_name"}
                            valueField={"state_code"}
                            searchBy={"state_name"}
                            loading={loadingMaster}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.state_code, "group_corporate_state_code")
                              setStateId(selectedData[0]?.state_code);
                            }}
                            styles={null}
                          />
                          
                          {errorsBasicSave?.data?.group_corporate_state_code && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsBasicSave?.data?.group_corporate_state_code}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("District")}</Form.Label>
                          <Select
                            options={masterCityStateWiseLists}
                            values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.group_corporate_city_code)}
                            name="group_corporate_city_code"
                            labelField={"city_name"}
                            valueField={"city_code"}
                            searchBy={"city_name"}
                            loading={loadingMaster}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.city_code, "group_corporate_city_code")
                            }}
                            styles={null}
                          />
                          
                          {errorsBasicSave?.data?.group_corporate_city_code && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsBasicSave?.data?.group_corporate_city_code}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Taluka / Sub-District")}
                          type="text"
                          errors={errorsBasicSave?.data}
                          value={formData.group_corporate_address}
                          name="group_corporate_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Village / City / Town")}
                          type="text"
                          errors={errorsBasicSave?.data}
                          value={formData.group_corporate_address}
                          name="group_corporate_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Flat / House Number / Building Name")}
                          type="text"
                          errors={errorsBasicSave?.data}
                          value={formData.group_corporate_address}
                          name="group_corporate_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Street Name / Road Name")}
                          type="text"
                          errors={errorsBasicSave?.data}
                          value={formData.group_corporate_address}
                          name="group_corporate_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Area / Locality")}
                          type="text"
                          errors={errorsBasicSave?.data}
                          value={formData.group_corporate_address}
                          name="group_corporate_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Landmark")}
                          type="text"
                          errors={errorsBasicSave?.data}
                          value={formData.group_corporate_address}
                          name="group_corporate_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={4}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Task Management
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={4}>
              <Card.Body>
                <Row>
                  
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Body>
              <Row>
                <Col lg={4}>
                  <FormInput
                    label={t("Company Trigger")}
                    type="text"
                    errors={errorsBasicSave?.data}
                    value={formData.company_trigger}
                    name="company_trigger"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Trigger")}
                    type="text"
                    errors={errorsBasicSave?.data}
                    value={formData.trigger}
                    name="trigger"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Comment")}
                    type="text"
                    errors={errorsBasicSave?.data}
                    value={formData.comment}
                    name="comment"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>
              </Row>

              <div className="d-flex justify-content-center">
                {loading ? 
                  <Button color="primary" disabled>
                    <Spinner animation="border" size="sm" />
                    {t("Please wait ...")}
                  </Button>
                :
                  <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                    {t("Submit")}
                  </Button>
                }
              </div>
            </Card.Body>
          </Card>

          {/* <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={5}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Bullet Management
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={5}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Claim Team")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.claim_team}
                      name="claim_team"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Allocation Team")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.allocation_team}
                      name="allocation_team"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card> */}

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={6}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                TAT
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={6}>
              <Card.Body>
                <Row>
                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "20px"}}>Expected TAT</span>
                  <Col lg={4}>
                    <FormInput
                      label={t("Agreement")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.agreement}
                      name="agreement"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Special Request")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.special_request}
                      name="special_request"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("TAT in Days/Hours")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.tat_in_days_hours}
                      name="tat_in_days_hours"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={7}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Status
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={7}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Status")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.bucket}
                      name="bucket"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Bucket")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.bucket}
                      name="bucket"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          {/* <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={8}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Red Alert
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={8}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator Username")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.alert_creator_username}
                      name="alert_creator_username"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator ID")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.alert_creator_id}
                      name="alert_creator_id"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Date & Time of Alert")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.date_time_alert}
                      name="date_time_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Comment on Alert")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.comment_alert}
                      name="comment_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card> */}

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={9}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                User Remarks
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={9}>
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    <FormInput
                      label={t("Comments")}
                      type="text"
                      errors={errorsBasicSave?.data}
                      value={formData.comments}
                      name="comments"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <div className="d-flex justify-content-center">
          {loading ? 
            <Button color="primary" disabled>
              <Spinner animation="border" size="sm" />
              {t("Please wait ...")}
            </Button>
          :
            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
              {t("Submit")}
            </Button>
          }
        </div>
      </Modal.Body>
      <AddInsuranceCompany show={showInsuranceCompanyModel} onHide={() => hideInsuranceCompanyModel()}  />
      <AddInsuranceManagerManager show={showInsuranceManagerModel} onHide={() => hideInsuranceManagerModel()}  />
      <AddHospital show={showHospitalModel} onHide={() => hideHospitalModel()}  />
      {loadingBasicSave && <Preloader />}
    </Modal>
  );
};

export default AddBasicSave;
