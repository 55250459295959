import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layouts/Layout";
import Dashboard from "./pages/Dashboard/Dashboard";

import StateIndex from "./pages/Master/State/Index";
import CityIndex from "./pages/Master/City/Index";
import ZoneIndex from "./pages/Master/Zone/Index";

import UserIndex from "./pages/ManageUsers/User/Index";
import ActiveUserIndex from "./pages/ManageUsers/User/ActiveIndex";
import InactiveUserIndex from "./pages/ManageUsers/User/InactiveIndex";
import UserAdd from "./pages/ManageUsers/User/Add";

import UsertypeIndex from "./pages/ManageUsers/Usertype/Index";
import UserTypePermissionIndex from "./pages/ManageUsers/UserTypePermission/Index";
import UserPermissionIndex from "./pages/ManageUsers/UserPermission/Index";

import GeneralSettingAdd from "./pages/General Setting/Add";
import GeneralSettingAddSubmit from "./pages/General Setting/Add";

import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import { ToastContainer } from "react-toastify";

import GeofenceIndex from "./pages/Geofence/Index";
import GeofenceAdd from "./pages/Geofence/Add";

import HospitalIndex from "./pages/HospitalMaster/Hospital/Index";

import DoctorIndex from "./pages/DoctorMaster/Doctor/Index";

import PathologyIndex from "./pages/LabMaster/Pathology/Index";

import RadiologyIndex from "./pages/LabMaster/Radiology/Index";

import BasicSaveIndex from "./pages/BasicSave/Index";

import PatientDataIndex from "./pages/PatientData/Index";

import PharmacyIndex from "./pages/Pharmacy/Index";

import AgentBrokerIndex from "./pages/AgentBroker/Index";

import CorporateIndex from "./pages/CorporateMaster/Index";

import DesignationIndex from "./pages/Insurance/Designation/Index";
import EducationIndex from "./pages/Master/Education/Index";
import IncomeIndex from "./pages/Master/Income/Index";
import JobIndex from "./pages/Master/Job/Index";
import LocalityIndex from "./pages/Master/Locality/Index";
import OccupationIndex from "./pages/Master/Occupation/Index";
import RelationshipIndex from "./pages/Master/Relationship/Index";
import ChecklistIndex from "./pages/Master/Checklist/Index";
import SubChecklistIndex from "./pages/Master/SubChecklist/Index";
import TypeOfClaimIndex from "./pages/Insurance/TypeOfClaim/Index";
import InsuranceIndex from "./pages/Insurance/InsuranceCompany/Index";
import InsuranceManagerIndex from "./pages/Insurance/InsuranceManager/Index";
import RoomTypeIndex from "./pages/HospitalMaster/RoomType/Index";
import HospitalizationIndex from "./pages/HospitalMaster/HospitalizationType/Index";
import InjuryTypeIndex from "./pages/HospitalMaster/InjuryType/Index";


import CaseDetailsIndex from "./pages/CaseDetails/Index";
import CaseSummaryIndex from "./pages/CaseDetails/IndexSummary";
import AddCaseInitiated from "./pages/CaseDetails/AddCaseInitiated";
import AddCaseGenerated from "./pages/CaseDetails/AddCaseGenerated";
import AddCaseDocument from "./pages/CaseDetails/AddCaseDocumet";
import AssignInvestigator from "./pages/CaseDetails/AssignInvestigator";

import CaseManagementHealthIndex from "./pages/CaseManagement/Health/Index";
import HealthOtherIndex from "./pages/CaseManagement/Health/SaveOtherDetails";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route element={<Layout />}>
          <Route path="/" element={<Dashboard />} />

          <Route path="/Users" element={<UserIndex />} />
          <Route path="/Active-Users" element={<ActiveUserIndex />} />
          <Route path="/In-Active-Users" element={<InactiveUserIndex />} />
          <Route path="/Add-User" element={<UserAdd />} />
          <Route path="/Update-User/:id" element={<UserAdd />} />

          <Route path="/State" element={<StateIndex />} />
          <Route path="/City" element={<CityIndex />} />
          <Route path="/Zone" element={<ZoneIndex />} />

          <Route path="/Usertype" element={<UsertypeIndex />} />
          <Route path="/User-Type-Permission" element={<UserTypePermissionIndex />} />
          <Route path="/User-Permission" element={<UserPermissionIndex />} />
          
          <Route path="/General-Settings" element={<GeneralSettingAdd />} />
          <Route path="/General-Settings" element={<GeneralSettingAddSubmit />} />
          
          <Route path="/Geofence" element={<GeofenceIndex />} />
          <Route path="/Add-Geofence" element={<GeofenceAdd />} />
          <Route path="/Update-Geofence/:id" element={<GeofenceAdd />} />

          <Route path="/Hospital" element={<HospitalIndex />} />
          <Route path="/Doctor" element={<DoctorIndex />} />
          <Route path="/Designation" element={<DesignationIndex />} />
          <Route path="/Income" element={<IncomeIndex />} />
          <Route path="/Education" element={<EducationIndex />} />
          <Route path="/Job" element={<JobIndex />} />
          <Route path="/Locality" element={<LocalityIndex />} />
          <Route path="/Occupation" element={<OccupationIndex />} />
          <Route path="/Relationship" element={<RelationshipIndex />} />
          <Route path="/Checklist" element={<ChecklistIndex />} />
          <Route path="/Sub-Checklist" element={<SubChecklistIndex />} />
          <Route path="/Type-Of-Claim" element={<TypeOfClaimIndex />} />
          <Route path="/Insurance-Company" element={<InsuranceIndex />} />
          <Route path="/Insurance-Manager" element={<InsuranceManagerIndex />} />
          <Route path="/Room-Type" element={<RoomTypeIndex />} />
          <Route path="/Hospitalization-Type" element={<HospitalizationIndex />} />
          <Route path="/Injury-Type" element={<InjuryTypeIndex />} />

          <Route path="/Pathology-Lab" element={<PathologyIndex />} />

          <Route path="/Radiology-Lab" element={<RadiologyIndex />} />

          <Route path="/Basic-Save" element={<BasicSaveIndex />} />

          <Route path="/Patient-Data" element={<PatientDataIndex />} />

          <Route path="/Pharmacy" element={<PharmacyIndex />} />

          <Route path="/Agent-Broker" element={<AgentBrokerIndex />} />

          <Route path="/Corporate" element={<CorporateIndex />} />

          <Route path="/Initiated" element={<CaseDetailsIndex case_url={"Initiated"} title={"Case Initiated"} />} />
          <Route path="/Case-Summary/:id" element={<CaseSummaryIndex case_url={"Case-Summary"} title={"Case Summary"} />} />
          <Route path="/Pending-Generation" element={<CaseDetailsIndex case_url={"Generated"} title={"Pending Generation"} />} />
          <Route path="/Assign-Field-Officer" element={<CaseDetailsIndex case_url={"Assign-Field-Officer"} title={"Assign Field Officer"} />} />
          <Route path="/Accepted-By-FO" element={<CaseDetailsIndex case_url={"Accepted-By-FO"} title={"Accepted By F.O."} />} />
          <Route path="/Rejected-By-FO" element={<CaseDetailsIndex case_url={"Rejected-By-FO"} title={"Rejected By F.O."} />} />
          <Route path="/Field-QC-Completed" element={<CaseDetailsIndex case_url={"Field-QC-Completed"} title={"Field QC Completed"} />} />
          <Route path="/Waiting-for-Doctor-Allocation" element={<CaseDetailsIndex case_url={"Waiting-for-Doctor-Allocation"} title={"Waiting for Doctor Allocation"} />} />
          <Route path="/Doctor-Report-Pending" element={<CaseDetailsIndex case_url={"Doctor-Report-Pending"} title={"Doctor Report Pending"} />} />
          <Route path="/Manager-QC-Pending" element={<CaseDetailsIndex case_url={"Manager-QC-Pending"} title={"Manager QC Pending"} />} />
          <Route path="/Team-Leader-QC-Pending" element={<CaseDetailsIndex case_url={"Team-Leader-QC-Pending"} title={"Team Leader QC Pending"} />} />
          <Route path="/Waiting-For-Closer" element={<CaseDetailsIndex case_url={"Waiting-For-Closer"} title={"Waiting For Closer"} />} />
          <Route path="/Closed" element={<CaseDetailsIndex case_url={"Closed"} title={"Closed Case"} />} />
          <Route path="/Add-Case-Initiated" element={<AddCaseInitiated />} />
          <Route path="/Add-Case-Generated/:id" element={<AddCaseGenerated title={"Add Case Generated"} />} />
          <Route path="/Update-Case-Generated/:id" element={<AddCaseGenerated title={"Update Case Generated"} />} />
          <Route path="/Add-Case-Document/:id" element={<AddCaseDocument title={"Case Document List"} />} />
          <Route path="/Investigator-Allocation/:id" element={<AssignInvestigator title={"Assign Investigator Allocation"} />} />

          <Route path="/Health" element={<CaseManagementHealthIndex />} />
          <Route path="/Other-Details/:id" element={<HealthOtherIndex />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
