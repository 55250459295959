import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Table, Button, Accordion, Spinner, Form, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getAgentBrokerListById, saveAgentBrokerData, updateAgentBrokerData } from "../../redux/actions/AgentBrokerActions";
import FormInput from "../FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllCityStateWiseList, getAllStateList } from "../../redux/actions/MasterActions";
import Preloader from "../Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import CustomToggle from "../CustomToggle";
import '../../styles/common.css'

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddAgentBroker = ({ show, onHide, agentBrokerId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});

  const authentificationList = [
    {
      id: "ITR",
      name: 'ITR'
    },
    {
      id: "Aadhaar Card",
      name: 'Aadhaar Card'
    },
    {
      id: "Pan Card",
      name: 'Pan Card'
    },
    {
      id: "Driving License",
      name: 'Driving License'
    },
    {
      id: "Doctor Registration",
      name: 'Doctor Registration'
    },
    {
      id: "Voting Card",
      name: 'Voting Card'
    },
    {
      id: "FIR Verification",
      name: 'FIR Verification'
    },
    {
      id: "Ration Card",
      name: 'Ration Card'
    },
    {
      id: "Vehicle RC",
      name: 'Vehicle RC'
    },
    {
      id: "RTI Filing Online",
      name: 'RTI Filing Online'
    },
    {
      id: "Drug License",
      name: 'Drug License'
    },
    {
      id: "Pradhan Mantri Awas Yojana",
      name: 'Pradhan Mantri Awas Yojana'
    },
    {
      id: "Online DL for Verification Process",
      name: 'Online DL for Verification Process'
    }
  ]

  const linksMap = {
    "ITR": "https://eportal.incometax.gov.in/iec/foservices/#/pre-login/eVerifyReturn-bl",
    "Aadhaar Card": "https://myaadhaar.uidai.gov.in/check-aadhaar-validity",
    "Pan Card": "https://eportal.incometax.gov.in/iec/foservices/#/pre-login/verifyYourPAN",
    "Driving License": "https://parivahan.gov.in/rcdlstatus/?pur_cd=101",
    "Doctor Registration": "https://www.maharashtramedicalcouncil.in/frmRmpList.aspx",
    "Voting Card": "https://electoralsearch.eci.gov.in/",
    "FIR Verification": "https://citizen.mahapolice.gov.in/Citizen/MH/PublishedFIRs.aspx",
    "Ration Card": "https://rcms.mahafood.gov.in/Show_Reports.aspx?RID=116",
    "Vehicle RC": "https://parivahan.gov.in/rcdlstatus/?pur_cd=101",
    "RTI Filing Online": "https://rtionline.gov.in/request/request.php",
    "Drug License": "https://fdamfg.maharashtra.gov.in/frm_G_Cold_S_Query.aspx?ST=MH",
    "Pradhan Mantri Awas Yojana": "http://pmaymis.gov.in/Open/Find_Beneficiary_Details.aspx",
    "Online DL for Verification Process": "https://sarathi.parivahan.gov.in/sarathiservice/stateSelection.do",
  };

  const countryList = [
    {
      id: "India",
      name: 'India'
    }
  ]

  const salutationList = [
    {
      id: "Mr",
      name: 'Mr'
    },
    {
      id: "Mrs",
      name: 'Mrs'
    }
  ]
  

  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    agentBrokerReducer: {
      loadingAgentBroker,
      errorsAgentBroker,
      agentBrokerLists,
      changedAgentBrokerStatus,
    },
    masterReducer: {
      loadingMaster,
      masterStateLists,
      masterCityStateWiseLists,
    },
  } = useSelector((state) => state);


  useEffect(() => {
    if (auth?.token)
    {
      dispatch(getAllStateList(auth.token));
    }
  }, [auth?.token]);


  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);


  const [validated, setValidated] = useState(false);
  const autocompleteRef = useRef(null);
  

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(agentBrokerId){
      dispatch(updateAgentBrokerData(auth?.token, formData, agentBrokerId));
    } else {
      dispatch(saveAgentBrokerData(auth?.token, formData));
    }
  };


  useEffect(() => {
    dispatch(getAllStateList(auth.token));
    setFormData({})
    if(agentBrokerId) {
      dispatch(getAgentBrokerListById(auth?.token, agentBrokerId));
    }
  }, [auth, agentBrokerId]);


  useEffect(() => {
    if(Object.keys(placeData).length > 0 && Object.keys(masterCityStateWiseLists).length>0) {
      const addressComponents = placeData?.address_components;
      const city_name = getAddressComponent(addressComponents, 'administrative_area_level_3');
      const city_code=masterCityStateWiseLists?.filter(data => data.city_name == getAddressComponent(addressComponents, 'administrative_area_level_3'))
      setFormData({ ...formData, 'city_code': city_code[0]?.city_code });
    }
  },[masterCityStateWiseLists])


  useEffect(() => {
    if(agentBrokerId && agentBrokerLists && Object.keys(agentBrokerLists).length > 0) {
      setFormData({
        lab_name: agentBrokerLists?.lab_name,
        lat: agentBrokerLists?.lat,
        lng: agentBrokerLists?.lng,
        address: agentBrokerLists?.address,
        email: agentBrokerLists?.email || "",
        mobile_number: agentBrokerLists?.mobile_number,
        tpa_name: agentBrokerLists?.tpa_name,
        tpa_number: agentBrokerLists?.tpa_number,
        state_code: agentBrokerLists?.lab_cities?.cities_state?.state_code,
        city_code: agentBrokerLists?.lab_cities?.city_code,
      });
    }
  }, [agentBrokerLists]);


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };


  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };


  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);


  const handleInputChange = async (e) => {
    const input = e.target.value;
    setFormData({ ...formData, search_name: input });

    if (input.length > 2)
    {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({
        input,
        componentRestrictions: { country: "IN" },
        types: ["doctor"],
      }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
          setShowSuggestions(true);
        }
      });
    }
    else
    {
      setShowSuggestions(false);
    }
  };


  const handlePlaceSelected = async (placeId) => {
    const service = new window.google.maps.places.PlacesService(document.createElement("div"));
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setPlaceData(place)
        const addressComponents = place?.address_components;
        const state = getAddressComponent(addressComponents, 'administrative_area_level_1');
        const city = getAddressComponent(addressComponents, 'administrative_area_level_3');
        const state_code=masterStateLists?.filter(state => state.state_name == getAddressComponent(addressComponents, 'administrative_area_level_1'))
        setFormData({
          ...formData,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          lab_name: place.name || "",
          mobile_number: (place.formatted_phone_number || "").replace(/\s/g, '').replace(/^0/, ''),
          address: place.formatted_address || "",
          state_code:state_code[0]?.state_code,
          city_name: city || "",
        });
        setShowSuggestions(false);
      }
    });
  };


  const getAddressComponent = (components, type) => {
    const component = components.find(comp => comp.types.includes(type));
    return component ? component.long_name : '';
  };


  useEffect(() => {
    if (changedAgentBrokerStatus?.success)
    {
      onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedAgentBrokerStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }, [changedAgentBrokerStatus, dispatch]);


  const handleAuthenticationChange = (selectedData) => {
    const selectedId = selectedData[0]?.id;
    const link = linksMap[selectedId] || "";
    selectHandler2(selectedId, "kyc_authentication");
    inputHandler({
      target: {
        name: "kyc_authentification_link",
        value: link,
      }
    });
  };


  return (
    <Modal show={show} onHide={onHide} size="xl" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add or Edit Agent Broker")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorsAgentBroker?.data?.message && (
          <div className="alert alert-danger">
            {errorsAgentBroker?.data?.message}
          </div>
        )}

        <Accordion id="accordion" className="mb-3">
          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey="0"
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Basic Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{`Salutation of IMD / Agent / Advisor / Broker`}</Form.Label>
                      <Select
                        options={salutationList}
                        values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                        name="issuing_salutation"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "issuing_salutation")
                        }}
                        styles={null}
                      />
                      
                      {errorsAgentBroker?.data?.issuing_salutation && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsAgentBroker?.data?.issuing_salutation}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`First Name of IMD / Agent / Advisor / Broker`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_owner_first}
                      name="hospital_owner_first"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`Middle Name of IMD / Agent / Advisor / Broker`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_owner_middle}
                      name="hospital_owner_middle"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`Last Name of IMD / Agent / Advisor / Broker`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_owner_last}
                      name="hospital_owner_last"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`Code of IMD / Agent / Advisor / Broker`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.treating_first_name}
                      name="treating_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Row>
                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px", marginTop: "14px"}}>Address of IMD / Agent / Advisor / Broker </span>
                  <Col lg={4}>
                    <FormInput
                      label={t("Pincode")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_pincode}
                      name="hospital_pincode"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                  
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Country")}</Form.Label>
                      <Select
                        options={countryList}
                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                        name="country_name"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "country_name")
                        }}
                        styles={null}
                      />
                      
                      {errorsAgentBroker?.data?.country_name && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsAgentBroker?.data?.country_name}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                      <Select
                        options={masterStateLists}
                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                        name="state_code"
                        labelField={"state_name"}
                        valueField={"state_code"}
                        searchBy={"state_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.state_code, "state_code")
                          setStateId(selectedData[0]?.state_code);
                        }}
                        styles={null}
                      />
                      
                      {errorsAgentBroker?.data?.state_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsAgentBroker?.data?.state_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("District")}</Form.Label>
                      <Select
                        options={masterCityStateWiseLists}
                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                        name="city_code"
                        labelField={"city_name"}
                        valueField={"city_code"}
                        searchBy={"city_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.city_code, "city_code")
                        }}
                        styles={null}
                      />
                      
                      {errorsAgentBroker?.data?.city_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsAgentBroker?.data?.city_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Taluka / Sub-District")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Village / City / Town")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Flat / House Number / Building Name")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Street Name / Road Name")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Area / Locality")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Landmark")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={`Contact No.`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.treating_last_name}
                      name="treating_last_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={4}>
                    
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`Email ID`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.lab_name}
                      name="lab_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={4}>
                    
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={`Agent Photo`}
                      type="file"
                      errors={errorsAgentBroker?.data}
                      value={formData.agent_photo}
                      name="agent_photo"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={1}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Kyc Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={1}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("KYC Authentication Type")}</Form.Label>
                      <Select
                        options={authentificationList}
                        values={authentificationList?.filter((authen) => authen?.id === formData?.kyc_authentication)}
                        name="kyc_authentication"
                        placeholder={t("Select KYC Authentication Type")}
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(handleAuthenticationChange)}
                        styles={null}
                      />
                      {errorsAgentBroker?.data?.kyc_authentication && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">
                            {errorsAgentBroker?.data?.kyc_authentication}
                          </p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t(`KYC Document Upload`)}
                      type="file"
                      errors={errorsAgentBroker?.data}
                      value={formData.document_upload}
                      name="document_upload"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <div
                      style={{ position: "relative" }}
                      onClick={() => window.open(formData?.kyc_authentification_link || "", "_blank")}
                    >
                      <FormInput
                        label={t("KYC Authenfication Check")}
                        type="text"
                        errors={errorsAgentBroker?.data}
                        value={formData?.kyc_authentification_link || ""}
                        name="kyc_authentification_link"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                        readOnly
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("KYC Authenfication Photo")}
                      type="file"
                      errors={errorsAgentBroker?.data}
                      value={formData.link_verification_upload}
                      name="link_verification_upload"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Comment")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.link_verification_comment}
                      name="link_verification_comment"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={2}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Other
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={2}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={`Sub IMD Code`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.treating_first_name}
                      name="treating_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{`Salutation of Sub IMD`}</Form.Label>
                      <Select
                        options={salutationList}
                        values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                        name="issuing_salutation"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "issuing_salutation")
                        }}
                        styles={null}
                      />
                      
                      {errorsAgentBroker?.data?.issuing_salutation && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsAgentBroker?.data?.issuing_salutation}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`First Name of Sub IMD`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_owner_first}
                      name="hospital_owner_first"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`Middle Name of Sub IMD`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_owner_middle}
                      name="hospital_owner_middle"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`Last Name of Sub IMD`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_owner_last}
                      name="hospital_owner_last"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`RM Code`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.treating_first_name}
                      name="treating_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`Agent ID`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.treating_first_name}
                      name="treating_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`Policy Sourcing Branch`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.treating_first_name}
                      name="treating_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`Agent Branch Name`}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.treating_first_name}
                      name="treating_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>
                
                <Row>
                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Branch </span>
                  <Col lg={3}>
                    <FormInput
                      label={t("Pincode")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_pincode}
                      name="hospital_pincode"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                  
                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Country")}</Form.Label>
                      <Select
                        options={countryList}
                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                        name="country_name"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "country_name")
                        }}
                        styles={null}
                      />
                      
                      {errorsAgentBroker?.data?.country_name && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsAgentBroker?.data?.country_name}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                      <Select
                        options={masterStateLists}
                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                        name="state_code"
                        labelField={"state_name"}
                        valueField={"state_code"}
                        searchBy={"state_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.state_code, "state_code")
                          setStateId(selectedData[0]?.state_code);
                        }}
                        styles={null}
                      />
                      
                      {errorsAgentBroker?.data?.state_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsAgentBroker?.data?.state_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("District")}</Form.Label>
                      <Select
                        options={masterCityStateWiseLists}
                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                        name="city_code"
                        labelField={"city_name"}
                        valueField={"city_code"}
                        searchBy={"city_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.city_code, "city_code")
                        }}
                        styles={null}
                      />
                      
                      {errorsAgentBroker?.data?.city_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsAgentBroker?.data?.city_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Taluka / Sub-District")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Village / City / Town")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Flat / House Number / Building Name")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Street Name / Road Name")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Area / Locality")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Landmark")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={3}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Agent Code Online Verification
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={3}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <div
                      style={{ position: "relative" }}
                      onClick={() => window.open(formData?.rohini_id_link || "https://agencyportal.irdai.gov.in/PublicAccess/LookUpPAN.aspx", "_blank")}
                    >
                      <FormInput
                        label={t("Check Agent Code By PAN")}
                        type="text"
                        errors={errorsAgentBroker?.data}
                        value={formData?.rohini_id_link || "https://agencyportal.irdai.gov.in/PublicAccess/LookUpPAN.aspx"}
                        name="rohini_id_link"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                        readOnly
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Agent Code Verification Upload")}
                      type="file"
                      errors={errorsAgentBroker?.data}
                      value={formData.irda_link_upload}
                      name="irda_link_upload"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Agent Code Verification Comment")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.irda_link_comment}
                      name="irda_link_comment"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={1} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <div
                      style={{ position: "relative" }}
                      onClick={() => window.open(formData?.rohini_id_link || "https://agencyportal.irdai.gov.in/PublicAccess/AgentLocator.aspx", "_blank")}
                    >
                      <FormInput
                        label={t("Check Agent Code By Agent Locator")}
                        type="text"
                        errors={errorsAgentBroker?.data}
                        value={formData?.rohini_id_link || "https://agencyportal.irdai.gov.in/PublicAccess/AgentLocator.aspx"}
                        name="rohini_id_link"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                        readOnly
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Agent Code Verification Upload")}
                      type="file"
                      errors={errorsAgentBroker?.data}
                      value={formData.irda_link_upload}
                      name="irda_link_upload"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Agent Code Verification Comment")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.irda_link_comment}
                      name="irda_link_comment"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={1} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={4}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Agent Social Media
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={4}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Profesional Website")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_whatsapp_link}
                      name="hospital_whatsapp_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Whatsapp")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_whatsapp_link}
                      name="hospital_whatsapp_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Instagram Link")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_instagram_link}
                      name="hospital_instagram_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Facebook Link")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_facebook_link}
                      name="hospital_facebook_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Linkdin Link")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_linkdin_link}
                      name="hospital_linkdin_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Twitter Link")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_twitter_link}
                      name="hospital_twitter_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Justdial Link")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_justdial_link}
                      name="hospital_justdial_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Eyecon Link")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_eyecon_link}
                      name="hospital_eyecon_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Other Link")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.hospital_eyecon_link}
                      name="hospital_eyecon_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={5}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Suspicious Agent
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={5}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <label>{t("Suspicious Agent")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="suspicious_check"
                        value="Yes"
                        checked={formData.suspicious_check === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="suspicious_check"
                        value="No"
                        checked={formData.suspicious_check === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  {formData.suspicious_check === "Yes" && ( 
                    <Col lg={4}>
                      <FormInput
                        label={t("Remark")}
                        type="text"
                        errors={errorsAgentBroker?.data}
                        value={formData.suspicious_remark}
                        name="suspicious_remark"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>
                  )}
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={6}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Alert
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={6}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator Username")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.alert_creator_username}
                      name="alert_creator_username"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator ID")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.alert_creator_id}
                      name="alert_creator_id"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Date & Time of Alert")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.date_time_alert}
                      name="date_time_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Comment on Alert")}
                      type="text"
                      errors={errorsAgentBroker?.data}
                      value={formData.comment_alert}
                      name="comment_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={7}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Blacklisted
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={7}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <label>{t("Backlisted Status")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="backlisted_status"
                        value="Yes"
                        checked={formData.backlisted_status === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="backlisted_status"
                        value="No"
                        checked={formData.backlisted_status === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Data Upload")}
                      type="file"
                      errors={errorsAgentBroker?.data}
                      value={formData.blacklisted_insurance_data}
                      name="blacklisted_insurance_data"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={8}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Grievance Management
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={8}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <label>{t("Grievance and Escalation")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Grievance")}
                        name="grievance_status"
                        value="Grievance"
                        checked={formData.grievance_status === "Grievance"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("Escalation")}
                        name="grievance_status"
                        value="Escalation"
                        checked={formData.grievance_status === "Escalation"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                <br />
                {formData.grievance_status && (<>
                  <Row>
                    <Col lg={4}>
                      <FormInput
                        label={t(`Type of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsAgentBroker?.data}
                        value={formData.type_of_grievance}
                        name="type_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Date of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsAgentBroker?.data}
                        value={formData.date_of_grievance}
                        name="date_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Notification of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsAgentBroker?.data}
                        value={formData.notification_of_grievance}
                        name="notification_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Reason of the ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsAgentBroker?.data}
                        value={formData.reason_of_grievance}
                        name="reason_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Status of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsAgentBroker?.data}
                        value={formData.status_of_grievance}
                        name="status_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Final Conclusion of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsAgentBroker?.data}
                        value={formData.final_of_grievance}
                        name="final_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>
                  </Row>
                </>)}

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Modal.Body>
      {loadingAgentBroker && <Preloader />}
    </Modal>
  );
};

export default AddAgentBroker;
