import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Accordion, Spinner, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getInsuranceManagerListById, saveInsuranceManagerData, updateInsuranceManagerData } from "../../redux/actions/InsuranceManagerActions";
import FormInput from "../../Components/FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllStateList, getAllCityStateWiseList, getMasterDesignationList, getMasterInsuranceList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import CustomToggle from "../../Components/CustomToggle";
import '../../styles/common.css';

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddInsuranceManagerManager = ({ show, onHide, editId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});
  const [isClientTypeSelected, setIsClientTypeSelected] = useState(false);
  

  const clientTypeList = [
    {
      id: "Insurance Company",
      name: 'Insurance Company'
    },
    {
      id: "TPA",
      name: 'TPA'
    },
    {
      id: "Vendor",
      name: 'Vendor'
    },
    {
      id: "Others",
      name: 'Others'
    }
  ]

  const countryList = [
    {
      id: "India",
      name: 'India'
    }
  ]

  const salutationList = [
    {
      id: "Mr",
      name: 'Mr'
    },
    {
      id: "Mrs",
      name: 'Mrs'
    }
  ]


  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    insuranceManagerReducer: {
      loadingInsuranceManager,
      errorsInsuranceManager,
      insuranceManagerLists,
      changedInsuranceManagerStatus,
    },
    masterReducer: {
      loadingMaster,
      masterStateLists,
      masterCityStateWiseLists,
      masterInsuranceList,
      masterDesignationList
    },
  } = useSelector((state) => state);


  useEffect(() => {
    if (auth?.token)
    {
      dispatch(getAllStateList(auth.token));
      dispatch(getMasterDesignationList(auth.token));
    }
  }, [auth?.token]);


  const [validated, setValidated] = useState(false);
  

  useEffect(() => {
    setFormData({})
    if(editId) {
      dispatch(getInsuranceManagerListById(auth?.token, editId));
    }
  }, [auth, editId]);


  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);


  useEffect(() => {
    if (auth?.token && typeId) {
      dispatch(getMasterInsuranceList(auth.token, typeId));
    }
  }, [auth?.token, typeId, dispatch]);


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(editId){
      dispatch(updateInsuranceManagerData(auth?.token, formData, editId));
    } else {
      dispatch(saveInsuranceManagerData(auth?.token, formData));
    }
  };

  
  useEffect(() => {
    if(editId && insuranceManagerLists && Object.keys(insuranceManagerLists).length > 0) {
      setFormData({
        name: insuranceManagerLists?.name,
        email: insuranceManagerLists?.email,
        mobile_number: insuranceManagerLists?.mobile_number,
        insurance_id: insuranceManagerLists?.insuranceManager_insurance?.id,
        designation_id: insuranceManagerLists?.insuranceManager_designation?.id,
      });
    }
  }, [insuranceManagerLists]);


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide();
    }
  }, [errors, dispatch, onHide]);


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };


  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const selectPincodeHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,6}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const handleClientTypeChange = (selectedData) => {
    const clientTypeId = selectedData[0]?.id;
    selectHandler2(clientTypeId, "client_type");
    setIsClientTypeSelected(!!clientTypeId);
  };


  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };

  
  useEffect(() => {
    if (changedInsuranceManagerStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedInsuranceManagerStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_INSURANCE_MANAGER_STATUS' });
      
    }
  }, [changedInsuranceManagerStatus, dispatch]);


  return (
    <Modal show={show} onHide={onHide} size="xl" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{(editId && insuranceManagerLists && Object.keys(insuranceManagerLists).length > 0) ? t("Update Insurance Manager") : t("Add Insurance Manager")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorsInsuranceManager?.data?.message && (
          <div className="alert alert-danger">
            {errorsInsuranceManager?.data?.message}
          </div>
        )}

        <Card>
          <Card.Body>
            <Row>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Client Type")}</Form.Label>
                  <Select
                    options={clientTypeList}
                    values={clientTypeList?.filter(client => client?.id === formData?.client_type)}
                    name="client_type"
                    labelField={"name"}
                    valueField={"id"}
                    searchBy={"name"}
                    loading={false}
                    onChange={handleClientTypeChange}
                    styles={null}
                  />
                  
                  {errorsInsuranceManager?.data?.client_type && (
                    <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsInsuranceManager?.data?.client_type}</p>
                    </div>
                  )}
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Client Company Name")}</Form.Label>
                  <Select
                    options={masterInsuranceList}
                    values={masterInsuranceList?.filter(state => state.id === formData.insurance_id)}
                    name="insurance_id"
                    labelField={"name"}
                    valueField={"id"}
                    searchBy={"name"}
                    loading={loadingMaster}
                    onChange={(selectedData) => {
                      selectHandler2(selectedData[0]?.id, "insurance_id")
                    }}
                  />
                  
                  {errorsInsuranceManager?.data?.insurance_id && (
                    <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsInsuranceManager?.data?.insurance_id}</p>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Accordion id="accordion" className="mb-3">
          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey="0"
                containerClass="m-0"
                linkClass="text-dark"
                onClick={handleClientTypeChange}
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Basic Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0" in={isClientTypeSelected}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{`Salutation of Client Manager`}</Form.Label>
                      <Select
                        options={salutationList}
                        values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                        name="issuing_salutation"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "issuing_salutation")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsuranceManager?.data?.issuing_salutation && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsuranceManager?.data?.issuing_salutation}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`First Name of Client Manager`}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hospital_owner_first}
                      name="hospital_owner_first"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`Middle Name of Client Manager`}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hospital_owner_middle}
                      name="hospital_owner_middle"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`Last Name of Client Manager`}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hospital_owner_last}
                      name="hospital_owner_last"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: "12px" }}>
                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Designation of Client Manager")}</Form.Label>
                      <Select
                        options={masterDesignationList}
                        values={masterDesignationList?.filter(state => state.id === formData.designation_id)}
                        name="designation_id"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "designation_id")
                        }}
                      />
                      
                      {errorsInsuranceManager?.data?.designation_id && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsuranceManager?.data?.designation_id}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Contact No.")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.contact_number}
                      name="contact_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={6} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Official Email ID")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.email_id}
                      name="email_id"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Incharge of Location")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.incharge_location}
                      name="incharge_location"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Hierarchy Level")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hierarchy_level}
                      name="hierarchy_level"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Manager Photo")}
                      type="file"
                      errors={errorsInsuranceManager?.data}
                      value={formData.photo_manager}
                      name="photo_manager"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Row>
                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Office</span>
                  <Col lg={3}>
                    <FormInput
                      label={t("Pincode")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hospital_pincode}
                      name="hospital_pincode"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                  
                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Country")}</Form.Label>
                      <Select
                        options={countryList}
                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                        name="country_name"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "country_name")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsuranceManager?.data?.country_name && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsuranceManager?.data?.country_name}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                      <Select
                        options={masterStateLists}
                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                        name="state_code"
                        labelField={"state_name"}
                        valueField={"state_code"}
                        searchBy={"state_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.state_code, "state_code")
                          setStateId(selectedData[0]?.state_code);
                        }}
                        styles={null}
                      />
                      
                      {errorsInsuranceManager?.data?.state_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsuranceManager?.data?.state_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("District")}</Form.Label>
                      <Select
                        options={masterCityStateWiseLists}
                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                        name="city_code"
                        labelField={"city_name"}
                        valueField={"city_code"}
                        searchBy={"city_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.city_code, "city_code")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsuranceManager?.data?.city_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsuranceManager?.data?.city_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Taluka / Sub-District")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Village / City / Town")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Flat / House Number / Building Name")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Street Name / Road Name")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Area / Locality")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Landmark")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Accordion>
                  <Card className="mt-3">
                    <Card.Header>
                      <CustomToggle
                        eventKey="0-1"
                        containerClass="m-0"
                        linkClass="text-dark"
                      >
                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                        Social Media
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0-1">
                      <Card.Body>
                        <Row>
                          <Col lg={4}>
                            <FormInput
                              label={t("Whatsapp")}
                              type="text"
                              errors={errorsInsuranceManager?.data}
                              value={formData.hospital_whatsapp_link}
                              name="hospital_whatsapp_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Instagram Link")}
                              type="text"
                              errors={errorsInsuranceManager?.data}
                              value={formData.hospital_instagram_link}
                              name="hospital_instagram_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Facebook Link")}
                              type="text"
                              errors={errorsInsuranceManager?.data}
                              value={formData.hospital_facebook_link}
                              name="hospital_facebook_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Linkdin Link")}
                              type="text"
                              errors={errorsInsuranceManager?.data}
                              value={formData.hospital_linkdin_link}
                              name="hospital_linkdin_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Twitter Link")}
                              type="text"
                              errors={errorsInsuranceManager?.data}
                              value={formData.hospital_twitter_link}
                              name="hospital_twitter_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Truecaller Link")}
                              type="text"
                              errors={errorsInsuranceManager?.data}
                              value={formData.hospital_truecaller_link}
                              name="hospital_truecaller_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Justdial Link")}
                              type="text"
                              errors={errorsInsuranceManager?.data}
                              value={formData.hospital_justdial_link}
                              name="hospital_justdial_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Eyecon Link")}
                              type="text"
                              errors={errorsInsuranceManager?.data}
                              value={formData.hospital_eyecon_link}
                              name="hospital_eyecon_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Other Link")}
                              type="text"
                              errors={errorsInsuranceManager?.data}
                              value={formData.hospital_eyecon_link}
                              name="hospital_eyecon_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {(editId && insuranceManagerLists && Object.keys(insuranceManagerLists).length > 0) ? t("Update") : t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={1}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Reporting Manager
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={1}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{`Reporting Manager Salutation`}</Form.Label>
                      <Select
                        options={salutationList}
                        values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                        name="issuing_salutation"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "issuing_salutation")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsuranceManager?.data?.issuing_salutation && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsuranceManager?.data?.issuing_salutation}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`Reporting Manager First Name`}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hospital_owner_first}
                      name="hospital_owner_first"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`Reporting Manager Middle Name`}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hospital_owner_middle}
                      name="hospital_owner_middle"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`Reporting Manager Last Name`}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.hospital_owner_last}
                      name="hospital_owner_last"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Reporting Manager Designation")}</Form.Label>
                      <Select
                        options={masterDesignationList}
                        values={masterDesignationList?.filter(state => state.id === formData.reporting_designation)}
                        name="reporting_designation"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "reporting_designation")
                        }}
                      />
                      
                      {errorsInsuranceManager?.data?.reporting_designation && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsuranceManager?.data?.reporting_designation}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Reporting Manager Contact No.")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.reporting_contact_number}
                      name="reporting_contact_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={3}>
                    
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Reporting Manager Email ID")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.reporting_email_id}
                      name="reporting_email_id"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                    {(editId && insuranceManagerLists && Object.keys(insuranceManagerLists).length > 0) ? t("Update") : t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={2}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Client Authority Rights
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={2}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <Form.Check
                      type="checkbox"
                      label={t("Claim Allocation")}
                      name="claim_allocation"
                      value="Yes"
                      checked={formData.claim_allocation}
                      onChange={(e) => inputHandler({ target: { name: "claim_allocation", value: e.target.checked } })}
                      inline
                    />
                  </Col>

                  <Col lg={3}>
                    <Form.Check
                      type="checkbox"
                      label={t("Billing")}
                      name="billing"
                      value="Yes"
                      checked={formData.billing}
                      onChange={(e) => inputHandler({ target: { name: "billing", value: e.target.checked } })}
                      inline
                    />
                  </Col>

                  <Col lg={3}>
                    <Form.Check
                      type="checkbox"
                      label={t("Follow Up")}
                      name="follow_up"
                      value="Yes"
                      checked={formData.follow_up}
                      onChange={(e) => inputHandler({ target: { name: "follow_up", value: e.target.checked } })}
                      inline
                    />
                  </Col>

                  <Col lg={3}>
                    <Form.Check
                      type="checkbox"
                      label={t("Others")}
                      name="others"
                      value="Yes"
                      checked={formData.others}
                      onChange={(e) => inputHandler({ target: { name: "others", value: e.target.checked } })}
                      inline
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: "38px" }}>
                  <Col lg={4}>
                    <FormInput
                      label={t("Remark")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.remarks}
                      name="remarks"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                    {(editId && insuranceManagerLists && Object.keys(insuranceManagerLists).length > 0) ? t("Update") : t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={3}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Special Instruction
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={3}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Comment")}
                      type="text"
                      errors={errorsInsuranceManager?.data}
                      value={formData.comment}
                      name="comment"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                    {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={4}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Status
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={4}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <label>{t("Status")}</label>
                    <div style={{marginTop: '7%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Active")}
                        name="status_manager"
                        value="Active"
                        checked={formData.status_manager === "Active"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("In-Active")}
                        name="status_manager"
                        value="In-Active"
                        checked={formData.status_manager === "In-Active"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  {formData.status_manager === "In-Active" && (
                    <>
                      <Col lg={4}>
                        <FormInput
                          label={t("Reason for In-Active")}
                          type="text"
                          errors={errorsInsuranceManager?.data}
                          value={formData.remarks}
                          name="remarks"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                    {(editId && insuranceManagerLists && Object.keys(insuranceManagerLists).length > 0) ? t("Update") : t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={5}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Access Portal
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={5}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <label>{t("Access Portal")}</label>
                    <div style={{marginTop: '7%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="access_portal"
                        value="Yes"
                        checked={formData.access_portal === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="access_portal"
                        value="No"
                        checked={formData.access_portal === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                    {(editId && insuranceManagerLists && Object.keys(insuranceManagerLists).length > 0) ? t("Update") : t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Modal.Body>
      {loadingInsuranceManager && <Preloader />}
    </Modal>
  );
};

export default AddInsuranceManagerManager;
