const initialState = {
    radiologyPermissions:{},
    radiologyLists: [],
    radiologyStatus: [],
    changedRadiologyStatus : [],
    loadingRadiology: false,
    errorsRadiology: {}
}

const  radiologyReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_RADIOLOGYLIST_PENDING":
        case "ADD_RADIOLOGY_PENDING":
        case "UPDATE_RADIOLOGY_PENDING":
        case "UPDATE_RADIOLOGY_STATUS_PENDING":
        case "DELETE_RADIOLOGY_PENDING":
        case "GET_RADIOLOGYLISTBYID_PENDING":
            return { ...state, loadingRadiology: true };

        case "GET_RADIOLOGYLIST_FULFILLED":
            return {
                ...state,
                loadingRadiology: false,
                radiologyLists: action.payload,
                radiologyCount: action.count,
                radiologyPermissions:action.permissions,
            };

        case "ADD_RADIOLOGY_FULFILLED":
            return {
                ...state,
                loadingRadiology: false,
                changedRadiologyStatus: action.payload,
            };

        case "UPDATE_RADIOLOGY_FULFILLED":
            return {
                ...state,
                loadingRadiology: false,
                changedRadiologyStatus: action.payload,
            };

        case "UPDATE_RADIOLOGY_STATUS_FULFILLED":
            return {
                ...state,
                loadingRadiology: false,
                changedRadiologyStatus: action.payload,
            };

        case "GET_RADIOLOGYLISTBYID_FULFILLED":
            return {
                ...state,
                loadingRadiology: false,
                radiologyLists: action.payload
            };

        case "DELETE_RADIOLOGY_FULFILLED":
            return {
                ...state,
                loadingRadiology: false,
                changedRadiologyStatus: action.payload
            };

        case "RESET_DELETE_RADIOLOGYS_STATUS":
            return {
                ...state,
                loadingRadiology: false,
                changedRadiologyStatus: [],
                errorsRadiology:{}
            };

        case "GET_RADIOLOGYLIST_REJECTED":
        case "ADD_RADIOLOGY_REJECTED":
        case "UPDATE_RADIOLOGY_REJECTED":
        case "UPDATE_RADIOLOGY_STATUS_REJECTED":
        case "DELETE_RADIOLOGY_REJECTED":
        case "GET_RADIOLOGYLISTBYID_REJECTED":
            return {
                ...state,
                loadingRadiology: false,
                errorsRadiology: action.payload
            };

        default:
            return state;
    }
}

export default radiologyReducer;