const initialState = {
    agentBrokerPermissions:{},
    agentBrokerLists: [],
    agentBrokerStatus: [],
    changedAgentBrokerStatus : [],
    loadingAgentBroker: false,
    errorsAgentBroker: {}
}

const  agentBrokerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_AGENTBROKERLIST_PENDING":
        case "ADD_AGENTBROKER_PENDING":
        case "UPDATE_AGENTBROKER_PENDING":
        case "UPDATE_AGENTBROKER_STATUS_PENDING":
        case "DELETE_AGENTBROKER_PENDING":
        case "GET_AGENTBROKERLISTBYID_PENDING":
            return { ...state, loadingAgentBroker: true };

        case "GET_AGENTBROKERLIST_FULFILLED":
            return {
                ...state,
                loadingAgentBroker: false,
                agentBrokerLists: action.payload,
                agentBrokerCount: action.count,
                agentBrokerPermissions:action.permissions,
            };

        case "ADD_AGENTBROKER_FULFILLED":
            return {
                ...state,
                loadingAgentBroker: false,
                changedAgentBrokerStatus: action.payload,
            };

        case "UPDATE_AGENTBROKER_FULFILLED":
            return {
                ...state,
                loadingAgentBroker: false,
                changedAgentBrokerStatus: action.payload,
            };

        case "UPDATE_AGENTBROKER_STATUS_FULFILLED":
            return {
                ...state,
                loadingAgentBroker: false,
                changedAgentBrokerStatus: action.payload,
            };

        case "GET_AGENTBROKERLISTBYID_FULFILLED":
            return {
                ...state,
                loadingAgentBroker: false,
                agentBrokerLists: action.payload
            };

        case "DELETE_AGENTBROKER_FULFILLED":
            return {
                ...state,
                loadingAgentBroker: false,
                changedAgentBrokerStatus: action.payload
            };

        case "RESET_DELETE_AGENTBROKERS_STATUS":
            return {
                ...state,
                loadingAgentBroker: false,
                changedAgentBrokerStatus: [],
                errorsAgentBroker:{}
            };

        case "GET_AGENTBROKERLIST_REJECTED":
        case "ADD_AGENTBROKER_REJECTED":
        case "UPDATE_AGENTBROKER_REJECTED":
        case "UPDATE_AGENTBROKER_STATUS_REJECTED":
        case "DELETE_AGENTBROKER_REJECTED":
        case "GET_AGENTBROKERLISTBYID_REJECTED":
            return {
                ...state,
                loadingAgentBroker: false,
                errorsAgentBroker: action.payload
            };

        default:
            return state;
    }
}

export default agentBrokerReducer;