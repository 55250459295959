import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Table, Button, Accordion, Spinner, Form, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getInsuranceListById, saveInsuranceData, updateInsuranceData } from "../../redux/actions/InsuranceActions";
import FormInput from "../../Components/FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllCityStateWiseList, getAllStateList, getMasterDesignationList, getMasterInsuranceList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import CustomToggle from "../../Components/CustomToggle";
import '../../styles/common.css'

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddInsurance = ({ show, onHide, hospitalId, isDisplay=false }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});
  const [isClientTypeSelected, setIsClientTypeSelected] = useState(false);

  const clientTypeList = [
    {
      id: "Insurance Company",
      name: 'Insurance Company'
    },
    {
      id: "TPA",
      name: 'TPA'
    },
    {
      id: "Vendor",
      name: 'Vendor'
    },
    {
      id: "Others",
      name: 'Others'
    }
  ]

  const salutationList = [
    {
      id: "Mr",
      name: 'Mr'
    },
    {
      id: "Mrs",
      name: 'Mrs'
    }
  ]

  const servicesList = [
    {
      id: "Motor",
      name: 'Motor'
    },
    {
      id: "Health",
      name: 'Health'
    },
    {
      id: "Life",
      name: 'Life'
    }
  ]

  const claimsList = [
    {
      id: "Always Update",
      name: 'Always Update'
    },
    {
      id: "Not Always Update",
      name: 'Not Always Update'
    }
  ]

  const countryList = [
    {
      id: "India",
      name: 'India'
    }
  ]

  const basicFeeLists = [
    {
      id: "Half",
      name: 'Half'
    },
    {
      id: "Full",
      name: 'Full'
    }
  ]

  const halfTypeLists = [
    {
      id: "Health",
      name: 'Health'
    },
    {
      id: "Patient",
      name: 'Patient'
    },
    {
      id: "Other",
      name: 'Other'
    }
  ]
  

  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    hospitalReducer: {
      loadingInsurance,
      errorsInsurance,
      hospitalLists,
      changedInsuranceStatus,
    },
    masterReducer: {
      loadingMaster,
      masterStateLists,
      masterCityStateWiseLists,
      masterDesignationList
    },
  } = useSelector((state) => state);


  useEffect(() => {
    if (auth?.token)
    {
      dispatch(getAllStateList(auth.token));
      dispatch(getMasterDesignationList(auth.token));
    }
  }, [auth?.token]);


  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);


  const [validated, setValidated] = useState(false);
  const autocompleteRef = useRef(null);
  

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(hospitalId){
      dispatch(updateInsuranceData(auth?.token, formData, hospitalId));
    } else {
      dispatch(saveInsuranceData(auth?.token, formData));
    }
  };


  useEffect(() => {
    dispatch(getAllStateList(auth.token));
    setFormData({})
    if(hospitalId) {
      dispatch(getInsuranceListById(auth?.token, hospitalId));
    }
  }, [auth, hospitalId]);


  useEffect(() => {
    if(Object.keys(placeData).length > 0 && Object.keys(masterCityStateWiseLists).length>0) {
        const addressComponents = placeData?.address_components;
        const city_name = getAddressComponent(addressComponents, 'administrative_area_level_3');
        const city_code=masterCityStateWiseLists?.filter(data => data.city_name == getAddressComponent(addressComponents, 'administrative_area_level_3'))
        setFormData({ ...formData, 'city_code': city_code[0]?.city_code });
    }
  },[masterCityStateWiseLists])


  useEffect(() => {
    if(hospitalId && hospitalLists && Object.keys(hospitalLists).length > 0) {
      setFormData({
        hospital_name: hospitalLists?.hospital_name,
        lat: hospitalLists?.lat,
        lng: hospitalLists?.lng,
        address: hospitalLists?.address,
        email: hospitalLists?.email || "",
        mobile_number: hospitalLists?.mobile_number,
        tpa_name: hospitalLists?.tpa_name,
        tpa_number: hospitalLists?.tpa_number,
        state_code: hospitalLists?.hospital_cities?.cities_state?.state_code,
        city_code: hospitalLists?.hospital_cities?.city_code,
      });
    }
  }, [hospitalLists]);


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };


  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleClientTypeChange = (selectedData) => {
    const clientTypeId = selectedData[0]?.id;
    selectHandler2(clientTypeId, "client_type");
    setIsClientTypeSelected(!!clientTypeId);
  };


  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };


  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);


  const handleInputChange = async (e) => {
    const input = e.target.value;
    setFormData({ ...formData, search_name: input });

    if (input.length > 2)
    {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({
        input,
        componentRestrictions: { country: "IN" },
        types: ["doctor"],
      }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
          setShowSuggestions(true);
        }
      });
    }
    else
    {
      setShowSuggestions(false);
    }
  };


  const handlePlaceSelected = async (placeId) => {
    const service = new window.google.maps.places.PlacesService(document.createElement("div"));
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setPlaceData(place)
        const addressComponents = place?.address_components;
        const state = getAddressComponent(addressComponents, 'administrative_area_level_1');
        const city = getAddressComponent(addressComponents, 'administrative_area_level_3');
        const state_code=masterStateLists?.filter(state => state.state_name == getAddressComponent(addressComponents, 'administrative_area_level_1'))
        setFormData({
          ...formData,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          hospital_name: place.name || "",
          mobile_number: (place.formatted_phone_number || "").replace(/\s/g, '').replace(/^0/, ''),
          address: place.formatted_address || "",
          state_code:state_code[0]?.state_code,
          city_name: city || "",
        });
        setShowSuggestions(false);
      }
    });
  };


  const getAddressComponent = (components, type) => {
    const component = components.find(comp => comp.types.includes(type));
    return component ? component.long_name : '';
  };


  useEffect(() => {
    if (changedInsuranceStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedInsuranceStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }, [changedInsuranceStatus, dispatch]);


  const formattedExpiryDate = formData.expiry_date ? formData.expiry_date.split('T')[0] : '';
  const formattedRenewalDate = formData.renewal_date ? formData.renewal_date.split('T')[0] : '';
  const formattedSendDate = formData.send_date ? formData.send_date.split('T')[0] : '';
  const formattedAgreementDate = formData.agreement_date ? formData.agreement_date.split('T')[0] : '';
  const formattedAgreementReceivedDate = formData.agreement_received_date ? formData.agreement_received_date.split('T')[0] : '';


  return (
    <Modal show={show} onHide={onHide} size="xl" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add or Edit Insurance Company")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorsInsurance?.data?.message && (
          <div className="alert alert-danger">
            {errorsInsurance?.data?.message}
          </div>
        )}

        <Card>
          <Card.Body>
            <Row>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Client Type")}</Form.Label>
                  <Select
                    options={clientTypeList}
                    values={clientTypeList?.filter(client => client?.id === formData?.client_type)}
                    name="client_type"
                    labelField={"name"}
                    valueField={"id"}
                    searchBy={"name"}
                    loading={false}
                    onChange={handleClientTypeChange}
                    styles={null}
                  />
                  
                  {errorsInsurance?.data?.client_type && (
                    <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsInsurance?.data?.client_type}</p>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Accordion id="accordion" className="mb-3">
          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey="0"
                containerClass="m-0"
                linkClass="text-dark"
                onClick={handleClientTypeChange}
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Basic Info
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0" in={isClientTypeSelected}>
              <Card.Body>
                <Row>
                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Registered Office</span>
                  <Col lg={4}>
                    <FormInput
                      label={t("Pincode")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.registered_office_pincode}
                      name="registered_office_pincode"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Country")}</Form.Label>
                      <Select
                        options={countryList}
                        values={countryList?.filter(country => country?.id === formData?.registered_office_country)}
                        name="registered_office_country"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "registered_office_country")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsurance?.data?.registered_office_country && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsurance?.data?.registered_office_country}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                      <Select
                        options={masterStateLists}
                        values={masterStateLists?.filter(state => state?.state_code === formData?.registered_office_state)}
                        name="registered_office_state"
                        labelField={"state_name"}
                        valueField={"state_code"}
                        searchBy={"state_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.state_code, "registered_office_state")
                          setStateId(selectedData[0]?.state_code);
                        }}
                        styles={null}
                      />
                      
                      {errorsInsurance?.data?.registered_office_state && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsurance?.data?.registered_office_state}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("District")}</Form.Label>
                      <Select
                        options={masterCityStateWiseLists}
                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.registered_office_city)}
                        name="registered_office_city"
                        labelField={"city_name"}
                        valueField={"city_code"}
                        searchBy={"city_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.city_code, "registered_office_city")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsurance?.data?.registered_office_city && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsurance?.data?.registered_office_city}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Taluka / Sub-District")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.registered_office_taluka}
                      name="registered_office_taluka"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Village / City / Town")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.registered_office_village}
                      name="registered_office_village"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Flat / House Number / Building Name")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.registered_office_flat_house}
                      name="registered_office_flat_house"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Street Name / Road Name")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.registered_office_street_name}
                      name="registered_office_street_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Area / Locality")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.registered_office_area_locality}
                      name="registered_office_area_locality"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Landmark")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.registered_office_landmark}
                      name="registered_office_landmark"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t(`${formData?.client_type} Name`)}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.hospital_name}
                      name="hospital_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                      disabled={isDisplay}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t(`${formData?.client_type} Official Email ID`)}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.insurance_email}
                      name="insurance_email"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t(`${formData?.client_type} Official Contact No.`)}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.insurance_contact_number}
                      name="insurance_contact_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={1}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Agreement
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={1}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t(`Agreement Received Date`)}
                      type="date"
                      errors={errorsInsurance?.data}
                      value={formattedAgreementReceivedDate}
                      name="agreement_date"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t(`Agreement Date`)}
                      type="date"
                      errors={errorsInsurance?.data}
                      value={formattedAgreementDate}
                      name="agreement_date"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t(`Agreement Expiry Date`)}
                      type="date"
                      errors={errorsInsurance?.data}
                      value={formattedExpiryDate}
                      name="expiry_date"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t(`Agreement Renewal Date`)}
                      type="date"
                      errors={errorsInsurance?.data}
                      value={formattedRenewalDate}
                      name="renewal_date"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t(`Agreement Send Date`)}
                      type="date"
                      errors={errorsInsurance?.data}
                      value={formattedSendDate}
                      name="renewal_date"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t(`Agreement Copy (Attached)`)}
                      type="file"
                      errors={errorsInsurance?.data}
                      value={formData.agreement_copy}
                      name="agreement_copy"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Accordion>
                  <Card className="mt-3">
                    <Card.Header>
                      <CustomToggle
                        eventKey="0-1"
                        containerClass="m-0"
                        linkClass="text-dark"
                      >
                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                        Agreement Copy Sent To
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0-1">
                      <Card.Body>
                        <Row>
                          <Col lg={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>{`Salutation`}</Form.Label>
                              <Select
                                options={salutationList}
                                values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                                name="issuing_salutation"
                                labelField={"name"}
                                valueField={"id"}
                                searchBy={"name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.id, "issuing_salutation")
                                }}
                                styles={null}
                              />
                              
                              {errorsInsurance?.data?.issuing_salutation && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsInsurance?.data?.issuing_salutation}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={`First Name`}
                              type="text"
                              errors={errorsInsurance?.data}
                              value={formData.hospital_owner_first}
                              name="hospital_owner_first"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={`Middle Name`}
                              type="text"
                              errors={errorsInsurance?.data}
                              value={formData.hospital_owner_middle}
                              name="hospital_owner_middle"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={`Last Name`}
                              type="text"
                              errors={errorsInsurance?.data}
                              value={formData.hospital_owner_last}
                              name="hospital_owner_last"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Designation")}</Form.Label>
                              <Select
                                options={masterDesignationList}
                                values={masterDesignationList?.filter(state => state.id === formData.designation_id)}
                                name="designation_id"
                                labelField={"name"}
                                valueField={"id"}
                                searchBy={"name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.id, "designation_id")
                                }}
                              />
                              
                              {errorsInsurance?.data?.designation_id && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsInsurance?.data?.designation_id}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("Contact No.")}
                              type="text"
                              errors={errorsInsurance?.data}
                              value={formData?.sent_to_contact_no}
                              name="sent_to_contact_no"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3} style={{marginTop: '28px'}}>
                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                              {t("Save")}
                            </Button>
                          </Col>

                          <Col lg={3} style={{marginTop: '28px'}}>
                            
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("Email ID")}
                              type="text"
                              errors={errorsInsurance?.data}
                              value={formData.sent_to_email}
                              name="sent_to_email"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3} style={{marginTop: '28px'}}>
                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                              {t("Save")}
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={2}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Services
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={2}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Types of Services")}</Form.Label>
                      <Select
                        options={servicesList}
                        values={servicesList?.filter(status => status?.id === formData?.type_of_services)}
                        name="type_of_services"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "type_of_services")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsurance?.data?.type_of_services && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsurance?.data?.type_of_services}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={3}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Claims Type
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={3}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Types of Claims")}</Form.Label>
                      <Select
                        options={claimsList}
                        values={claimsList?.filter(status => status?.id === formData?.type_of_services)}
                        name="type_of_services"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "type_of_services")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsurance?.data?.type_of_services && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsurance?.data?.type_of_services}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Sub Type of Claims")}</Form.Label>
                      <Select
                        options={claimsList}
                        values={claimsList?.filter(status => status?.id === formData?.type_of_services)}
                        name="type_of_services"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "type_of_services")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsurance?.data?.type_of_services && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsurance?.data?.type_of_services}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={4}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Billing Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={4}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <label>{t("Type of Billing")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Default")}
                        name="type_of_billing"
                        value="Default"
                        checked={formData.type_of_billing === "Default"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("Branch")}
                        name="type_of_billing"
                        value="Branch"
                        checked={formData.type_of_billing === "Branch"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                {formData.type_of_billing && (
                  <>
                    <Row style={{ marginTop: "30px" }}>
                      {formData.type_of_billing === "Branch" && (
                        <>
                          <Col lg={4}>
                            <FormInput
                              label={t("Branch Code")}
                              type="text"
                              errors={errorsInsurance?.data}
                              value={formData.branch_code}
                              name="branch_code"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Branch Name")}
                              type="text"
                              errors={errorsInsurance?.data}
                              value={formData.branch_name}
                              name="branch_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>
                        </>
                      )}
                      
                      <Col lg={4}>
                        <FormInput
                          label={t("GST Number")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.default_gst_number}
                          name="default_gst_number"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("UIN Number")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.default_uin_number}
                          name="default_uin_number"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Pan Card Number")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.default_pan_number}
                          name="default_pan_number"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of {formData.type_of_billing} Billing</span>
                      <Col lg={4}>
                        <FormInput
                          label={t("Pincode")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.hospital_pincode}
                          name="hospital_pincode"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                      
                      <Col lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("Country")}</Form.Label>
                          <Select
                            options={countryList}
                            values={countryList?.filter(country => country?.id === formData?.country_name)}
                            name="country_name"
                            labelField={"name"}
                            valueField={"id"}
                            searchBy={"name"}
                            loading={loadingMaster}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.id, "country_name")
                            }}
                            styles={null}
                          />
                          
                          {errorsInsurance?.data?.country_name && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsInsurance?.data?.country_name}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("State / Union Territory")}</Form.Label>
                          <Select
                            options={masterStateLists}
                            values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                            name="state_code"
                            labelField={"state_name"}
                            valueField={"state_code"}
                            searchBy={"state_name"}
                            loading={loadingMaster}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.state_code, "state_code")
                              setStateId(selectedData[0]?.state_code);
                            }}
                            styles={null}
                          />
                          
                          {errorsInsurance?.data?.state_code && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsInsurance?.data?.state_code}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("District")}</Form.Label>
                          <Select
                            options={masterCityStateWiseLists}
                            values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                            name="city_code"
                            labelField={"city_name"}
                            valueField={"city_code"}
                            searchBy={"city_name"}
                            loading={loadingMaster}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.city_code, "city_code")
                            }}
                            styles={null}
                          />
                          
                          {errorsInsurance?.data?.city_code && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsInsurance?.data?.city_code}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Taluka / Sub-District")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.hospital_address}
                          name="hospital_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Village / City / Town")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.hospital_address}
                          name="hospital_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Flat / House Number / Building Name")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.hospital_address}
                          name="hospital_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Street Name / Road Name")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.hospital_address}
                          name="hospital_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Area / Locality")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.hospital_address}
                          name="hospital_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Landmark")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.hospital_address}
                          name="hospital_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={5}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Professional Fee Structure
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={5}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Type of Claims")}</Form.Label>
                      <Select
                        options={masterCityStateWiseLists}
                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                        name="city_code"
                        labelField={"city_name"}
                        valueField={"city_code"}
                        searchBy={"city_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.city_code, "city_code")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsurance?.data?.city_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsurance?.data?.city_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Sub Type of Claims")}</Form.Label>
                      <Select
                        options={masterCityStateWiseLists}
                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                        name="city_code"
                        labelField={"city_name"}
                        valueField={"city_code"}
                        searchBy={"city_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.city_code, "city_code")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsurance?.data?.city_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsurance?.data?.city_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Basic Fee (As per Task)")}</Form.Label>
                      <Select
                        options={basicFeeLists}
                        values={basicFeeLists?.filter(fee => fee?.id === formData?.basic_fee_task)}
                        name="basic_fee_task"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "basic_fee_task")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsurance?.data?.basic_fee_task && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsurance?.data?.basic_fee_task}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  {formData?.basic_fee_task === "Half"  && (
                    <Col lg={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Half Type")}</Form.Label>
                        <Select
                          options={halfTypeLists}
                          values={halfTypeLists?.filter(typ => typ?.id === formData?.half_type)}
                          name="half_type"
                          labelField={"name"}
                          valueField={"id"}
                          searchBy={"name"}
                          loading={loadingMaster}
                          onChange={(selectedData) => {
                            selectHandler2(selectedData[0]?.id, "half_type")
                          }}
                          styles={null}
                        />
                        
                        {errorsInsurance?.data?.half_type && (
                          <div className="invalid-feedback d-block">
                            <p className="text-danger">{errorsInsurance?.data?.half_type}</p>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  )}

                  <Col lg={3}>
                    <FormInput
                      label={t("Value")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.allowance_criteria}
                      name="allowance_criteria"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <label>{t("TDS Applicable")}</label>
                    <div style={{marginTop: '7%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="fee_structure_tds_applicable"
                        value="Yes"
                        checked={formData.fee_structure_tds_applicable === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="fee_structure_tds_applicable"
                        value="No"
                        checked={formData.fee_structure_tds_applicable === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  {formData?.fee_structure_tds_applicable === "Yes" && (
                    <>
                      <Col lg={3}>
                        <FormInput
                          label={t("Percentage")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.fee_structure_tds_percentage}
                          name="fee_structure_tds_percentage"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={3}>
                        <FormInput
                          label={t("TDS Value")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.fee_structure_tds_value}
                          name="fee_structure_tds_value"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={6}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Incentive Structure
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={6}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <label>{t("Incentive Applicable")}</label>
                    <div style={{marginTop: '7%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="incentive_applicable"
                        value="Yes"
                        checked={formData.incentive_applicable === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="incentive_applicable"
                        value="No"
                        checked={formData.incentive_applicable === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                {formData?.incentive_applicable  === "Yes" && (
                  <>
                    <Row>
                      <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "24px"}}>As Per Claims Decision/Rejection Base</span>
                      <Col lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("Claim Type")}</Form.Label>
                          <Select
                            options={masterCityStateWiseLists}
                            values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                            name="city_code"
                            labelField={"city_name"}
                            valueField={"city_code"}
                            searchBy={"city_name"}
                            loading={loadingMaster}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.city_code, "city_code")
                            }}
                            styles={null}
                          />
                          
                          {errorsInsurance?.data?.city_code && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsInsurance?.data?.city_code}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={3}>
                        <FormInput
                          label={t("Minimum Claim Amount")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.allowance_criteria}
                          name="allowance_criteria"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={3}>
                        <FormInput
                          label={t("Maximum Claim Amount")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.allowance_criteria}
                          name="allowance_criteria"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={3}>
                        <FormInput
                          label={t("Incentive Value")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.allowance_criteria}
                          name="allowance_criteria"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={3}>
                        <label>{t("TDS Applicable")}</label>
                        <div style={{marginTop: '7%'}}>
                          <Form.Check 
                            type="radio"
                            label={t("Yes")}
                            name="incentive_tds_applicable"
                            value="Yes"
                            checked={formData.incentive_tds_applicable === "Yes"}
                            onChange={inputHandler}
                            inline
                          />
                          <Form.Check 
                            type="radio"
                            label={t("No")}
                            name="incentive_tds_applicable"
                            value="No"
                            checked={formData.incentive_tds_applicable === "No"}
                            onChange={inputHandler}
                            inline
                          />
                        </div>
                      </Col>

                      {formData?.incentive_tds_applicable === "Yes" && (
                        <>
                          <Col lg={3}>
                            <FormInput
                              label={t("Percentage")}
                              type="text"
                              errors={errorsInsurance?.data}
                              value={formData.incentive_tds_percentage}
                              name="incentive_tds_percentage"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("TDS Value")}
                              type="text"
                              errors={errorsInsurance?.data}
                              value={formData.incentive_tds_value}
                              name="incentive_tds_value"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>
                        </>
                      )}

                      <div className="d-flex justify-content-center">
                        {loading ? 
                          <Button color="primary" disabled>
                            <Spinner animation="border" size="sm" />
                            {t("Please wait ...")}
                          </Button>
                        :
                          <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                            {t("Save")}
                          </Button>
                        }
                      </div>
                    </Row>

                    <Row>
                      <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "24px"}}>As Per TAT</span>
                      <Col lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("Claim Type")}</Form.Label>
                          <Select
                            options={masterCityStateWiseLists}
                            values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                            name="city_code"
                            labelField={"city_name"}
                            valueField={"city_code"}
                            searchBy={"city_name"}
                            loading={loadingMaster}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.city_code, "city_code")
                            }}
                            styles={null}
                          />
                          
                          {errorsInsurance?.data?.city_code && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsInsurance?.data?.city_code}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={3}>
                        <FormInput
                          label={t("TAT in Days / Hours")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.allowance_criteria}
                          name="allowance_criteria"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={3}>
                        <FormInput
                          label={t("Incentive Value")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.allowance_criteria}
                          name="allowance_criteria"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={3}>
                        <label>{t("TDS Applicable")}</label>
                        <div style={{marginTop: '7%'}}>
                          <Form.Check 
                            type="radio"
                            label={t("Yes")}
                            name="incentive_tat_tds_applicable"
                            value="Yes"
                            checked={formData.incentive_tat_tds_applicable === "Yes"}
                            onChange={inputHandler}
                            inline
                          />
                          <Form.Check 
                            type="radio"
                            label={t("No")}
                            name="incentive_tat_tds_applicable"
                            value="No"
                            checked={formData.incentive_tat_tds_applicable === "No"}
                            onChange={inputHandler}
                            inline
                          />
                        </div>
                      </Col>

                      {formData?.incentive_tat_tds_applicable === "Yes" && (
                        <>
                          <Col lg={3}>
                            <FormInput
                              label={t("Percentage")}
                              type="text"
                              errors={errorsInsurance?.data}
                              value={formData.incentive_tat_tds_percentage}
                              name="incentive_tat_tds_percentage"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("TDS Value")}
                              type="text"
                              errors={errorsInsurance?.data}
                              value={formData.incentive_tat_tds_value}
                              name="incentive_tat_tds_value"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>
                        </>
                      )}

                      <div className="d-flex justify-content-center">
                        {loading ? 
                          <Button color="primary" disabled>
                            <Spinner animation="border" size="sm" />
                            {t("Please wait ...")}
                          </Button>
                        :
                          <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                            {t("Save")}
                          </Button>
                        }
                      </div>
                    </Row>
                  </>
                )}

                <div className="d-flex justify-content-center" style={{ marginTop: "12px" }}>
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={7}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Travelling Structure
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={7}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <label>{t("Travel Allowance Applicable")}</label>
                    <div style={{marginTop: '7%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="travel_applicable"
                        value="Yes"
                        checked={formData.travel_applicable === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="travel_applicable"
                        value="No"
                        checked={formData.travel_applicable === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  {formData?.travel_applicable === "Yes" && (
                    <>
                      <Col lg={3}>
                        <FormInput
                          label={t("Hospital Distance From FO Address")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.allowance_criteria}
                          name="allowance_criteria"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={3}>
                        <FormInput
                          label={t("Patient Distance From FO Address")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.allowance_criteria}
                          name="allowance_criteria"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={3}>
                        <label>{t("TDS Applicable")}</label>
                        <div style={{marginTop: '7%'}}>
                          <Form.Check 
                            type="radio"
                            label={t("Yes")}
                            name="travel_tds_applicable"
                            value="Yes"
                            checked={formData.travel_tds_applicable === "Yes"}
                            onChange={inputHandler}
                            inline
                          />
                          <Form.Check 
                            type="radio"
                            label={t("No")}
                            name="travel_tds_applicable"
                            value="No"
                            checked={formData.travel_tds_applicable === "No"}
                            onChange={inputHandler}
                            inline
                          />
                        </div>
                      </Col>

                      {formData?.travel_tds_applicable === "Yes" && (
                        <>
                          <Col lg={3}>
                            <FormInput
                              label={t("Percentage")}
                              type="text"
                              errors={errorsInsurance?.data}
                              value={formData.travel_tds_percentage}
                              name="travel_tds_percentage"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={8}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Penalty Structure
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={8}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <label>{t("Penalty Applicable")}</label>
                    <div style={{marginTop: '7%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="penalty_applicable"
                        value="Yes"
                        checked={formData.penalty_applicable === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="penalty_applicable"
                        value="No"
                        checked={formData.penalty_applicable === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                {formData?.penalty_applicable  === "Yes" && (
                  <>
                    <Row style={{ marginTop: "34px" }}>
                      <Col lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("Type of Claims")}</Form.Label>
                          <Select
                            options={masterCityStateWiseLists}
                            values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                            name="city_code"
                            labelField={"city_name"}
                            valueField={"city_code"}
                            searchBy={"city_name"}
                            loading={loadingMaster}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.city_code, "city_code")
                            }}
                            styles={null}
                          />
                          
                          {errorsInsurance?.data?.city_code && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsInsurance?.data?.city_code}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("TAT in Days / Hours")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.allowance_criteria}
                          name="allowance_criteria"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Deduction in Percentage From the Basic Fee")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.deduction_percentage_basic_fee}
                          name="deduction_percentage_basic_fee"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Value")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.allowance_criteria}
                          name="allowance_criteria"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={9}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Miscellaneous
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={9}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Comment")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.miscellaneous_comment}
                      name="miscellaneous_comment"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Value")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.miscellaneous_value}
                      name="miscellaneous_value"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <label>{t("TDS Applicable")}</label>
                    <div style={{marginTop: '7%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="miscellaneous_tds_applicable"
                        value="Yes"
                        checked={formData.miscellaneous_tds_applicable === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="miscellaneous_tds_applicable"
                        value="No"
                        checked={formData.miscellaneous_tds_applicable === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  {formData?.miscellaneous_tds_applicable === "Yes" && (
                    <>
                      <Col lg={3}>
                        <FormInput
                          label={t("Percentage")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.miscellaneous_tds_percentage}
                          name="miscellaneous_tds_percentage"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={10}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                TDS On IPD Charges
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={10}>
              <Card.Body>
                <Row>
                  <Col lg={2}>
                    <label>{t("TDS Applicable")}</label>
                    <div style={{marginTop: '7%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="ipd_charges_tds_applicable"
                        value="Yes"
                        checked={formData.ipd_charges_tds_applicable === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="ipd_charges_tds_applicable"
                        value="No"
                        checked={formData.ipd_charges_tds_applicable === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  {formData?.ipd_charges_tds_applicable === "Yes" && (
                    <>
                      <Col lg={4}>
                        <FormInput
                          label={t("Percentage")}
                          type="text"
                          errors={errorsInsurance?.data}
                          value={formData.ipd_charges_tds_percentage}
                          name="ipd_charges_tds_percentage"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={11}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Expected TAT As Per Agreement
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={11}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("As Per Product")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.miscellaneous}
                      name="miscellaneous"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("TAT in Days/Hours")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.miscellaneous}
                      name="miscellaneous"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={12}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Courier Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={12}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Name Of Recipient")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.recipient_name}
                      name="recipient_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Recipient Mobile No.")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.recipient_number}
                      name="recipient_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Recipient Email ID")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.recipient_email}
                      name="recipient_email"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Courier</span>
                  <Col lg={4}>
                    <FormInput
                      label={t("Pincode")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.hospital_pincode}
                      name="hospital_pincode"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                  
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Country")}</Form.Label>
                      <Select
                        options={countryList}
                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                        name="country_name"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "country_name")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsurance?.data?.country_name && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsurance?.data?.country_name}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                      <Select
                        options={masterStateLists}
                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                        name="state_code"
                        labelField={"state_name"}
                        valueField={"state_code"}
                        searchBy={"state_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.state_code, "state_code")
                          setStateId(selectedData[0]?.state_code);
                        }}
                        styles={null}
                      />
                      
                      {errorsInsurance?.data?.state_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsurance?.data?.state_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("District")}</Form.Label>
                      <Select
                        options={masterCityStateWiseLists}
                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                        name="city_code"
                        labelField={"city_name"}
                        valueField={"city_code"}
                        searchBy={"city_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.city_code, "city_code")
                        }}
                        styles={null}
                      />
                      
                      {errorsInsurance?.data?.city_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsInsurance?.data?.city_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Taluka / Sub-District")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Village / City / Town")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Flat / House Number / Building Name")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Street Name / Road Name")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Area / Locality")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Landmark")}
                      type="text"
                      errors={errorsInsurance?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={3}>
                    <label>{t("Billing Dependent on Hard Copy")}</label>
                    <div style={{marginTop: '7%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="dependent_hard_copy"
                        value="Yes"
                        checked={formData.dependent_hard_copy === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="dependent_hard_copy"
                        value="No"
                        checked={formData.dependent_hard_copy === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Modal.Body>
      {loadingInsurance && <Preloader />}
    </Modal>
  );
};

export default AddInsurance;
