import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Spinner, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getCityListById, saveCityData, updateCityData } from "../../redux/actions/CityActions";
import FormInput from "../../Components/FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllStateList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import '../../styles/common.css'

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddCity = ({ show, onHide, editId,isView=true }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    cityReducer: {
      loadingCity,
      errorsCity,
      cityLists,
      changedCityStatus,
    },
    masterReducer: {
      loadingMaster,
      errorsMaster,
      masterStateLists,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getAllStateList(auth.token));
    }
  }, [auth?.token]);

  useEffect(() => {
    setFormData({})
    if(editId) {
      dispatch(getCityListById(auth?.token, editId));
    }
  }, [auth, editId]);

  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(editId)
    {
      dispatch(updateCityData(auth?.token, formData,editId));
    }
    else
    {
      dispatch(saveCityData(auth?.token, formData));
    }
  };

  useEffect(()=>{
    if(editId && cityLists && Object.keys(cityLists).length > 0) {
      setFormData({
        city_code: cityLists?.city_code,
        city_name: cityLists?.city_name,
        state_id: cityLists?.state_id ,
      });
    }
  },[cityLists])

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);

  useEffect(() => {
    if (errorsMaster && Object.keys(errorsMaster).length > 0) {
      if(errorsMaster?.status==401){
        dispatch(logout())
        toast.error(errors?.data?.message);
        onHide();
      }
    }
  }, [errorsMaster]);

  useEffect(() => {
    if (changedCityStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedCityStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_CITY_STATUS' });
    }
  }, [changedCityStatus, dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectHandler = (selectedData, name) => {
    // console.log(selectedData);
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData[0]?.state_code
    }));
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };


  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{(editId && cityLists && Object.keys(cityLists).length > 0) ? t("Update City") : t("Add City")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            {errorsCity?.data?.message && (
              <div className="alert alert-danger">
                {errorsCity?.data?.message}
              </div>
            )}
          <Row>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("State")}</Form.Label>
                <Select
                  options={masterStateLists}
                  values={masterStateLists.filter((state) => state.state_code === formData.state_id)}
                  name="state_id"
                  labelField={"state_name"}
                  valueField={"state_code"}
                  loading={loadingMaster}
                  onChange={(selectedData) => selectHandler(selectedData, "state_id")}
                  styles={customSelectStyles}
                  disabled={isView}
                />
                
                {errorsCity?.data?.state_id && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsCity?.data?.state_id}</p>
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col lg={12}>
              <FormInput
                label={t("City Code")}
                type="text"
                errors={errorsCity?.data}
                value={formData.city_code}
                name="city_code"
                onChange={inputHandler}
                containerClass={"mb-3"}
                disabled={isView}
              />
            </Col>

            <Col lg={12}>
              <FormInput
                label={t("City Name")}
                type="text"
                errors={errorsCity?.data}
                value={formData.city_name}
                name="city_name"
                onChange={inputHandler}
                containerClass={"mb-3"}
                disabled={isView}
              />
            </Col>
          </Row>
          {isView==false && (
            <>
            <div className="d-flex justify-content-center">
              {loading ? 
                <Button color="primary" disabled>
                  <Spinner animation="border" size="sm" />
                  {t("Please wait ...")}
                </Button>
              :
                <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                {(editId && cityLists && Object.keys(cityLists).length > 0) ? t("Update") : t("Add")}
                </Button>
              }
            </div>
            </>
          )}
          
        
      </Modal.Body>
      {loadingCity && <Preloader />}
    </Modal>
  );
};

export default AddCity;
