const initialState = {
    corporatePermissions:{},
    corporateLists: [],
    corporateStatus: [],
    changedCorporateStatus : [],
    loadingCorporate: false,
    errorsCorporate: {}
}

const  corporateReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CORPORATELIST_PENDING":
        case "ADD_CORPORATE_PENDING":
        case "UPDATE_CORPORATE_PENDING":
        case "UPDATE_CORPORATE_STATUS_PENDING":
        case "DELETE_CORPORATE_PENDING":
        case "GET_CORPORATELISTBYID_PENDING":
            return { ...state, loadingCorporate: true };

        case "GET_CORPORATELIST_FULFILLED":
            return {
                ...state,
                loadingCorporate: false,
                corporateLists: action.payload,
                corporateCount: action.count,
                corporatePermissions:action.permissions,
            };

        case "ADD_CORPORATE_FULFILLED":
            return {
                ...state,
                loadingCorporate: false,
                changedCorporateStatus: action.payload,
            };

        case "UPDATE_CORPORATE_FULFILLED":
            return {
                ...state,
                loadingCorporate: false,
                changedCorporateStatus: action.payload,
            };

        case "UPDATE_CORPORATE_STATUS_FULFILLED":
            return {
                ...state,
                loadingCorporate: false,
                changedCorporateStatus: action.payload,
            };

        case "GET_CORPORATELISTBYID_FULFILLED":
            return {
                ...state,
                loadingCorporate: false,
                corporateLists: action.payload
            };

        case "DELETE_CORPORATE_FULFILLED":
            return {
                ...state,
                loadingCorporate: false,
                changedCorporateStatus: action.payload
            };

        case "RESET_DELETE_CORPORATES_STATUS":
            return {
                ...state,
                loadingCorporate: false,
                changedCorporateStatus: [],
                errorsCorporate:{}
            };

        case "GET_CORPORATELIST_REJECTED":
        case "ADD_CORPORATE_REJECTED":
        case "UPDATE_CORPORATE_REJECTED":
        case "UPDATE_CORPORATE_STATUS_REJECTED":
        case "DELETE_CORPORATE_REJECTED":
        case "GET_CORPORATELISTBYID_REJECTED":
            return {
                ...state,
                loadingCorporate: false,
                errorsCorporate: action.payload
            };

        default:
            return state;
    }
}

export default corporateReducer;