import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Table, Button, Accordion, Spinner, Form, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getDoctorListById, saveDoctorData, updateDoctorData } from "../../redux/actions/DoctorActions";
import FormInput from "../../Components/FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllCityStateWiseList, getAllStateList, getMasterInsuranceList, getMasterQualificationList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import CustomToggle from "../../Components/CustomToggle";
import '../../styles/common.css'

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddDoctor = ({ show, onHide, hospitalId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});
  const [isTypeAvailable, setIsTypeAvailable] = useState(false);
  const [isDoctorTypeSelected, setIsDoctorTypeSelected] = useState(false);

  const doctorTypeList = [
    {
      id: "Doctor",
      name: 'Doctor'
    },
    {
      id: "Radiologist",
      name: 'Radiologist'
    },
    {
      id: "Pathologist",
      name: 'Pathologist'
    }
  ]

  const salutationList = [
    {
      id: "Mr",
      name: 'Mr'
    },
    {
      id: "Mrs",
      name: 'Mrs'
    }
  ]

  const countryList = [
    {
      id: "India",
      name: 'India'
    }
  ]

  const degreeTypeList = [
    {
      id: "UG",
      name: 'UG'
    },
    {
      id: "PG",
      name: 'PG'
    },
    {
      id: "Specialisation",
      name: 'Specialisation'
    }
  ]

  const degreeTypeMapping = {
    MBBS: "UG",
    BDS: "UG",
    BAMS: "UG",
    BHMS: "UG",
    BUMS: "UG",
    BPT: "UG",
    BNS: "UG",
    BNYS: "UG",
    BVSc: "UG",
    MD: "PG",
    MS: "PG",
    MDS: "PG",
    DNB: "PG",
    DM: "Specialisation",
    MCh: "Specialisation",
    "MD (Hom)": "PG",
    "MS (Ayurvedic)": "PG",
    "MD (Ayurvedic)": "PG"
  };

  const degreeList = [
    {
      id: "MBBS",
      name: 'MBBS'
    },
    {
      id: "BDS",
      name: 'BDS'
    },
    {
      id: "BAMS",
      name: 'BAMS'
    },
    {
      id: "BHMS",
      name: 'BHMS'
    },
    {
      id: "BUMS",
      name: 'BUMS'
    },
    {
      id: "BPT",
      name: 'BPT'
    },
    {
      id: "BNS",
      name: 'BNS'
    },
    {
      id: "BNYS",
      name: 'BNYS'
    },
    {
      id: "BVSc",
      name: 'BVSc'
    },
    {
      id: "MD",
      name: 'MD'
    },
    {
      id: "MS",
      name: 'MS'
    },
    {
      id: "MDS",
      name: 'MDS'
    },
    {
      id: "DNB",
      name: 'DNB'
    },
    {
      id: "DM",
      name: 'DM'
    },
    {
      id: "MCh",
      name: 'MCh'
    },
    {
      id: "MD (Hom)",
      name: 'MD (Hom)'
    },
    {
      id: "MS (Ayurvedic)",
      name: 'MS (Ayurvedic)'
    },
    {
      id: "MD (Ayurvedic)",
      name: 'MD (Ayurvedic)'
    }
  ]
  

  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    hospitalReducer: {
      loadingDoctor,
      errorsDoctor,
      hospitalLists,
      changedDoctorStatus,
    },
    masterReducer: {
      loadingMaster,
      masterStateLists,
      masterQualificationList,
      masterCityStateWiseLists,
    },
  } = useSelector((state) => state);


  useEffect(() => {
    if (auth?.token)
    {
      dispatch(getAllStateList(auth.token));
      dispatch(getMasterQualificationList(auth.token));
    }
  }, [auth?.token]);


  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);


  const [validated, setValidated] = useState(false);
  const autocompleteRef = useRef(null);
  

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(hospitalId){
      dispatch(updateDoctorData(auth?.token, formData, hospitalId));
    } else {
      dispatch(saveDoctorData(auth?.token, formData));
    }
  };


  useEffect(() => {
    dispatch(getAllStateList(auth.token));
    setFormData({})
    if(hospitalId) {
      dispatch(getDoctorListById(auth?.token, hospitalId));
    }
  }, [auth, hospitalId]);


  useEffect(() => {
    if(Object.keys(placeData).length > 0 && Object.keys(masterCityStateWiseLists).length>0) {
        const addressComponents = placeData?.address_components;
        const city_name = getAddressComponent(addressComponents, 'administrative_area_level_3');
        const city_code=masterCityStateWiseLists?.filter(data => data.city_name == getAddressComponent(addressComponents, 'administrative_area_level_3'))
        setFormData({ ...formData, 'city_code': city_code[0]?.city_code });
    }
  },[masterCityStateWiseLists])


  useEffect(() => {
    if(hospitalId && hospitalLists && Object.keys(hospitalLists).length > 0) {
      setFormData({
        hospital_name: hospitalLists?.hospital_name,
        lat: hospitalLists?.lat,
        lng: hospitalLists?.lng,
        address: hospitalLists?.address,
        email: hospitalLists?.email || "",
        mobile_number: hospitalLists?.mobile_number,
        tpa_name: hospitalLists?.tpa_name,
        tpa_number: hospitalLists?.tpa_number,
        state_code: hospitalLists?.hospital_cities?.cities_state?.state_code,
        city_code: hospitalLists?.hospital_cities?.city_code,
      });
    }
  }, [hospitalLists]);


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };


  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };


  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);


  const handleInputChange = async (e) => {
    const input = e.target.value;
    setFormData({ ...formData, search_name: input });

    if (input.length > 2)
    {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({
        input,
        componentRestrictions: { country: "IN" },
        types: ["doctor"],
      }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
          setShowSuggestions(true);
        }
      });
    }
    else
    {
      setShowSuggestions(false);
    }
  };


  const handlePlaceSelected = async (placeId) => {
    const service = new window.google.maps.places.PlacesService(document.createElement("div"));
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setPlaceData(place)
        const addressComponents = place?.address_components;
        const state = getAddressComponent(addressComponents, 'administrative_area_level_1');
        const city = getAddressComponent(addressComponents, 'administrative_area_level_3');
        const state_code=masterStateLists?.filter(state => state.state_name == getAddressComponent(addressComponents, 'administrative_area_level_1'))
        setFormData({
          ...formData,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          hospital_name: place.name || "",
          mobile_number: (place.formatted_phone_number || "").replace(/\s/g, '').replace(/^0/, ''),
          address: place.formatted_address || "",
          state_code:state_code[0]?.state_code,
          city_name: city || "",
        });
        setShowSuggestions(false);
      }
    });
  };


  const getAddressComponent = (components, type) => {
    const component = components.find(comp => comp.types.includes(type));
    return component ? component.long_name : '';
  };


  useEffect(() => {
    if (changedDoctorStatus?.success)
    {
      onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedDoctorStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }, [changedDoctorStatus, dispatch]);


  const handleDoctorTypeChange = (selectedData) => {
    setIsTypeAvailable(selectedData[0]?.id);
    const doctorTypeId = selectedData[0]?.id;
    selectHandler2(doctorTypeId, "doctor_type");
    setIsDoctorTypeSelected(!!doctorTypeId);
  };


  const formattedExpiryDate = formData.expiry_date ? formData.expiry_date.split('T')[0] : '';
  const formattedRenewalDate = formData.renewal_date ? formData.renewal_date.split('T')[0] : '';


  return (
    <Modal show={show} onHide={onHide} size="xl" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add or Edit Doctor")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorsDoctor?.data?.message && (
          <div className="alert alert-danger">
            {errorsDoctor?.data?.message}
          </div>
        )}

        <Card className="mb-1">
          <Card.Body>
            <Row>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Type of Doctor")}</Form.Label>
                  <Select
                    options={doctorTypeList}
                    values={doctorTypeList?.filter(type => type?.id === formData?.doctor_type)}
                    name="doctor_type"
                    labelField={"name"}
                    valueField={"id"}
                    searchBy={"name"}
                    loading={false}
                    onChange={handleDoctorTypeChange}
                    styles={null}
                  />
                  
                  {errorsDoctor?.data?.doctor_type && (
                    <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsDoctor?.data?.doctor_type}</p>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Accordion id="accordion" className="mb-3">
          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey="0"
                containerClass="m-0"
                linkClass="text-dark"
                onClick={handleDoctorTypeChange}
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Basic Details of {formData.doctor_type}
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0" in={isDoctorTypeSelected}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{`${formData.doctor_type} Salutation`}</Form.Label>
                      <Select
                        options={salutationList}
                        values={salutationList?.filter(salutation => salutation?.id === formData?.treating_salutation)}
                        name="treating_salutation"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "treating_salutation")
                        }}
                        styles={null}
                      />
                      
                      {errorsDoctor?.data?.treating_salutation && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsDoctor?.data?.treating_salutation}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t(`${formData.doctor_type} First Name`)}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.treating_first_name}
                      name="treating_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t(`${formData.doctor_type} Middle Name`)}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.treating_middle_name}
                      name="treating_middle_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t(`${formData.doctor_type} Last Name`)}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.treating_last_name}
                      name="treating_last_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <label>{t(`${formData.doctor_type} Gender`)}</label>
                    <div style={{marginTop: '7%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Male")}
                        name="doctor_gender"
                        value="Male"
                        checked={formData.doctor_gender === "Male"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("Female")}
                        name="doctor_gender"
                        value="Female"
                        checked={formData.doctor_gender === "Female"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("Others")}
                        name="doctor_gender"
                        value="Others"
                        checked={formData.doctor_gender === "Others"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Doctor Qualification </span>
                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t(`${formData.doctor_type} Degree`)}</Form.Label>
                      <Select
                        options={degreeList}
                        values={degreeList?.filter(gree => gree?.id === formData?.doctor_degree)}
                        name="doctor_degree"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(selectedData) => {
                          const selectedDegreeId = selectedData[0]?.id;
                          const degreeType = degreeTypeMapping[selectedDegreeId];
                          selectHandler2(selectedDegreeId, "doctor_degree");
                          selectHandler2(degreeType, "degree_type");
                        }}
                        styles={null}
                      />
                      
                      {errorsDoctor?.data?.doctor_degree && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsDoctor?.data?.doctor_degree}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t(`${formData.doctor_type} Degree Type`)}</Form.Label>
                      <Select
                        options={degreeTypeList}
                        values={degreeTypeList?.filter(degr => degr?.id === formData?.degree_type)}
                        name="degree_type"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "degree_type")
                        }}
                        styles={null}
                      />
                      
                      {errorsDoctor?.data?.degree_type && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsDoctor?.data?.degree_type}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Doctor</span>
                  <Col lg={3}>
                    <FormInput
                      label={t("Pincode")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.hospital_pincode}
                      name="hospital_pincode"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Country")}</Form.Label>
                      <Select
                        options={countryList}
                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                        name="country_name"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "country_name")
                        }}
                        styles={null}
                      />
                      
                      {errorsDoctor?.data?.country_name && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsDoctor?.data?.country_name}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                      <Select
                        options={masterStateLists}
                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                        name="state_code"
                        labelField={"state_name"}
                        valueField={"state_code"}
                        searchBy={"state_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.state_code, "state_code")
                          setStateId(selectedData[0]?.state_code);
                        }}
                        styles={null}
                      />
                      
                      {errorsDoctor?.data?.state_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsDoctor?.data?.state_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("District")}</Form.Label>
                      <Select
                        options={masterCityStateWiseLists}
                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                        name="city_code"
                        labelField={"city_name"}
                        valueField={"city_code"}
                        searchBy={"city_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.city_code, "city_code")
                        }}
                        styles={null}
                      />
                      
                      {errorsDoctor?.data?.city_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsDoctor?.data?.city_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Taluka / Sub-District")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Village / City / Town")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Flat / House Number / Building Name")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Street Name / Road Name")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Area / Locality")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Landmark")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>
                
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t(`${formData.doctor_type} Contact No`)}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.hospital_number}
                      name="hospital_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t(`${formData.doctor_type} Email ID`)}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.doctor_email}
                      name="doctor_email"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t(`${formData.doctor_type} Live Photo`)}
                      type="file"
                      errors={errorsDoctor?.data}
                      value={formData.doctor_photo}
                      name="doctor_photo"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t(`${formData.doctor_type} Handwritting Sample`)}
                      type="file"
                      errors={errorsDoctor?.data}
                      value={formData.doctor_handwritting}
                      name="doctor_handwritting"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t(`${formData.doctor_type} Signature Sample`)}
                      type="file"
                      errors={errorsDoctor?.data}
                      value={formData.signature_sample}
                      name="signature_sample"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={1}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Registration Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={1}>
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    <FormInput
                      label={t("Doctor Registration No.")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.hospital_owner}
                      name="hospital_owner"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Row>
                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Online Verification of Registration </span>
                  <Col lg={4}>
                    <div
                      style={{ position: "relative" }}
                      onClick={() => window.open(formData?.rohini_id_link || "https://www.nmc.org.in/information-desk/indian-medical-register/", "_blank")}
                    >
                      <FormInput
                        label={t("Indian Medical Council Link")}
                        type="text"
                        errors={errorsDoctor?.data}
                        value={formData?.rohini_id_link || "https://www.nmc.org.in/information-desk/indian-medical-register/"}
                        name="rohini_id_link"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                        readOnly
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div
                      style={{ position: "relative" }}
                      onClick={() => window.open(formData?.rohini_id_link || "https://www.maharashtramedicalcouncil.in/frmRmpList.aspx", "_blank")}
                    >
                      <FormInput
                        label={t("State Medical Council Link")}
                        type="text"
                        errors={errorsDoctor?.data}
                        value={formData?.rohini_id_link || "https://www.maharashtramedicalcouncil.in/frmRmpList.aspx"}
                        name="rohini_id_link"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                        readOnly
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Upload Document")}
                      type="file"
                      errors={errorsDoctor?.data}
                      value={formData?.upload_document}
                      name="upload_document"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Date of Upload")}
                      type="date"
                      errors={errorsDoctor?.data}
                      value={formData?.date_of_upload}
                      name="date_of_upload"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Comment")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData?.comment}
                      name="comment"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Doctor Registration Date")}
                      type="date"
                      errors={errorsDoctor?.data}
                      value={formattedRenewalDate}
                      name="renewal_date"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Doctor Valid Upto Date")}
                      type="date"
                      errors={errorsDoctor?.data}
                      value={formattedExpiryDate}
                      name="expiry_date"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <label>{t("Doctor Registration Status")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Valid")}
                        name="registration_status"
                        value="Valid"
                        checked={formData.registration_status === "Valid"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("Expired")}
                        name="registration_status"
                        value="Expired"
                        checked={formData.registration_status === "Expired"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  {formData.registration_status === "Expired" && (
                    <>
                      <Col lg={4}>
                        <FormInput
                          label={t("Doctor Expiry Date")}
                          type="date"
                          errors={errorsDoctor?.data}
                          value={formattedExpiryDate}
                          name="expiry_date"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Doctor Renewal Date")}
                          type="date"
                          errors={errorsDoctor?.data}
                          value={formattedRenewalDate}
                          name="renewal_date"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                    </>
                  )}

                  <Col lg={4}>
                    <FormInput
                      label={t("Certificate Issuing Date")}
                      type="date"
                      errors={errorsDoctor?.data}
                      value={formattedExpiryDate}
                      name="corticate_date"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Issuing Office Authority")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.issuing_authority}
                      name="issuing_authority"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <div
                      style={{ position: "relative" }}
                      onClick={() => window.open(formData?.rohini_id_link || "https://rtionline.gov.in/", "_blank")}
                    >
                      <FormInput
                        label={t("Online RTI Link")}
                        type="text"
                        errors={errorsDoctor?.data}
                        value={formData?.rohini_id_link || "https://rtionline.gov.in/"}
                        name="rohini_id_link"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                        readOnly
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Doctor Registration Certificate Upload")}
                      type="file"
                      errors={errorsDoctor?.data}
                      value={formattedExpiryDate}
                      name="corticate_date"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={3}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Doctor Social Media
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={3}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Professional Website Account")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData?.doctor_website_account}
                      name="doctor_website_account"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Whatsapp Account")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData?.doctor_whatsapp_account}
                      name="doctor_whatsapp_account"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Instagram Account")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData?.doctor_instagram_account}
                      name="doctor_instagram_account"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Linkdin Account")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData?.doctor_linkdin_account}
                      name="doctor_linkdin_account"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Facebook Account")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData?.doctor_facebook_account}
                      name="doctor_facebook_account"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Twitter Account")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData?.doctor_twitter_account}
                      name="doctor_twitter_account"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Justdial Account")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData?.doctor_justdial_account}
                      name="doctor_justdial_account"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Eyecon Account")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData?.doctor_eyecon_account}
                      name="doctor_eyecon_account"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Other Account")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData?.doctor_other_account}
                      name="doctor_other_account"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          {isTypeAvailable && (
            <>
              <Card className="mb-1">
                <Card.Header>
                  <CustomToggle
                    eventKey={4}
                    containerClass="m-0"
                    linkClass="text-dark"
                  >
                    <i className="mdi mdi-help-circle me-1 text-primary"></i>
                    {isTypeAvailable === "Doctor" ? "Attached Hospital" : "Attached Lab"}
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={4}>
                  <Card.Body>
                    <Row>
                      <Col lg={4}>
                        <FormInput
                          label={t(`${isTypeAvailable === "Doctor" ? "Hospital Name" : "Lab Name"}`)}
                          type="text"
                          errors={errorsDoctor?.data}
                          value={formData.hospital_name}
                          name="hospital_name"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}> {isTypeAvailable === "Doctor" ? "Hospital Address" : "Lab Address"} </span>
                      <Col lg={4}>
                        <FormInput
                          label={t("Pincode")}
                          type="text"
                          errors={errorsDoctor?.data}
                          value={formData.hospital_pincode}
                          name="hospital_pincode"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                      
                      <Col lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("Country")}</Form.Label>
                          <Select
                            options={countryList}
                            values={countryList?.filter(country => country?.id === formData?.country_name)}
                            name="country_name"
                            labelField={"name"}
                            valueField={"id"}
                            searchBy={"name"}
                            loading={loadingMaster}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.id, "country_name")
                            }}
                            styles={null}
                          />
                          
                          {errorsDoctor?.data?.country_name && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsDoctor?.data?.country_name}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("State / Union Territory")}</Form.Label>
                          <Select
                            options={masterStateLists}
                            values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                            name="state_code"
                            labelField={"state_name"}
                            valueField={"state_code"}
                            searchBy={"state_name"}
                            loading={loadingMaster}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.state_code, "state_code")
                              setStateId(selectedData[0]?.state_code);
                            }}
                            styles={null}
                          />
                          
                          {errorsDoctor?.data?.state_code && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsDoctor?.data?.state_code}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("District")}</Form.Label>
                          <Select
                            options={masterCityStateWiseLists}
                            values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                            name="city_code"
                            labelField={"city_name"}
                            valueField={"city_code"}
                            searchBy={"city_name"}
                            loading={loadingMaster}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.city_code, "city_code")
                            }}
                            styles={null}
                          />
                          
                          {errorsDoctor?.data?.city_code && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsDoctor?.data?.city_code}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Taluka / Sub-District")}
                          type="text"
                          errors={errorsDoctor?.data}
                          value={formData.hospital_address}
                          name="hospital_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Village / City / Town")}
                          type="text"
                          errors={errorsDoctor?.data}
                          value={formData.hospital_address}
                          name="hospital_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Flat / House Number / Building Name")}
                          type="text"
                          errors={errorsDoctor?.data}
                          value={formData.hospital_address}
                          name="hospital_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Street Name / Road Name")}
                          type="text"
                          errors={errorsDoctor?.data}
                          value={formData.hospital_address}
                          name="hospital_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Area / Locality")}
                          type="text"
                          errors={errorsDoctor?.data}
                          value={formData.hospital_address}
                          name="hospital_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>

                      <Col lg={4}>
                        <FormInput
                          label={t("Landmark")}
                          type="text"
                          errors={errorsDoctor?.data}
                          value={formData.hospital_address}
                          name="hospital_address"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-center">
                      {loading ? 
                        <Button color="primary" disabled>
                          <Spinner animation="border" size="sm" />
                          {t("Please wait ...")}
                        </Button>
                      :
                        <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                          {t("Submit")}
                        </Button>
                      }
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </>
          )}

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={5}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Alert
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={5}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator Username")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.alert_creator_username}
                      name="alert_creator_username"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator ID")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.alert_creator_id}
                      name="alert_creator_id"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Date & Time of Alert")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.date_time_alert}
                      name="date_time_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Comment on Alert")}
                      type="text"
                      errors={errorsDoctor?.data}
                      value={formData.comment_alert}
                      name="comment_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={6}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Blacklisted
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={6}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <label>{t("Backlisted Status")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="backlisted_status"
                        value="Yes"
                        checked={formData.backlisted_status === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="backlisted_status"
                        value="No"
                        checked={formData.backlisted_status === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Data Upload")}
                      type="file"
                      errors={errorsDoctor?.data}
                      value={formData.blacklisted_insurance_data}
                      name="blacklisted_insurance_data"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={7}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Grievance Management
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={7}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <label>{t("Grievance and Escalation")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Grievance")}
                        name="grievance_status"
                        value="Grievance"
                        checked={formData.grievance_status === "Grievance"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("Escalation")}
                        name="grievance_status"
                        value="Escalation"
                        checked={formData.grievance_status === "Escalation"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                <br />
                {formData.grievance_status && (<>
                  <Row>
                    <Col lg={4}>
                      <FormInput
                        label={t(`Type of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsDoctor?.data}
                        value={formData.type_of_grievance}
                        name="type_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Date of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsDoctor?.data}
                        value={formData.date_of_grievance}
                        name="date_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Notification of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsDoctor?.data}
                        value={formData.notification_of_grievance}
                        name="notification_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Reason of the ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsDoctor?.data}
                        value={formData.reason_of_grievance}
                        name="reason_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Status of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsDoctor?.data}
                        value={formData.status_of_grievance}
                        name="status_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t(`Final Conclusion of ${formData.grievance_status}`)}
                        type="text"
                        errors={errorsDoctor?.data}
                        value={formData.final_of_grievance}
                        name="final_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>
                  </Row>
                </>)}
                
                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Modal.Body>
      {loadingDoctor && <Preloader />}
    </Modal>
  );
};

export default AddDoctor;
