const initialState = {
    healthPermissions:{},
    healthLists: [],
    healthStatus: [],
    changedHealthStatus : [],
    loadingHealth: false,
    errorsHealth: {}
}

const  healthReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_HEALTHLIST_PENDING":
        case "ADD_HEALTH_PENDING":
        case "UPDATE_HEALTH_PENDING":
        case "UPDATE_HEALTH_STATUS_PENDING":
        case "DELETE_HEALTH_PENDING":
        case "GET_HEALTHLISTBYID_PENDING":
            return { ...state, loadingHealth: true };

        case "GET_HEALTHLIST_FULFILLED":
            return {
                ...state,
                loadingHealth: false,
                healthLists: action.payload,
                healthCount: action.count,
                healthPermissions:action.permissions,
            };

        case "ADD_HEALTH_FULFILLED":
            return {
                ...state,
                loadingHealth: false,
                changedHealthStatus: action.payload,
            };

        case "UPDATE_HEALTH_FULFILLED":
            return {
                ...state,
                loadingHealth: false,
                changedHealthStatus: action.payload,
            };

        case "UPDATE_HEALTH_STATUS_FULFILLED":
            return {
                ...state,
                loadingHealth: false,
                changedHealthStatus: action.payload,
            };

        case "GET_HEALTHLISTBYID_FULFILLED":
            return {
                ...state,
                loadingHealth: false,
                healthLists: action.payload
            };

        case "DELETE_HEALTH_FULFILLED":
            return {
                ...state,
                loadingHealth: false,
                changedHealthStatus: action.payload
            };

        case "RESET_DELETE_HEALTHS_STATUS":
            return {
                ...state,
                loadingHealth: false,
                changedHealthStatus: [],
                errorsHealth:{}
            };

        case "GET_HEALTHLIST_REJECTED":
        case "ADD_HEALTH_REJECTED":
        case "UPDATE_HEALTH_REJECTED":
        case "UPDATE_HEALTH_STATUS_REJECTED":
        case "DELETE_HEALTH_REJECTED":
        case "GET_HEALTHLISTBYID_REJECTED":
            return {
                ...state,
                loadingHealth: false,
                errorsHealth: action.payload
            };

        default:
            return state;
    }
}

export default healthReducer;