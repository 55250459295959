import React, { useCallback, useEffect , useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Table, Tab, Nav, Accordion, Form, Button, Spinner } from "react-bootstrap";
import PageTitle from "../../../Components/PageTitle";
import FormInput from "../../../Components/FormInput";
import RoundedPagination from "../../../Components/Paginate";
import { useTranslation } from "react-i18next";
import { deleteHealthData, getHealthList, updateHealthStatusData } from "../../../redux/actions/HealthActions";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllStateList, getAllCityStateWiseList, getMasterDesignationList, getMasterInsuranceList, 
  getMasterQualificationList, getMasterClaimTypeList, getMasterHospitalList } from "../../../redux/actions/MasterActions";
import { toast } from "react-toastify";
import { logout } from "../../../redux/actions/AuthActions";
import useDebounce from "../../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from "../../../util/generalUtills";
import Preloader from "../../../Components/Preloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import CustomToggle from "../../../Components/CustomToggle";



const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [stateId, setStateId] = useState(null);

  const categoryList = [
    {
      id: "Artist",
      name: 'Artist'
    },
    {
      id: "Bussiness",
      name: 'Bussiness'
    },
    {
      id: "Private Services",
      name: 'Private Services'
    },
    {
      id: "Gov Employee-Department",
      name: 'Gov Employee-Department'
    }
  ]

  const genderList = [
    {
      id: "Male",
      name: 'Male'
    },
    {
      id: "Female",
      name: 'Female'
    },
    {
      id: "Others",
      name: 'Others'
    }
  ]

  const groupClaimsTypeList = [
    {
      id: "Health",
      name: 'Health'
    },
    {
      id: "Life",
      name: 'Life'
    },
    {
      id: "Motor",
      name: 'Motor'
    }
  ]

  const salutationList = [
    {
      id: "Mr",
      name: 'Mr'
    },
    {
      id: "Mrs",
      name: 'Mrs'
    }
  ]

  const countryList = [
    {
      id: "India",
      name: 'India'
    }
  ]

  const behalfPatientList =[
    {
      id: "Self",
      name: "Self"
    },
    {
      id: "Father",
      name: "Father"
    },
    {
      id: "Mother",
      name: "Mother"
    },
    {
      id: "Wife",
      name: "Wife"
    },
    {
      id: "Husband",
      name: "Husband"
    }
  ]

  const companyTypeList = [
    {
      id: "Proprietor",
      name: 'Proprietor'
    },
    {
      id: "Partership",
      name: 'Partership'
    },
    {
      id: "NGO",
      name: 'NGO'
    },
    {
      id: "Public LTD",
      name: 'Public LTD'
    },
    {
      id: "Private LTD",
      name: 'Private LTD'
    },
    {
      id: "LLP",
      name: 'LLP'
    }
  ]

  const natureEmploymentList = [
    {
      id: "Permanent",
      name: 'Permanent'
    },
    {
      id: "Contractual",
      name: 'Contractual'
    },
    {
      id: "Part-Time",
      name: 'Part-Time'
    },
    {
      id: "Temporary",
      name: 'Temporary'
    },
    {
      id: "Internship",
      name: 'Internship'
    },
    {
      id: "Apprenticeship",
      name: 'Apprenticeship'
    },
    {
      id: "Freelance",
      name: 'Freelance'
    },
    {
      id: "Consultant",
      name: 'Consultant'
    },
    {
      id: "Probationary",
      name: 'Probationary'
    }
  ]

  const registrationList = [
    {
      id: "Employee-Employer",
      name: 'Employee-Employer'
    },
    {
      id: "Bank Account Holder",
      name: 'Bank Account Holder'
    },
    {
      id: "NGO Members",
      name: 'NGO Members'
    },
    {
      id: "Association Members",
      name: 'Association Members'
    }
  ]

  const authentificationList = [
    {
      id: "Aadhaar Card",
      name: 'Aadhaar Card'
    },
    {
      id: "Pan Card",
      name: 'Pan Card'
    },
    {
      id: "Ration Card",
      name: 'Ration Card'
    },
    {
      id: "Voter ID Card",
      name: 'Voter ID Card'
    },
    {
      id: "Driving License",
      name: 'Driving License'
    },
    {
      id: "Passport",
      name: 'Passport'
    },
    {
      id: "Other Document",
      name: 'Other Document'
    }
  ]

  const linksMap = {
    "Aadhaar Card": "https://myaadhaar.uidai.gov.in/check-aadhaar-validity",
    "Pan Card": "https://eportal.incometax.gov.in/iec/foservices/#/pre-login/verifyYourPAN",
    "Ration Card": "https://rcms.mahafood.gov.in/Show_Reports.aspx?RID=116",
    "Voter ID Card": "https://electoralsearch.eci.gov.in/",
    "Driving License": "https://parivahan.gov.in/rcdlstatus/?pur_cd=101",
    "Passport": "https://www.passportindia.gov.in/AppOnlineProject/statusTracker/trackStatusInpNew",
  };

  const accountTypeList = [
    {
      id: "Saving Account",
      name: 'Saving Account'
    },
    {
      id: "Current Account",
      name: 'Current Account'
    }
  ]

  const pathologyTypeList = [
    {
      id: "Inhouse",
      name: 'Inhouse'
    },
    {
      id: "Outsource",
      name: 'Outsource'
    }
  ]

  const degreeTypeList = [
    {
      id: "UG",
      name: 'UG'
    },
    {
      id: "PG",
      name: 'PG'
    },
    {
      id: "Specialisation",
      name: 'Specialisation'
    }
  ]

  const degreeTypeMapping = {
    MBBS: "UG",
    BDS: "UG",
    BAMS: "UG",
    BHMS: "UG",
    BUMS: "UG",
    BPT: "UG",
    BNS: "UG",
    BNYS: "UG",
    BVSc: "UG",
    MD: "PG",
    MS: "PG",
    MDS: "PG",
    DNB: "PG",
    DM: "Specialisation",
    MCh: "Specialisation",
    "MD (Hom)": "PG",
    "MS (Ayurvedic)": "PG",
    "MD (Ayurvedic)": "PG"
  };

  const degreeList = [
    {
      id: "MBBS",
      name: 'MBBS'
    },
    {
      id: "BDS",
      name: 'BDS'
    },
    {
      id: "BAMS",
      name: 'BAMS'
    },
    {
      id: "BHMS",
      name: 'BHMS'
    },
    {
      id: "BUMS",
      name: 'BUMS'
    },
    {
      id: "BPT",
      name: 'BPT'
    },
    {
      id: "BNS",
      name: 'BNS'
    },
    {
      id: "BNYS",
      name: 'BNYS'
    },
    {
      id: "BVSc",
      name: 'BVSc'
    },
    {
      id: "MD",
      name: 'MD'
    },
    {
      id: "MS",
      name: 'MS'
    },
    {
      id: "MDS",
      name: 'MDS'
    },
    {
      id: "DNB",
      name: 'DNB'
    },
    {
      id: "DM",
      name: 'DM'
    },
    {
      id: "MCh",
      name: 'MCh'
    },
    {
      id: "MD (Hom)",
      name: 'MD (Hom)'
    },
    {
      id: "MS (Ayurvedic)",
      name: 'MS (Ayurvedic)'
    },
    {
      id: "MD (Ayurvedic)",
      name: 'MD (Ayurvedic)'
    }
  ]

  const hospitalManagementList = [
    {
      id: "Director",
      name: 'Director'
    },
    {
      id: "Secretory",
      name: 'Secretory'
    },
    {
      id: "Billing Head",
      name: 'Billing Head'
    }
  ]

  const facilityAvailableList = [
    {
      id: "ICU",
      name: 'ICU'
    },
    {
      id: "OT",
      name: 'OT'
    },
    {
      id: "Cath Lab",
      name: 'Cath Lab'
    },
    {
      id: "Dialysis",
      name: 'Dialysis'
    }
  ]

  const {
    AuthReducer: { auth,errors,loading },
    healthReducer: {
      healthPermissions,
      healthLists,
      loadingHealth,
      errorsHealth,
      healthCount,
      changedHealthStatus,
    },
    masterReducer: {
      loadingMaster,
      masterStateLists,
      masterCityStateWiseLists,
      masterInsuranceList,
      masterQualificationList,
      masterHospitalList,
      masterDesignationList,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;


  useEffect(() => {
    if (auth?.token)
    {
      dispatch(getAllStateList(auth.token));
      dispatch(getMasterInsuranceList(auth.token));
      dispatch(getMasterQualificationList(auth.token));
      dispatch(getMasterDesignationList(auth.token));
      dispatch(getMasterClaimTypeList(auth.token));
      dispatch(getMasterHospitalList(auth.token));
    }
  }, [auth?.token]);


  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);


  const [validated, setValidated] = useState(false);
  const autocompleteRef = useRef(null);
  

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    // if(basicSaveId){
    //   dispatch(updateBasicSaveData(auth?.token, formData, basicSaveId));
    // } else {
    //   dispatch(saveBasicSaveData(auth?.token, formData));
    // }
  };


  const fetchHealthList = useCallback(() => {
    if (auth?.token) {
      dispatch(getHealthList(auth.token, paginate, page, search, sort));
    }
  }, [auth, paginate, page, search, sort, dispatch]);

  useDebounce(fetchHealthList, 500, [auth, paginate, page, search, sort]);


  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])


  useEffect(() => {
    if(changedHealthStatus?.status)
    {
      swal.fire({
        show: true,
        title: "Deleted!",
        text: changedHealthStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_HEALTH_STATUS' });
      dispatch(getHealthList(auth?.token, paginate, page, search, sort));
    }
    else if(changedHealthStatus.success)
    {
      swal.fire({
        show: true,
        title: "Great !",
        text: changedHealthStatus.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_HEALTH_STATUS' });
      dispatch(getHealthList(auth?.token, paginate, page, search, sort));
    }
  },[changedHealthStatus])


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };


  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? healthCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1);
  };


  const handleDeleteRequest = (id) => {
    swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, delete it!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteHealthData(auth?.token,id))
      }
    });
  };


  const [filters, setFilters] = useState({});
  const [modal, setModal] = useState({ show: false, key: "", type: "", value: "" });


  const handleFilterClick = (key) => {
    if (!filters[key]) {
      setFilters((prev) => ({
        ...prev,
        [key]: { type: "", value: "" },
      }));
    }
    setModal({ show: true, key, type: filters[key]?.type || "", value: filters[key]?.value || "" });
  };


  const formattedExpiryDate = formData.expiry_date ? formData.expiry_date.split('T')[0] : '';
  const formattedRenewalDate = formData.renewal_date ? formData.renewal_date.split('T')[0] : '';
  const formattedCertificateDate = formData.certificate_date ? formData.certificate_date.split('T')[0] : '';


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Case Management"), path: "/Health" },
          { label: t("Health"), path: "/Health", active: true },
        ]}
        title={t("Health Other Details")}
      />


      <Row>
        <Col xl={12} lg={12}>
          <Tab.Container defaultActiveKey="timeline">
            <Row>
              <Col lg={3}>
                <Card>
                  <Card.Body>
                    <Nav
                      variant="pills"
                      as="ul"
                      className="nav nav-pills nav-fill navtab-bg"
                      style={{
                        maxHeight: "450px",
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}     
                    >
                      <Row>
                        <Col lg={12}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="uploadDocument"
                              className="nav-link cursor-pointer"
                            >
                              Upload Case Document
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="patientCase"
                              className="nav-link cursor-pointer"
                            >
                              Patient Case Data
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="policyData"
                              className="nav-link cursor-pointer"
                            >
                              Policy Data
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="groupDetails"
                              className="nav-link cursor-pointer"
                            >
                              Group Details
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="agent"
                              className="nav-link cursor-pointer"
                            >
                              Agent
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="accidentalCases"
                              className="nav-link cursor-pointer"
                            >
                              Accidental Cases
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="familyDoctor"
                              className="nav-link cursor-pointer"
                            >
                              Family Doctor Details
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="hospitalData"
                              className="nav-link cursor-pointer"
                            >
                              Hospital Data
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="treatingDoctor"
                              className="nav-link cursor-pointer"
                            >
                              Treating Doctor Details
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="referralDoctor"
                              className="nav-link cursor-pointer"
                            >
                              Referral Doctor Details
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="pathologyDetails"
                              className="nav-link cursor-pointer"
                            >
                              Pathology Details
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="pathologistDetails"
                              className="nav-link cursor-pointer"
                            >
                              Pathologist Details
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="radiologyDetails"
                              className="nav-link cursor-pointer"
                            >
                              Radiology Details
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="radiologistDetails"
                              className="nav-link cursor-pointer"
                            >
                              Radiologist Details
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="pharmacyData"
                              className="nav-link cursor-pointer"
                            >
                              Pharmacy Data
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="triggerManagement"
                              className="nav-link cursor-pointer"
                            >
                              Trigger Management
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="allocationManagement"
                              className="nav-link cursor-pointer"
                            >
                              Allocation Management
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="reportManagement"
                              className="nav-link cursor-pointer"
                            >
                              Report Management
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="submission"
                              className="nav-link cursor-pointer"
                            >
                              Submission
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="billing"
                              className="nav-link cursor-pointer"
                            >
                              Billing
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="dispatch"
                              className="nav-link cursor-pointer"
                            >
                              Dispatch
                            </Nav.Link>
                          </Nav.Item>
                        </Col>

                        <Col lg={12} style={{ marginTop: "4%" }}>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              href="#"
                              eventKey="foPayment"
                              className="nav-link cursor-pointer"
                            >
                              FO Payment
                            </Nav.Link>
                          </Nav.Item>
                        </Col>
                      </Row>
                    </Nav>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={9}>
                <Card>
                  <Card.Body>
                    <Tab.Content>
                      <Tab.Pane eventKey="uploadDocument">
                        <Row>
                          <Col>
                            <Card>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Document")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.document_name}
                                      name="document_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Case Document")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.case_document}
                                      name="case_document"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Authority Letter")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.authority_letter}
                                      name="authority_letter"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Upload Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.upload_date}
                                      name="upload_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Re-Upload Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.reupload_date}
                                      name="reupload_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={8}>
                                    <FormInput
                                      label={t("Remark for Documents")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.document_remark}
                                      name="document_remark"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Card>

                            <Card>
                              <Card.Body>
                                <div>
                                  <Table className="mb-0 table-sm" responsive="sm" striped bordered hover >
                                    <thead>
                                      <tr>
                                        <th>Sr No.</th>
                                        <th>
                                          Document Name<br />
                                          {healthPermissions?.filter && (<>
                                            <Button variant="link" onClick={() => handleFilterClick("document_name")}><FontAwesomeIcon icon={faFilter} /></Button>
                                          </>)}
                                        </th>

                                        <th>
                                          Case Document<br />
                                          {healthPermissions?.filter && (<>
                                            <Button variant="link" onClick={() => handleFilterClick("case_document")}><FontAwesomeIcon icon={faFilter} /></Button>
                                          </>)}
                                        </th>

                                        <th>
                                          Authority Letter<br />
                                          {healthPermissions?.filter && (<>
                                            <Button variant="link" onClick={() => handleFilterClick("authority_letter")}><FontAwesomeIcon icon={faFilter} /></Button>
                                          </>)}
                                        </th>

                                        <th>
                                          Upload Date<br />
                                          {healthPermissions?.filter && (<>
                                            <Button variant="link" onClick={() => handleFilterClick("upload_date")}><FontAwesomeIcon icon={faFilter} /></Button>
                                          </>)}
                                        </th>

                                        <th>
                                          Re-Upload Date<br />
                                          {healthPermissions?.filter && (<>
                                            <Button variant="link" onClick={() => handleFilterClick("reupload_date")}><FontAwesomeIcon icon={faFilter} /></Button>
                                          </>)}
                                        </th>

                                        <th>
                                          Remark for Documents<br />
                                          {healthPermissions?.filter && (<>
                                            <Button variant="link" onClick={() => handleFilterClick("document_remarks")}><FontAwesomeIcon icon={faFilter} /></Button>
                                          </>)}
                                        </th>
                                        {(healthPermissions.edit || healthPermissions.delete) && (
                                          <>
                                            <th>Action</th>
                                          </>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                    {healthLists.length === 0 ? (
                                      <tr>
                                        <td colSpan="8">No data found</td>
                                      </tr>
                                    ) : (<>
                                      {healthLists?.map?.((item, index) => {
                                        return (
                                          <tr key={item.id}>
                                            <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                                            <td>{item?.document_name}</td>
                                            <td>{item?.case_document}</td>
                                            <td>{item?.authority_letter}</td>
                                            <td>{getDisplayDateTimeFormat(item?.upload_date)}</td>
                                            <td>{getDisplayDateTimeFormat(item?.reupload_date)}</td>
                                            <td>{item?.document_remark}</td>
                                            {(healthPermissions.edit || healthPermissions.delete) && (
                                              <>
                                                <td>
                                                  {(healthPermissions.delete) && (
                                                    <>
                                                      <Link to="#" className="action-icon text-danger" onClick={() => handleDeleteRequest(item?.id)} >
                                                        {" "}
                                                        <i className="mdi mdi-delete"></i>
                                                      </Link>
                                                    </>
                                                  )}
                                                </td>
                                              </>
                                            )}
                                          </tr>
                                        );
                                      })}</>
                                    )}
                                    </tbody>
                                  </Table>
                                </div>
                                <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
                                  <div class="d-inline-block me-3">
                                    <pharmacyel class="me-1">Display :</pharmacyel>
                                    <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  </div>
                                  <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(healthCount / paginate)}</strong> </span>
                                  <RoundedPagination currentPage={page} totalPages={Math.ceil(healthCount / paginate)} onPageChange={(pageNumber) => setPage(pageNumber)}/>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Tab.Pane>

                      <Tab.Pane eventKey="patientCase">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Basic Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Patient Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_name}
                                      name="patient_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Name of Insured ( Only For Death / PA )")}</Form.Label>
                                      <Select
                                        options={masterInsuranceList}
                                        values={masterInsuranceList?.filter(ins => ins.id === formData.insured_name)}
                                        name="insured_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "insured_name")
                                        }}
                                      />
                                      
                                      {errorsHealth?.data?.insured_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.insured_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Salutation`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                                        name="issuing_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "issuing_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.issuing_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.issuing_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`First Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_owner_first}
                                      name="hospital_owner_first"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Middle Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_owner_middle}
                                      name="hospital_owner_middle"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Last Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_owner_last}
                                      name="hospital_owner_last"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Gender")}</Form.Label>
                                      <Select
                                        options={genderList}
                                        values={genderList?.filter(gen => gen?.id === formData?.gender)}
                                        name="gender"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "gender")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.gender && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.gender}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px", marginTop: "14px"}}>Residential Address (As per Document)</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px", marginTop: "14px"}}>Permanent Address</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px", marginTop: "14px"}}>Address (As per Field)</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Latitude")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.latitude}
                                      name="latitude"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Longitude")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.longitude}
                                      name="longitude"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Patient (Unique Health Identification Number)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_uhid}
                                      name="patient_uhid"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Patient Live Photo")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_live_photo}
                                      name="patient_live_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Patient Live Photo (As per Document)")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_document_photo}
                                      name="patient_document_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Patient Live Photo (As per Aadhaar Card)")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_aadhaar_document}
                                      name="patient_aadhaar_document"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Residential Photo (Only For Death Claim)")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.longitude}
                                      name="longitude"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Wallmount (PA Death Claim)")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.wallmount}
                                      name="wallmount"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Face Match")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.face_match}
                                      name="face_match"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Marital Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Married")}
                                        name="marital_status"
                                        value="Married"
                                        checked={formData.marital_status === "Married"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Unmarried")}
                                        name="marital_status"
                                        value="Unmarried"
                                        checked={formData.marital_status === "Unmarried"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Contact Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Contact No.")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.sent_to_contact_no}
                                      name="sent_to_contact_no"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Email ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.sent_to_email}
                                      name="sent_to_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={2}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                KYC Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("KYC Authentication Type")}</Form.Label>
                                      <Select
                                        options={authentificationList}
                                        values={authentificationList?.filter((authen) => authen?.id === formData?.kyc_authentication)}
                                        name="kyc_authentication"
                                        placeholder={t("Select KYC Authentication Type")}
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          setFormData(prevFormData => ({
                                            ...prevFormData,
                                            'kyc_authentication': selectedData[0]?.id
                                          }));
                                        }}
                                        styles={null}
                                      />
                                      {errorsHealth?.data?.kyc_authentication && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">
                                            {errorsHealth?.data?.kyc_authentication}
                                          </p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("KYC Document Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.document_upload}
                                      name="document_upload"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  {formData?.kyc_authentication === "Other Document" ? (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("KYC Document Name")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.kyc_document_name}
                                        name="kyc_document_name"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  ) : (
                                    <>
                                      <Col lg={4}>
                                        <div
                                          style={{ position: "relative" }}
                                          onClick={() => window.open(`${linksMap[formData?.kyc_authentication]}` || "", "_blank")}
                                        >
                                          <FormInput
                                            label={t("KYC Authentication Check")}
                                            type="text"
                                            errors={errorsHealth?.data}
                                            value={linksMap[formData?.kyc_authentication] || ""}
                                            name="kyc_authentification_link"
                                            onChange={linksMap[formData?.kyc_authentication]}
                                            containerClass={"mb-3"}
                                            readOnly
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("KYC Authentication Photo")}
                                          type="file"
                                          errors={errorsHealth?.data}
                                          value={formData.link_verification_upload}
                                          name="link_verification_upload"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Comment")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData.link_verification_comment}
                                          name="link_verification_comment"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </>
                                  )}
                                </Row>


                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={3}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Other Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={3}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Patient Related")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.patient_related}
                                      name="patient_related"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Education")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.education}
                                      name="education"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Account Holder Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.account_holder_name}
                                      name="account_holder_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Bank Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.bank_account_name}
                                      name="bank_account_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Bank Account Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.bank_account_number}
                                      name="bank_account_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("IFSC Code")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.ifsc_code}
                                      name="ifsc_code"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Branch Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.branch_name}
                                      name="branch_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Account Type")}</Form.Label>
                                      <Select
                                        options={accountTypeList}
                                        values={accountTypeList?.filter(account => account?.id === formData?.account_type)}
                                        name="account_type"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "account_type")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.account_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.account_type}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Bank</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House No / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={4}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Job Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={4}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Occupation")}</Form.Label>
                                      <Select
                                        options={categoryList}
                                        values={categoryList?.filter(cate => cate?.id === formData?.occupation)}
                                        name="occupation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "occupation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.occupation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.occupation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employee ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.employee_id}
                                      name="employee_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employer Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.employer_name}
                                      name="employer_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Designation")}</Form.Label>
                                      <Select
                                        options={masterDesignationList}
                                        values={masterDesignationList?.filter(state => state.id === formData.designation_id)}
                                        name="designation_id"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "designation_id")
                                        }}
                                      />
                                      {errorsHealth?.data?.designation_id && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.designation_id}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Employer</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Joining (Current Organisation)")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData?.date_of_joining}
                                      name="date_of_joining"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Last Drawn Salary")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.drawn_salary}
                                      name="drawn_salary"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Sick Leave Details")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.sick_leave}
                                      name="sick_leave"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employer Group Health Policy Details")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.group_health}
                                      name="group_health"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={8}>
                                    <FormInput
                                      label={t("Any Claim Lodged in Group Health Insurance Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.insurance_policy}
                                      name="insurance_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Contact No.")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.sent_to_contact_no}
                                      name="sent_to_contact_no"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Email ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.sent_to_email}
                                      name="sent_to_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey={4-1}
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Employer Social Media
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={4-1}>
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Profesional Website")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Whatsapp")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Instagram Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_instagram_link}
                                              name="hospital_instagram_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Facebook Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_facebook_link}
                                              name="hospital_facebook_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Linkdin Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_linkdin_link}
                                              name="hospital_linkdin_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Twitter Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_twitter_link}
                                              name="hospital_twitter_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Truecaller Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_truecaller_link}
                                              name="hospital_truecaller_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Justdial Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_justdial_link}
                                              name="hospital_justdial_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Eyecon Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Other Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Details of Previous Employer</span>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employer Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.previous_employer_name}
                                      name="previous_employer_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Previous Employer</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={5}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Income Verification
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={5}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Income")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.income}
                                      name="income"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Proof of Income")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.income_proof}
                                      name="income_proof"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Standard of Living")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.standard_living}
                                      name="standard_living"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Financial Background of Insured")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.background_financial}
                                      name="background_financial"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Purpose for Insurance")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.insurance_purpose}
                                      name="insurance_purpose"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={6}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Personal Habits
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={6}>
                              <Card.Body>
                                
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={7}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Vicinity Check
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={7}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("GPS / AVR")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.vacinity_gps_photo}
                                      name="vacinity_gps_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Neighbour Visit </span>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.neigbour_name}
                                      name="neigbour_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("GPS / AVR")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.neighbour_gps_photo}
                                      name="neighbour_gps_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={8}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Nominee Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={8}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Relationship of Patient with Nominee")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.relationship_with_nominee}
                                      name="relationship_with_nominee"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Salutation")}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.nominee_salutation)}
                                        name="nominee_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "nominee_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.nominee_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.nominee_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("First Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.nominee_first_name}
                                      name="nominee_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Middle Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.nominee_middle_name}
                                      name="nominee_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Last Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.nominee_last_name}
                                      name="nominee_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Gender")}</Form.Label>
                                      <Select
                                        options={genderList}
                                        values={genderList?.filter(gen => gen?.id === formData?.gender)}
                                        name="gender"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "gender")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.gender && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.gender}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Nominee</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Contact No.")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.sent_to_contact_no}
                                      name="sent_to_contact_no"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Email ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.sent_to_email}
                                      name="sent_to_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}></Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("KYC Authentication Type")}</Form.Label>
                                      <Select
                                        options={authentificationList}
                                        values={authentificationList?.filter((authen) => authen?.id === formData?.kyc_authentication_nominee)}
                                        name="kyc_authentication_nominee"
                                        placeholder={t("Select KYC Authentication Type")}
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          setFormData(prevFormData => ({
                                            ...prevFormData,
                                            'kyc_authentication_nominee': selectedData[0]?.id
                                          }));
                                        }}
                                        styles={null}
                                      />
                                      {errorsHealth?.data?.kyc_authentication_nominee && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">
                                            {errorsHealth?.data?.kyc_authentication_nominee}
                                          </p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("KYC Document Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.document_upload_nominee}
                                      name="document_upload_nominee"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  {formData?.kyc_authentication_nominee === "Other Document" ? (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("KYC Document Name")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.kyc_document_name_nominee}
                                        name="kyc_document_name_nominee"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  ) : (
                                    <>
                                      <Col lg={4}>
                                        <div
                                          style={{ position: "relative" }}
                                          onClick={() => window.open(`${linksMap[formData?.kyc_authentication_nominee]}` || "", "_blank")}
                                        >
                                          <FormInput
                                            label={t("KYC Authentication Check")}
                                            type="text"
                                            errors={errorsHealth?.data}
                                            value={linksMap[formData?.kyc_authentication_nominee] || ""}
                                            name="kyc_authentification_link_nominee"
                                            onChange={linksMap[formData?.kyc_authentication_nominee]}
                                            containerClass={"mb-3"}
                                            readOnly
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("KYC Authentication Photo")}
                                          type="file"
                                          errors={errorsHealth?.data}
                                          value={formData.link_verification_upload_nominee}
                                          name="link_verification_upload_nominee"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Comment")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData.link_verification_comment_nominee}
                                          name="link_verification_comment_nominee"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </>
                                  )}
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Live of Photo of Nominee")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.live_photo_nominee}
                                      name="live_photo_nominee"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Occupation")}</Form.Label>
                                      <Select
                                        options={categoryList}
                                        values={categoryList?.filter(cate => cate?.id === formData?.occupation)}
                                        name="occupation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "occupation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.occupation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.occupation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employee ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.employee_id}
                                      name="employee_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employer Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.employer_name}
                                      name="employer_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Employer</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employer Contact No.")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.employer_contact_no}
                                      name="employer_contact_no"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employer Email ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.employer_email}
                                      name="employer_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey={4-1}
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Employer Social Media
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={4-1}>
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Profesional Website")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Whatsapp")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Instagram Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_instagram_link}
                                              name="hospital_instagram_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Facebook Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_facebook_link}
                                              name="hospital_facebook_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Linkdin Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_linkdin_link}
                                              name="hospital_linkdin_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Twitter Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_twitter_link}
                                              name="hospital_twitter_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Truecaller Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_truecaller_link}
                                              name="hospital_truecaller_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Justdial Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_justdial_link}
                                              name="hospital_justdial_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Eyecon Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Other Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Income")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.income}
                                      name="income"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Proof of Income")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.income_proof}
                                      name="income_proof"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Bank Details of Nominee</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House No / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={9}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Other Relatives
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={9}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Relation With Patient/ Insured")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.relation_with_patient}
                                      name="relation_with_patient"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Salutation of Relative")}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.relative_salutation)}
                                        name="relative_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "relative_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.relative_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.relative_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("First Name of Relative")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.relative_first_name}
                                      name="relative_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Middle Name of Relative")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.relative_middle_name}
                                      name="relative_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Last Name of Relative")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.relative_last_name}
                                      name="relative_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Gender of Relative")}</Form.Label>
                                      <Select
                                        options={genderList}
                                        values={genderList?.filter(gen => gen?.id === formData?.relative_gender)}
                                        name="relative_gender"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "relative_gender")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.relative_gender && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.relative_gender}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Birth of Relative")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.dob_relative}
                                      name="dob_relative"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Age of Relative")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.age_of_relative}
                                      name="age_of_relative"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Relative</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Contact No. of Relative")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.relative_contact_no}
                                      name="relative_contact_no"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Email ID of Relative")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.relative_email}
                                      name="relative_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}></Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("KYC Authentication Type")}</Form.Label>
                                      <Select
                                        options={authentificationList}
                                        values={authentificationList?.filter((authen) => authen?.id === formData?.kyc_authentication_nominee)}
                                        name="kyc_authentication_nominee"
                                        placeholder={t("Select KYC Authentication Type")}
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          setFormData(prevFormData => ({
                                            ...prevFormData,
                                            'kyc_authentication_nominee': selectedData[0]?.id
                                          }));
                                        }}
                                        styles={null}
                                      />
                                      {errorsHealth?.data?.kyc_authentication_nominee && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">
                                            {errorsHealth?.data?.kyc_authentication_nominee}
                                          </p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("KYC Document Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.document_upload_nominee}
                                      name="document_upload_nominee"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  {formData?.kyc_authentication_nominee === "Other Document" ? (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("KYC Document Name")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.kyc_document_name_nominee}
                                        name="kyc_document_name_nominee"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  ) : (
                                    <>
                                      <Col lg={4}>
                                        <div
                                          style={{ position: "relative" }}
                                          onClick={() => window.open(`${linksMap[formData?.kyc_authentication_nominee]}` || "", "_blank")}
                                        >
                                          <FormInput
                                            label={t("KYC Authentication Check")}
                                            type="text"
                                            errors={errorsHealth?.data}
                                            value={linksMap[formData?.kyc_authentication_nominee] || ""}
                                            name="kyc_authentification_link_nominee"
                                            onChange={linksMap[formData?.kyc_authentication_nominee]}
                                            containerClass={"mb-3"}
                                            readOnly
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("KYC Authentication Photo")}
                                          type="file"
                                          errors={errorsHealth?.data}
                                          value={formData.link_verification_upload_nominee}
                                          name="link_verification_upload_nominee"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Comment")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData.link_verification_comment_nominee}
                                          name="link_verification_comment_nominee"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </>
                                  )}
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Occupation of Relative")}</Form.Label>
                                      <Select
                                        options={categoryList}
                                        values={categoryList?.filter(cate => cate?.id === formData?.relative_occupation)}
                                        name="relative_occupation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "relative_occupation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.relative_occupation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.relative_occupation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employee ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.employee_id}
                                      name="employee_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employer Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.employer_name}
                                      name="employer_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Employer</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employer Contact No.")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.employer_contact_no}
                                      name="employer_contact_no"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employer Email ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.employer_email}
                                      name="employer_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey={4-1}
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Employer Social Media
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={4-1}>
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Profesional Website")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Whatsapp")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Instagram Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_instagram_link}
                                              name="hospital_instagram_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Facebook Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_facebook_link}
                                              name="hospital_facebook_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Linkdin Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_linkdin_link}
                                              name="hospital_linkdin_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Twitter Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_twitter_link}
                                              name="hospital_twitter_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Truecaller Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_truecaller_link}
                                              name="hospital_truecaller_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Justdial Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_justdial_link}
                                              name="hospital_justdial_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Eyecon Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Other Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Income")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.income}
                                      name="income"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Proof of Income")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.income_proof}
                                      name="income_proof"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Bank Details of Relative</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House No / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={10}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Vicinity Check
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={10}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("GPS / AVR")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.vacinity_gps_photo}
                                      name="vacinity_gps_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Neighbour Visit </span>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.neigbour_name}
                                      name="neigbour_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("GPS / AVR")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.neighbour_gps_photo}
                                      name="neighbour_gps_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Statement")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.vacinity_statement}
                                      name="vacinity_statement"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={11}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Only Death Claims
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={11}>
                              <Card.Body>
                                <Row>
                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Financial sounding of the insured prior to death – Any debt or liabilities ")}
                                      name="financial_sounding"
                                      value="Yes"
                                      checked={formData.financial_sounding}
                                      onChange={(e) => inputHandler({ target: { name: "financial_sounding", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Whether Insured and deceased one and the same?")}
                                      name="insured_deceased"
                                      value="Yes"
                                      checked={formData.insured_deceased}
                                      onChange={(e) => inputHandler({ target: { name: "insured_deceased", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Is or was insured or LA was mentally or physically challenged?")}
                                      name="mentally_physically"
                                      value="Yes"
                                      checked={formData.mentally_physically}
                                      onChange={(e) => inputHandler({ target: { name: "mentally_physically", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Eyewitness Statement")}
                                      name="eyewitness_statement"
                                      value="Yes"
                                      checked={formData.eyewitness_statement}
                                      onChange={(e) => inputHandler({ target: { name: "eyewitness_statement", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("How and when were the relatives informed of the accident?")}
                                      name="informed_accident"
                                      value="Yes"
                                      checked={formData.informed_accident}
                                      onChange={(e) => inputHandler({ target: { name: "informed_accident", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Who was the first person to hear about the accident?")}
                                      name="hear_about_accident"
                                      value="Yes"
                                      checked={formData.hear_about_accident}
                                      onChange={(e) => inputHandler({ target: { name: "hear_about_accident", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={12}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Proposal from Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={12}>
                              <Card.Body>
                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Life Insured (As per Proposal Form)</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Age of Life Assured")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.life_assured_age}
                                      name="life_assured_age"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Occupation of Life Assured")}</Form.Label>
                                      <Select
                                        options={categoryList}
                                        values={categoryList?.filter(cate => cate?.id === formData?.life_assured_occupation)}
                                        name="life_assured_occupation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "life_assured_occupation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.life_assured_occupation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.life_assured_occupation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Income")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.income}
                                      name="income"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Proof of Income")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.income_proof}
                                      name="income_proof"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Contact No. of Life Assured")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.life_assured_contact_no}
                                      name="life_assured_contact_no"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Email ID of Life Assured")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.life_assured_email}
                                      name="life_assured_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={13}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Case Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={13}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Admission (As per Patient)")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.admission_date}
                                      name="admission_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Discharge (As Per Patient)")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.discharge_date}
                                      name="discharge_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Diagnosis (As per Patient)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.diagnosis}
                                      name="diagnosis"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Complain History (As per Patient)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.complain_history}
                                      name="complain_history"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Patient Statement Details")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_statement}
                                      name="patient_statement"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={14}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Other Policy Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={14}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Insurance Company")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.insurance_company}
                                      name="insurance_company"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Policy Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.policy_number}
                                      name="policy_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Policy Issue Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.policy_issue_date}
                                      name="policy_issue_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Branch Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.branch_name}
                                      name="branch_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Claim Status")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Active")}
                                        name="claim_status"
                                        value="Active"
                                        checked={formData.claim_status === "Active"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("In-Active")}
                                        name="claim_status"
                                        value="In-Active"
                                        checked={formData.claim_status === "In-Active"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={15}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Visit Finding
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={15}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Patient Visit Done")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="patient_visit"
                                        value="Yes"
                                        checked={formData.patient_visit === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="patient_visit"
                                        value="No"
                                        checked={formData.patient_visit === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.patient_visit === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Reason If Not Visit Done")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_visit_not_done}
                                        name="reason_visit_not_done"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Patient Appointment")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_appointment_date}
                                      name="patient_appointment_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Patient Appointment")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_appointment_time}
                                      name="patient_appointment_time"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Person Giving Appointment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_giving_appointment}
                                      name="person_giving_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Patient Visit")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_visit_date}
                                      name="patient_visit_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Patient Visit")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_visit_time}
                                      name="patient_visit_time"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Person Giving Appointment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_giving_appointment_two}
                                      name="person_giving_appointment_two"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Who Met on Behalf of the Patient?")}</Form.Label>
                                      <Select
                                        options={behalfPatientList}
                                        values={behalfPatientList?.filter(beha => beha?.id === formData?.behalf_patient)}
                                        name="behalf_patient"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "behalf_patient")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.behalf_patient && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.behalf_patient}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of the Person Who Met on Behalf of the Patient ")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_met_behalf_patient}
                                      name="person_met_behalf_patient"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Location of Visit</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Is it Match with Patient Address?")}
                                      name="match_with_patient_address"
                                      value="Yes"
                                      checked={formData.match_with_patient_address}
                                      onChange={(e) => inputHandler({ target: { name: "match_with_patient_address", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={16}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Patient Questioner
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={16}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Patient Questioner")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_questioner}
                                      name="patient_questioner"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Upload the patient Statement")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.upload_patient_statement}
                                      name="upload_patient_statement"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Person Giving Statement ")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_name_giving}
                                      name="person_name_giving"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Mobile Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.mobile_number}
                                      name="mobile_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Patient Current Weight")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_current_weight}
                                      name="patient_current_weight"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Patient Height")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_height}
                                      name="patient_height"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={6}>
                                    <FormInput
                                      label={t("What has been your (Patient) Weight at the Time of Taking Captioned Policy for the First Time")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.weight_time_policy_first_time}
                                      name="weight_time_policy_first_time"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Any increase or decrease in weight in past five to seven years, if drastic gain or loss-please mention the reason")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.increase_decrease_weight_reason}
                                      name="increase_decrease_weight_reason"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={17}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Alert
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={17}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator Username")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_username}
                                      name="alert_creator_username"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_id}
                                      name="alert_creator_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Date & Time of Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.date_time_alert}
                                      name="date_time_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Comment on Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.comment_alert}
                                      name="comment_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={19}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grievance Management
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={19}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Grievance and Escalation")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Grievance")}
                                        name="grievance_status"
                                        value="Grievance"
                                        checked={formData.grievance_status === "Grievance"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Escalation")}
                                        name="grievance_status"
                                        value="Escalation"
                                        checked={formData.grievance_status === "Escalation"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <br />
                                {formData.grievance_status && (<>
                                  <Row>
                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Type of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.type_of_grievance}
                                        name="type_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Date of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.date_of_grievance}
                                        name="date_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Notification of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.notification_of_grievance}
                                        name="notification_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Reason of the ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_of_grievance}
                                        name="reason_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Status of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.status_of_grievance}
                                        name="status_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Final Conclusion of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.final_of_grievance}
                                        name="final_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  </Row>
                                </>)}
                                
                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={20}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Special Remarks
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={20}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Comments")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.comments}
                                      name="comments"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane eventKey="policyData">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Policy Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Insured UHID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.insured_uhid}
                                      name="insured_uhid"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Policy Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.insured_uhid}
                                      name="insured_uhid"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Plan Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.plan_name}
                                      name="plan_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Sub Plan Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.sub_plan_name}
                                      name="sub_plan_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Type of Policy")}</Form.Label>
                                      <Select
                                        options={masterInsuranceList}
                                        values={masterInsuranceList?.filter(ins => ins.id === formData.type_of_policy)}
                                        name="type_of_policy"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "type_of_policy")
                                        }}
                                      />
                                      
                                      {errorsHealth?.data?.type_of_policy && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.type_of_policy}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Policy Nature")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.policy_nature}
                                      name="policy_nature"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Product Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.product_name}
                                      name="product_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Risk Inception Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.risk_inception_date}
                                      name="risk_inception_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("First Policy Inception Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.first_policy_inception_date}
                                      name="first_policy_inception_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("No. of Years of Renewal")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.years_of_renewal}
                                      name="years_of_renewal"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Generation")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.generation}
                                      name="generation"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Risk Expiry Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.risk_expiry_date}
                                      name="risk_expiry_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Policy Status")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="policy_status"
                                        value="Yes"
                                        checked={formData.policy_status === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="policy_status"
                                        value="No"
                                        checked={formData.policy_status === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Policy Cancellation Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.policy_cancellation_date}
                                      name="policy_cancellation_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Policy Age")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.policy_age}
                                      name="policy_age"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Sum Insured")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.sum_insured}
                                      name="sum_insured"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Balance Sum")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.balance_sum}
                                      name="balance_sum"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Policy Risk Serial Sum Insured")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.policy_risk_sum_insured}
                                      name="policy_risk_sum_insured"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Sum Insured Generation")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.sum_insured_generation}
                                      name="sum_insured_generation"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Type of Proposal`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.type_of_proposal)}
                                        name="type_of_proposal"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "type_of_proposal")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.type_of_proposal && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.type_of_proposal}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Premium Amount`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.premium_amount}
                                      name="premium_amount"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Estimated Loss Amount`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.estimated_loss_amount}
                                      name="estimated_loss_amount"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Allowed Amount`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.allowed_amount}
                                      name="allowed_amount"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Co-Pay Amount`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.co_pay_amount}
                                      name="co_pay_amount"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Disallowed Amount`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.disallowed_amount}
                                      name="disallowed_amount"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Disallowance Reason`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.disallowed_amount}
                                      name="disallowed_amount"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Disclosure in Policy`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.disclosure_policy}
                                      name="disclosure_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Close Proximity`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.close_proximity}
                                      name="close_proximity"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Member Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Member Details Available")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="member_available"
                                        value="Yes"
                                        checked={formData.member_available === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="member_available"
                                        value="No"
                                        checked={formData.member_available === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Relation With Patient")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.member_relation_patient}
                                      name="member_relation_patient"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Salutation of Member`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.salutation_member)}
                                        name="salutation_member"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "salutation_member")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.salutation_member && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.salutation_member}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`First Name of Member`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_first}
                                      name="member_first"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Middle Name of Member`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_middle}
                                      name="member_middle"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Last Name of Member`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_last}
                                      name="member_last"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Date of Birth")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_dob}
                                      name="member_dob"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Age")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_age}
                                      name="member_age"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Member Gender")}</Form.Label>
                                      <Select
                                        options={genderList}
                                        values={genderList?.filter(gen => gen?.id === formData?.member_gender)}
                                        name="member_gender"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "member_gender")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.member_gender && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.member_gender}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("KYC Authentication Type")}</Form.Label>
                                      <Select
                                        options={authentificationList}
                                        values={authentificationList?.filter((authen) => authen?.id === formData?.kyc_authentication)}
                                        name="kyc_authentication"
                                        placeholder={t("Select KYC Authentication Type")}
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          setFormData(prevFormData => ({
                                            ...prevFormData,
                                            'kyc_authentication': selectedData[0]?.id
                                          }));
                                        }}
                                        styles={null}
                                      />
                                      {errorsHealth?.data?.kyc_authentication && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">
                                            {errorsHealth?.data?.kyc_authentication}
                                          </p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("KYC Document Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.document_upload}
                                      name="document_upload"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  {formData?.kyc_authentication === "Other Document" ? (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("KYC Document Name")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.kyc_document_name}
                                        name="kyc_document_name"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  ) : (
                                    <>
                                      <Col lg={4}>
                                        <div
                                          style={{ position: "relative" }}
                                          onClick={() => window.open(`${linksMap[formData?.kyc_authentication]}` || "", "_blank")}
                                        >
                                          <FormInput
                                            label={t("KYC Authentication Check")}
                                            type="text"
                                            errors={errorsHealth?.data}
                                            value={linksMap[formData?.kyc_authentication] || ""}
                                            name="kyc_authentification_link"
                                            onChange={linksMap[formData?.kyc_authentication]}
                                            containerClass={"mb-3"}
                                            readOnly
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("KYC Authentication Photo")}
                                          type="file"
                                          errors={errorsHealth?.data}
                                          value={formData.link_verification_upload}
                                          name="link_verification_upload"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Comment")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData.link_verification_comment}
                                          name="link_verification_comment"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Inception Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.member_inception_date}
                                      name="member_inception_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Inception Date Portability")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_inception_date_portability}
                                      name="member_inception_date_portability"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Member Occupation")}</Form.Label>
                                      <Select
                                        options={categoryList}
                                        values={categoryList?.filter(cate => cate?.id === formData?.member_occupation)}
                                        name="member_occupation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "member_occupation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.member_occupation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.member_occupation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Joining Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.member_joining_date}
                                      name="member_joining_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Leaving Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.member_leaving_date}
                                      name="member_leaving_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("No of Members Covered Under Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_covered_under_policy}
                                      name="member_covered_under_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name Of Corporate")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.corporate_name}
                                      name="corporate_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={2}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Details of Port
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Portability Status")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="portability_status"
                                        value="Yes"
                                        checked={formData.portability_status === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="portability_status"
                                        value="No"
                                        checked={formData.portability_status === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Portability Flag")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.portability_flag}
                                      name="portability_flag"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Portability Reason")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.portability_reason}
                                      name="portability_reason"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Previous Insured")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.previous_insured}
                                      name="previous_insured"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Previous Sum Insured")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.previous_sum_insured}
                                      name="previous_sum_insured"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Previous Effective Start Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.previous_effective_start_date}
                                      name="previous_effective_start_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Previous Expiry Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.previous_expiry_date}
                                      name="previous_expiry_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Previously Covered by Any Other Mediclaim / Health Insurance?")}
                                      name="previously_other_health_insurance"
                                      value="Yes"
                                      checked={formData.previously_other_health_insurance}
                                      onChange={(e) => inputHandler({ target: { name: "previously_other_health_insurance", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={3}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Other Policy Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={3}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Insurance Company")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.insurance_company}
                                      name="insurance_company"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Policy Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.policy_number}
                                      name="policy_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Policy Issue Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.policy_issue_date}
                                      name="policy_issue_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Branch Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.branch_name}
                                      name="branch_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Claim Status")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Active")}
                                        name="claim_status"
                                        value="Active"
                                        checked={formData.claim_status === "Active"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("In-Active")}
                                        name="claim_status"
                                        value="In-Active"
                                        checked={formData.claim_status === "In-Active"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={4}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Alert
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={4}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator Username")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_username}
                                      name="alert_creator_username"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_id}
                                      name="alert_creator_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Date & Time of Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.date_time_alert}
                                      name="date_time_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Comment on Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.comment_alert}
                                      name="comment_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={5}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Data
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={5}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Data Related Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.data_related_policy}
                                      name="data_related_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane eventKey="groupDetails">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Basic Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={`Name of Corporate`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.name_of_corporate}
                                      name="name_of_corporate"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Corporate </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={`Corporate Contact No.`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_last_name}
                                      name="treating_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}>
                                    
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Corporate Email ID`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}>
                                    
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Number of Employee`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.agent_photo}
                                      name="agent_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>HR Manager Details </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`HR Manager Salutation`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                                        name="issuing_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "issuing_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.issuing_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.issuing_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`HR Manager First Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_owner_first}
                                      name="hospital_owner_first"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`HR Manager Middle Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_owner_middle}
                                      name="hospital_owner_middle"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`HR Manager Last Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_owner_last}
                                      name="hospital_owner_last"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`HR Manager Contact No.`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_last_name}
                                      name="treating_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`HR Manager Email ID`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}>
                                    
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`GPS Photo`}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.agent_photo}
                                      name="agent_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Company Details </span>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Establish Year")}
                                      type="number"
                                      errors={errorsHealth?.data}
                                      value={formData.establish_year}
                                      name="establish_year"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                      min={1900}
                                      max={new Date().getFullYear()}
                                      placeholder="YYYY"
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Company Type")}</Form.Label>
                                      <Select
                                        options={companyTypeList}
                                        values={companyTypeList?.filter((comp) => comp?.id === formData?.company_type) || []}
                                        name="company_type"
                                        placeholder={t("Select Company Type")}
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "company_type")
                                        }}
                                      />
                                      {errorsHealth?.data?.company_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">
                                            {errorsHealth?.data?.company_type}
                                          </p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey="0-1"
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Director of Company Details
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0-1">
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{`Director of Company Salutation`}</Form.Label>
                                              <Select
                                                options={salutationList}
                                                values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                                                name="issuing_salutation"
                                                labelField={"name"}
                                                valueField={"id"}
                                                searchBy={"name"}
                                                loading={loadingMaster}
                                                onChange={(selectedData) => {
                                                  selectHandler2(selectedData[0]?.id, "issuing_salutation")
                                                }}
                                                styles={null}
                                              />
                                              
                                              {errorsHealth?.data?.issuing_salutation && (
                                                <div className="invalid-feedback d-block">
                                                  <p className="text-danger">{errorsHealth?.data?.issuing_salutation}</p>
                                                </div>
                                              )}
                                            </Form.Group>
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={`Director of Company First Name`}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_owner_first}
                                              name="hospital_owner_first"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={`Director of Company Middle Name`}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_owner_middle}
                                              name="hospital_owner_middle"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={`Director of Company Last Name`}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_owner_last}
                                              name="hospital_owner_last"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{t("Designation of Director")}</Form.Label>
                                              <Select
                                                options={masterDesignationList}
                                                values={masterDesignationList?.filter(state => state.id === formData.designation_id)}
                                                name="designation_id"
                                                placeholder={t("Select Designation of Director")}
                                                labelField={"name"}
                                                valueField={"id"}
                                                searchBy={"name"}
                                                loading={loadingMaster}
                                                onChange={(selectedData) => {
                                                  selectHandler2(selectedData[0]?.id, "designation_id")
                                                }}
                                              />
                                              
                                              {errorsHealth?.data?.designation_id && (
                                                <div className="invalid-feedback d-block">
                                                  <p className="text-danger">{errorsHealth?.data?.designation_id}</p>
                                                </div>
                                              )}
                                            </Form.Group>
                                          </Col>

                                          <Col lg={4}>
                                            
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={`Director of Company Contact No.`}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.treating_last_name}
                                              name="treating_last_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4} style={{marginTop: '28px'}}>
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Save")}
                                            </Button>
                                          </Col>

                                          <Col lg={4}>
                                            
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={`Director of Company Email ID`}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.lab_name}
                                              name="lab_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4} style={{marginTop: '28px'}}>
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Save")}
                                            </Button>
                                          </Col>
                                        </Row>

                                        <div className="d-flex justify-content-center">
                                          {loading ? 
                                            <Button color="primary" disabled>
                                              <Spinner animation="border" size="sm" />
                                              {t("Please wait ...")}
                                            </Button>
                                          :
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Submit")}
                                            </Button>
                                          }
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={`Company Registration Number`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.company_registration_number}
                                      name="company_registration_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Corporate Identification Number (CIN)`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.corporate_identification_number}
                                      name="corporate_identification_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Group Relationship`}</Form.Label>
                                      <Select
                                        options={registrationList}
                                        values={registrationList?.filter(regis => regis?.id === formData?.type_of_registration)}
                                        name="type_of_registration"
                                        placeholder={t("Select Group Relationship")}
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "type_of_registration")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.type_of_registration && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.type_of_registration}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <label>{t(`Corporate Establish With EPFO`)}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="establish_epso"
                                        value="Yes"
                                        checked={formData.establish_epso === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="establish_epso"
                                        value="No"
                                        checked={formData.establish_epso === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Nature of Employment")}</Form.Label>
                                      <Select
                                        options={natureEmploymentList}
                                        values={natureEmploymentList?.filter((nature) => nature?.id === formData?.nature_employment) || []}
                                        name="nature_employment"
                                        placeholder={t("Select Nature of Employment")}
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "nature_employment")
                                        }}
                                      />
                                      {errorsHealth?.data?.nature_employment && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">
                                            {errorsHealth?.data?.nature_employment}
                                          </p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Online Verification Zauba Corp </span>
                                  <Col lg={4}>
                                    <div
                                      style={{ position: "relative" }}
                                      onClick={() => window.open(formData?.rohini_id_link || "https://www.zaubacorp.com/", "_blank")}
                                    >
                                      <FormInput
                                        label={t("Check on Zauba Corporate")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData?.rohini_id_link || "https://www.zaubacorp.com/"}
                                        name="rohini_id_link"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                        readOnly
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Zauba Corporate Uplaod")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.irda_link_upload}
                                      name="irda_link_upload"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Zauba Corporate Comment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.irda_link_comment}
                                      name="irda_link_comment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Field Visit Finding
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is Corporate Visit Done")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="corporate_visit_done"
                                        value="Yes"
                                        checked={formData.corporate_visit_done === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="corporate_visit_done"
                                        value="No"
                                        checked={formData.corporate_visit_done === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.corporate_visit_done === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Reason Corporate Visit Not Done")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.corporate_visit_not_remark}
                                        name="corporate_visit_not_remark"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Corporate Date of Appointment")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.corporate_date_appointment}
                                      name="corporate_date_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Corporate Time of Appointment")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.corporate_time_appointment}
                                      name="corporate_time_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Person Giving Appointment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_giving_appointment}
                                      name="person_giving_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Corporate Date of Visit")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.corporate_date_visit}
                                      name="corporate_date_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Corporate Time of Visit")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.corporate_time_visit}
                                      name="corporate_time_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Revisit")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.corporate_date_revisit}
                                      name="corporate_date_revisit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Revisit")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.corporate_time_revisit}
                                      name="corporate_time_revisit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Location of Visit </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Field Visit Photo")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.field_visit_photo}
                                      name="field_visit_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Attendence Record")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.attendence_record}
                                      name="attendence_record"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Sick Leave Record")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.sick_leave_record}
                                      name="sick_leave_record"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Any Other Group Insurance Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_group_insurance_policy}
                                      name="other_group_insurance_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Any Other Claim Lodged Under Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_claim_lodged_under_policy}
                                      name="other_claim_lodged_under_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employee ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.employee_id}
                                      name="employee_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Employee Designation")}</Form.Label>
                                      <Select
                                        options={masterDesignationList}
                                        values={masterDesignationList?.filter(state => state.id === formData.employee_designation_id)}
                                        name="employee_designation_id"
                                        placeholder={t("Select Employee Designation")}
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "employee_designation_id")
                                        }}
                                      />
                                      
                                      {errorsHealth?.data?.employee_designation_id && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.employee_designation_id}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Employee Salary Details")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.employee_salary}
                                      name="employee_salary"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("General Corporate Visit Remarks")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.general_corporate_visit_remark}
                                      name="general_corporate_visit_remark"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={2}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Alert
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator Username")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_username}
                                      name="alert_creator_username"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_id}
                                      name="alert_creator_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Date & Time of Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.date_time_alert}
                                      name="date_time_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Comment on Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.comment_alert}
                                      name="comment_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={3}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Related Data Search
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={3}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Data Related Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.data_related_policy}
                                      name="data_related_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={4}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grievance Management
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={4}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Grievance and Escalation")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Grievance")}
                                        name="grievance_status"
                                        value="Grievance"
                                        checked={formData.grievance_status === "Grievance"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Escalation")}
                                        name="grievance_status"
                                        value="Escalation"
                                        checked={formData.grievance_status === "Escalation"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <br />
                                {formData.grievance_status && (<>
                                  <Row>
                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Type of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.type_of_grievance}
                                        name="type_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Date of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.date_of_grievance}
                                        name="date_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Notification of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.notification_of_grievance}
                                        name="notification_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Reason of the ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_of_grievance}
                                        name="reason_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Status of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.status_of_grievance}
                                        name="status_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Final Conclusion of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.final_of_grievance}
                                        name="final_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  </Row>
                                </>)}
                                
                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={5}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Special Remarks
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={5}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Comments")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.comments}
                                      name="comments"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane eventKey="agent">
                        <Row>
                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{`Salutation of IMD / Agent / Advisor / Broker`}</Form.Label>
                              <Select
                                options={salutationList}
                                values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                                name="issuing_salutation"
                                labelField={"name"}
                                valueField={"id"}
                                searchBy={"name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.id, "issuing_salutation")
                                }}
                                styles={null}
                              />
                              
                              {errorsHealth?.data?.issuing_salutation && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsHealth?.data?.issuing_salutation}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={`First Name of IMD / Agent / Advisor / Broker`}
                              type="text"
                              errors={errorsHealth?.data}
                              value={formData.hospital_owner_first}
                              name="hospital_owner_first"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={`Middle Name of IMD / Agent / Advisor / Broker`}
                              type="text"
                              errors={errorsHealth?.data}
                              value={formData.hospital_owner_middle}
                              name="hospital_owner_middle"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={`Last Name of IMD / Agent / Advisor / Broker`}
                              type="text"
                              errors={errorsHealth?.data}
                              value={formData.hospital_owner_last}
                              name="hospital_owner_last"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={`Code of IMD / Agent / Advisor / Broker`}
                              type="text"
                              errors={errorsHealth?.data}
                              value={formData.treating_first_name}
                              name="treating_first_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={`Contact No.`}
                              type="text"
                              errors={errorsHealth?.data}
                              value={formData.treating_last_name}
                              name="treating_last_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={`Email ID`}
                              type="text"
                              errors={errorsHealth?.data}
                              value={formData.lab_name}
                              name="lab_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of IMD / Agent / Advisor / Broker </span>
                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Country")}</Form.Label>
                              <Select
                                options={countryList}
                                values={countryList?.filter(country => country?.id === formData?.country_name)}
                                name="country_name"
                                labelField={"name"}
                                valueField={"id"}
                                searchBy={"name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.id, "country_name")
                                }}
                                styles={null}
                              />
                              
                              {errorsHealth?.data?.country_name && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("State / Union Territory")}</Form.Label>
                              <Select
                                options={masterStateLists}
                                values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                name="state_code"
                                labelField={"state_name"}
                                valueField={"state_code"}
                                searchBy={"state_name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.state_code, "state_code")
                                  setStateId(selectedData[0]?.state_code);
                                }}
                                styles={null}
                              />
                              
                              {errorsHealth?.data?.state_code && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("District")}</Form.Label>
                              <Select
                                options={masterCityStateWiseLists}
                                values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                name="city_code"
                                labelField={"city_name"}
                                valueField={"city_code"}
                                searchBy={"city_name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.city_code, "city_code")
                                }}
                                styles={null}
                              />
                              
                              {errorsHealth?.data?.city_code && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Taluka / Sub-District")}
                              type="text"
                              errors={errorsHealth?.data}
                              value={formData.hospital_address}
                              name="hospital_address"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Village / City / Town")}
                              type="text"
                              errors={errorsHealth?.data}
                              value={formData.hospital_address}
                              name="hospital_address"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Flat / House Number / Building Name")}
                              type="text"
                              errors={errorsHealth?.data}
                              value={formData.hospital_address}
                              name="hospital_address"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Street Name / Road Name")}
                              type="text"
                              errors={errorsHealth?.data}
                              value={formData.hospital_address}
                              name="hospital_address"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Area / Locality")}
                              type="text"
                              errors={errorsHealth?.data}
                              value={formData.hospital_address}
                              name="hospital_address"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Landmark")}
                              type="text"
                              errors={errorsHealth?.data}
                              value={formData.hospital_address}
                              name="hospital_address"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Pincode")}
                              type="text"
                              errors={errorsHealth?.data}
                              value={formData.hospital_pincode}
                              name="hospital_pincode"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>
                        </Row>

                        <div className="d-flex justify-content-center">
                          {loading ? 
                            <Button color="primary" disabled>
                              <Spinner animation="border" size="sm" />
                              {t("Please wait ...")}
                            </Button>
                          :
                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                              {t("Submit")}
                            </Button>
                          }
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="accidentalCases">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Incidental Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={`Incidence Date`}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.incidence_date}
                                      name="incidence_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Incidence Time`}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.incidence_time}
                                      name="incidence_time"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Location of Incidence </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={`Date of Death`}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.date_of_death}
                                      name="date_of_death"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Time of Death`}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.time_of_death}
                                      name="time_of_death"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Cause of Death`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.cause_of_death}
                                      name="cause_of_death"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`No of Days Between Incident and Death`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.no_of_days_between_incident_death}
                                      name="no_of_days_between_incident_death"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Whether Death is Due to Accident or Incident`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.due_to_accident_or_incident}
                                      name="due_to_accident_or_incident"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={`Distance of Accident Spot from Insured(s) Residence & Other Relevant Places (Office, Nearest Visit Spot, etc)`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.distance_accident_spot}
                                      name="distance_accident_spot"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={6}>
                                    <FormInput
                                      label={`Brief Description on the Cause of Accident (Preferable One Line)`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.description_cause_of_accident}
                                      name="description_cause_of_accident"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                      style={{ marginTop: "20px" }}
                                    />
                                  </Col>

                                  <Col lg={6}>
                                    <FormInput
                                      label={`Detailed Description on the Circumstances Leading to the Accident`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.description_circumstances_of_accident}
                                      name="description_circumstances_of_accident"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={6}>
                                    <FormInput
                                      label={`Details of Accident Site (Preferably with a Sketch as an Enclosure)`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.details_of_accident_site}
                                      name="details_of_accident_site"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <FormInput
                                      label={`Any Other Factors Contributed to the Accident`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.any_other_factor_to_accident}
                                      name="any_other_factor_to_accident"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Spot Visit
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is Spot Visit Done")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="spot_visit_done"
                                        value="Yes"
                                        checked={formData.spot_visit_done === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="spot_visit_done"
                                        value="No"
                                        checked={formData.spot_visit_done === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.spot_visit_done === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Reason Spot Visit Not Done")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.spot_visit_not_remark}
                                        name="spot_visit_not_remark"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Person Giving Appointment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_giving_appointment}
                                      name="person_giving_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Spot Date of Visit")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.spot_date_visit}
                                      name="spot_date_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Spot Time of Visit")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.spot_time_visit}
                                      name="spot_time_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Location of Visit </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Spot Visit Photo")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.field_visit_photo}
                                      name="field_visit_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={2}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Police Station
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Police Station Where FIR was Done")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.police_station_name}
                                      name="police_station_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("FIR Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.fir_number}
                                      name="fir_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("IPC Section of Crime")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.ipc_section_crime}
                                      name="ipc_section_crime"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("FIR Lodged By")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.fir_lodged_by}
                                      name="fir_lodged_by"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("FIR Lodged Against")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.fir_lodged_against}
                                      name="fir_lodged_against"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of F.I.R ")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.fir_date}
                                      name="fir_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of FIR")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.fir_time}
                                      name="fir_time"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Investigating Officer")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.investigating_officer_name}
                                      name="investigating_officer_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={6}>
                                    <FormInput
                                      label={t("FIR Summary")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.fir_summary}
                                      name="fir_summary"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Any Other Relevant Point During Investigation")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_relevant_point_investigation}
                                      name="other_relevant_point_investigation"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Police Charge Sheet")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.file_charge_sheet}
                                      name="file_charge_sheet"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Final Report Details")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.final_report_details}
                                      name="final_report_details"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Spot Panchnama")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.spot_panchnama}
                                      name="spot_panchnama"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={3}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                MLC
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={3}>
                              <Card.Body>
                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Police Station </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Police Officer Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.police_officer_name}
                                      name="police_officer_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Designation")}</Form.Label>
                                      <Select
                                        options={masterDesignationList}
                                        values={masterDesignationList?.filter(state => state.id === formData.mlc_designation)}
                                        name="mlc_designation"
                                        placeholder={t("Select Designation")}
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "mlc_designation")
                                        }}
                                      />
                                      
                                      {errorsHealth?.data?.mlc_designation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.mlc_designation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Bukkal No. / Badge No.")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.bukkal_badge_number}
                                      name="bukkal_badge_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`MLC Contact No.`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.mlc_contact_number}
                                      name="mlc_contact_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("MLC Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.mlc_number}
                                      name="mlc_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of MLC")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.mlc_date}
                                      name="mlc_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of MLC")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.mlc_time}
                                      name="mlc_time"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Is Alcohol found in MLC")}
                                      name="alcohol_found_mlc"
                                      value="Yes"
                                      checked={formData.alcohol_found_mlc}
                                      onChange={(e) => inputHandler({ target: { name: "alcohol_found_mlc", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>
                                </Row>

                                <Row style={{ marginTop: "3%" }}>
                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Whether Police Authority Visited Hospital After Receiveing MLC")}
                                      name="police_authority_receiveing_mlc"
                                      value="Yes"
                                      checked={formData.police_authority_receiveing_mlc}
                                      onChange={(e) => inputHandler({ target: { name: "police_authority_receiveing_mlc", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>
                                </Row>

                                <Row style={{ marginTop: "3%" }}>
                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Patient Statement / Jabab / Dying Declaration")}
                                      name="patient_jabab_dying_declaration"
                                      value="Yes"
                                      checked={formData.patient_jabab_dying_declaration}
                                      onChange={(e) => inputHandler({ target: { name: "patient_jabab_dying_declaration", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>
                                </Row>

                                <Row style={{ marginTop: "3%" }}>
                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("MLC Converted to FIR After MLC")}
                                      name="converted_fir_after_mlc"
                                      value="Yes"
                                      checked={formData.converted_fir_after_mlc}
                                      onChange={(e) => inputHandler({ target: { name: "converted_fir_after_mlc", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>
                                </Row>

                                <Row style={{ marginTop: "3%" }}>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Vehicle Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.vehicle_number}
                                      name="vehicle_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                      style={{ marginTop: "20px" }}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Owner of the Vehicle")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.owner_of_the_vehicle}
                                      name="owner_of_the_vehicle"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                      style={{ marginTop: "20px" }}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Who was Driving the Vehicle at Time of Accident?")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.driving_vehicle_time_accident}
                                      name="driving_vehicle_time_accident"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Driver (At Time of Accident) ")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.driver_name_time_of_accident}
                                      name="driver_name_time_of_accident"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Number of Occupants in the Vehicle")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.number_occupants_vehicle}
                                      name="number_occupants_vehicle"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.occupants_name}
                                      name="occupants_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Contact No.")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.occupants_contact_number}
                                      name="occupants_contact_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={4}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Death Certificate
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={4}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Death")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.date_of_death}
                                      name="date_of_death"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Registration Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.registration_number}
                                      name="registration_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Registered At")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.registered_at}
                                      name="registered_at"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Online Verification Details")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.online_verification_details}
                                      name="online_verification_details"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Issuing Office Authority")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.death_issuing_authority}
                                      name="death_issuing_authority"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Issue date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.issue_date}
                                      name="issue_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Is RTI ﬁled for DC cancellation?")}
                                      name="is_rti_filed_dc_cancellation"
                                      value="Yes"
                                      checked={formData.is_rti_filed_dc_cancellation}
                                      onChange={(e) => inputHandler({ target: { name: "is_rti_filed_dc_cancellation", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={5}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Cremation Certificate Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={5}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Certificate Issued by")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.certificate_issued_by}
                                      name="certificate_issued_by"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Cremation")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.date_of_cremation}
                                      name="date_of_cremation"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Cremation")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.time_of_cremation}
                                      name="time_of_cremation"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Location of Cremation </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Cremation Ground Visit Findings")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.cremation_ground_visits_findings}
                                      name="cremation_ground_visits_findings"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={6}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Check With Village Authorites
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={6}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Sarpanch")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.sarpanch_name}
                                      name="sarpanch_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Anganwadi Teacher")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.anganwadi_teacher}
                                      name="anganwadi_teacher"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("ASHW Worker / ANM")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.ashw_worker}
                                      name="ashw_worker"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Primary Health Center PHC")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.primary_health_center}
                                      name="primary_health_center"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <label>{t("Bank Account of Insured is Active or Not After Date of Death")}</label>
                                    <div style={{marginTop: '2%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="bank_account_insured_active"
                                        value="Yes"
                                        checked={formData.bank_account_insured_active === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="bank_account_insured_active"
                                        value="No"
                                        checked={formData.bank_account_insured_active === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.bank_account_insured_active === "No" && (
                                    <Col lg={6} style={{ marginTop: "2%" }}>
                                      <FormInput
                                        label={t("Reason for Not Deactivating the Insure Bank Account")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_deactivating_insure_account}
                                        name="reason_deactivating_insure_account"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane eventKey="familyDoctor">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Basic Details of Family Doctor
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Family Doctor Salutation`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.family_doctor_salutation)}
                                        name="family_doctor_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "family_doctor_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.family_doctor_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.family_doctor_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Family Doctor First Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.family_doctor_first_name}
                                      name="family_doctor_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Family Doctor Middle Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.family_doctor_middle_name}
                                      name="family_doctor_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Family Doctor Last Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.family_doctor_last_name}
                                      name="family_doctor_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t(`Family Doctor Gender`)}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Male")}
                                        name="family_doctor_gender"
                                        value="Male"
                                        checked={formData.family_doctor_gender === "Male"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Female")}
                                        name="family_doctor_gender"
                                        value="Female"
                                        checked={formData.family_doctor_gender === "Female"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Others")}
                                        name="family_doctor_gender"
                                        value="Others"
                                        checked={formData.family_doctor_gender === "Others"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Family Doctor Qualification </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Family Doctor Degree`)}</Form.Label>
                                      <Select
                                        options={degreeList}
                                        values={degreeList?.filter(gree => gree?.id === formData?.doctor_degree)}
                                        name="doctor_degree"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          const degreeType = degreeTypeMapping[selectedData[0]?.id];
                                          selectHandler2(selectedData[0]?.id, "doctor_degree");
                                          selectHandler2(degreeType, "degree_type");
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.doctor_degree && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.doctor_degree}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Family Doctor Degree Type`)}</Form.Label>
                                      <Select
                                        options={degreeTypeList}
                                        values={degreeTypeList?.filter(degr => degr?.id === formData?.degree_type)}
                                        name="degree_type"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "degree_type")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.degree_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.degree_type}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Family Doctor Contact No`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_number}
                                      name="hospital_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Treating Doctor Email ID`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_email}
                                      name="doctor_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Family Doctor Live Photo`)}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_photo}
                                      name="doctor_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Family Doctor Handwritting Sample`)}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_handwritting}
                                      name="doctor_handwritting"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Registration Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Doctor Registration No.")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_owner}
                                      name="hospital_owner"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Registration Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formattedRenewalDate}
                                      name="renewal_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Valid Upto Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formattedExpiryDate}
                                      name="expiry_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Doctor Registration Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Valid")}
                                        name="registration_status"
                                        value="Valid"
                                        checked={formData.registration_status === "Valid"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Expired")}
                                        name="registration_status"
                                        value="Expired"
                                        checked={formData.registration_status === "Expired"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData.registration_status === "Expired" && (
                                    <>
                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Doctor Expiry Date")}
                                          type="date"
                                          errors={errorsHealth?.data}
                                          value={formattedExpiryDate}
                                          name="expiry_date"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Doctor Renewal Date")}
                                          type="date"
                                          errors={errorsHealth?.data}
                                          value={formattedRenewalDate}
                                          name="renewal_date"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Issuing Office Authority")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.issuing_authority}
                                      name="issuing_authority"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <div
                                      style={{ position: "relative" }}
                                      onClick={() => window.open(formData?.rohini_id_link || "https://rtionline.gov.in/", "_blank")}
                                    >
                                      <FormInput
                                        label={t("Online RTI Link")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData?.rohini_id_link || "https://rtionline.gov.in/"}
                                        name="rohini_id_link"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                        readOnly
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Registration Certificate Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formattedExpiryDate}
                                      name="corticate_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey={1-1}
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Family Doctor Social Media
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={1-1}>
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Whatsapp")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Instagram Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_instagram_link}
                                              name="hospital_instagram_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Facebook Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_facebook_link}
                                              name="hospital_facebook_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Linkdin Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_linkdin_link}
                                              name="hospital_linkdin_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Twitter Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_twitter_link}
                                              name="hospital_twitter_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Truecaller Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_truecaller_link}
                                              name="hospital_truecaller_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Justdial Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_justdial_link}
                                              name="hospital_justdial_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Eyecon Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Other Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={2}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Family Doctor Related Data Search
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Data Related Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.data_related_policy}
                                      name="data_related_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={3}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Alert
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={3}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator Username")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_username}
                                      name="alert_creator_username"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_id}
                                      name="alert_creator_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Date & Time of Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.date_time_alert}
                                      name="date_time_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Comment on Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.comment_alert}
                                      name="comment_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={4}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Blacklisted
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={4}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <label>{t("Backlisted Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="backlisted_status"
                                        value="Yes"
                                        checked={formData.backlisted_status === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="backlisted_status"
                                        value="No"
                                        checked={formData.backlisted_status === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Data Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.blacklisted_insurance_data}
                                      name="blacklisted_insurance_data"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={5}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grievance Management
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={5}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Grievance and Escalation")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Grievance")}
                                        name="grievance_status"
                                        value="Grievance"
                                        checked={formData.grievance_status === "Grievance"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Escalation")}
                                        name="grievance_status"
                                        value="Escalation"
                                        checked={formData.grievance_status === "Escalation"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <br />
                                {formData.grievance_status && (<>
                                  <Row>
                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Type of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.type_of_grievance}
                                        name="type_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Date of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.date_of_grievance}
                                        name="date_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Notification of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.notification_of_grievance}
                                        name="notification_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Reason of the ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_of_grievance}
                                        name="reason_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Status of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.status_of_grievance}
                                        name="status_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Final Conclusion of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.final_of_grievance}
                                        name="final_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  </Row>
                                </>)}
                                
                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={6}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grading
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={6}>
                              <Card.Body>
                                
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={7}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Family Doctor Visit Finding
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={7}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is Family Doctor Visit Done")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="family_doctor_visit_done"
                                        value="Yes"
                                        checked={formData.family_doctor_visit_done === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="family_doctor_visit_done"
                                        value="No"
                                        checked={formData.family_doctor_visit_done === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.family_doctor_visit_done === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Reason Corporate Visit Not Done")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.family_doctor_visit_not_remark}
                                        name="family_doctor_visit_not_remark"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Family Doctor Appointment")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.corporate_date_appointment}
                                      name="corporate_date_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Family Doctor Appointment")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.corporate_time_appointment}
                                      name="corporate_time_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Person Giving Appointment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_giving_appointment}
                                      name="person_giving_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Family Doctor Visit")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.corporate_date_visit}
                                      name="corporate_date_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Family Doctor Visit")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.corporate_time_visit}
                                      name="corporate_time_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Location of Visit </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={6}>
                                    <label>{t("Family Doctor Provide Any Past Medical History During the Visit?")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="family_doctor_past_medical"
                                        value="Yes"
                                        checked={formData.family_doctor_past_medical === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="family_doctor_past_medical"
                                        value="No"
                                        checked={formData.family_doctor_past_medical === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.family_doctor_past_medical === "No" && (
                                    <Col lg={6}>
                                      <FormInput
                                        label={t("Remarks Specify the Details of the Past Medical History")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.family_doctor_visit_not_remark}
                                        name="family_doctor_visit_not_remark"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={6}>
                                    <label>{t("Did the Family Doctor Confirm the Presence of Ailment?")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="family_doctor_confirm_presence"
                                        value="Yes"
                                        checked={formData.family_doctor_confirm_presence === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="family_doctor_confirm_presence"
                                        value="No"
                                        checked={formData.family_doctor_confirm_presence === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.family_doctor_confirm_presence === "No" && (
                                    <Col lg={6}>
                                      <FormInput
                                        label={t("General Remarks of Family Doctor Verification")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.family_doctor_verification_general_remarks}
                                        name="family_doctor_verification_general_remarks"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane eventKey="hospitalData">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Hospital Basic Info
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Hospital Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_name}
                                      name="hospital_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Hospital (As Per Document)</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Latitude")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.hospital_lat}
                                      name="hospital_lat"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Longitude")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.hospital_lng}
                                      name="hospital_lng"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Hospital (As Per Field)</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country (As Per Field)")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory (As Per Field)")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District (As Per Field)")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>
                                
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Help Desk")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Available")}
                                        name="helpdesk"
                                        value="Available"
                                        checked={formData.helpdesk === "Available"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Not Available")}
                                        name="helpdesk"
                                        value="Not Available"
                                        checked={formData.helpdesk === "Not Available"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Hospital Category")}</Form.Label>
                                      <Select
                                        options={categoryList}
                                        values={categoryList?.filter(category => category?.id === formData?.hospital_category)}
                                        name="hospital_category"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "hospital_category")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.hospital_category && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.hospital_category}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Hospital Contact No 1")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_number}
                                      name="hospital_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Hospital Email ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_email}
                                      name="hospital_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={2} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey="0-1"
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        T.P.A Details
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0-1">
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{t("T.P.A Cordinator Salutation")}</Form.Label>
                                              <Select
                                                options={salutationList}
                                                values={salutationList?.filter(salutation => salutation?.id === formData?.tpa_salutation)}
                                                name="tpa_salutation"
                                                labelField={"name"}
                                                valueField={"id"}
                                                searchBy={"name"}
                                                loading={loadingMaster}
                                                onChange={(selectedData) => {
                                                  selectHandler2(selectedData[0]?.id, "tpa_salutation")
                                                }}
                                                styles={null}
                                              />
                                              
                                              {errorsHealth?.data?.tpa_salutation && (
                                                <div className="invalid-feedback d-block">
                                                  <p className="text-danger">{errorsHealth?.data?.tpa_salutation}</p>
                                                </div>
                                              )}
                                            </Form.Group>
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("T.P.A Cordinator First Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.tpa_first_name}
                                              name="tpa_first_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("T.P.A Cordinator Middle Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.tpa_middle_name}
                                              name="tpa_middle_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("T.P.A Cordinator Last Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.tpa_last_name}
                                              name="tpa_last_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("T.P.A Cordinator Contact No")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.tpa_number}
                                              name="tpa_number"
                                              onChange={selectMobileHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={2} style={{marginTop: '28px'}}>
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Save")}
                                            </Button>
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("T.P.A Cordinator Email ID")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.tpa_email}
                                              name="tpa_email"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4} style={{marginTop: '28px'}}>
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Save")}
                                            </Button>
                                          </Col>
                                        </Row>

                                        <div className="d-flex justify-content-center">
                                          {loading ? 
                                            <Button color="primary" disabled>
                                              <Spinner animation="border" size="sm" />
                                              {t("Please wait ...")}
                                            </Button>
                                          :
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Submit")}
                                            </Button>
                                          }
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey="0-2"
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        R.M.O Details
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0-2">
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{t("R.M.O Salutation")}</Form.Label>
                                              <Select
                                                options={salutationList}
                                                values={salutationList?.filter(salutation => salutation?.id === formData?.rmo_salutation)}
                                                name="rmo_salutation"
                                                labelField={"name"}
                                                valueField={"id"}
                                                searchBy={"name"}
                                                loading={loadingMaster}
                                                onChange={(selectedData) => {
                                                  selectHandler2(selectedData[0]?.id, "rmo_salutation")
                                                }}
                                                styles={null}
                                              />
                                              
                                              {errorsHealth?.data?.rmo_salutation && (
                                                <div className="invalid-feedback d-block">
                                                  <p className="text-danger">{errorsHealth?.data?.rmo_salutation}</p>
                                                </div>
                                              )}
                                            </Form.Group>
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("R.M.O First Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.rmo_first_name}
                                              name="rmo_first_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("R.M.O Middle Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.rmo_middle_name}
                                              name="rmo_middle_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("R.M.O Last Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.rmo_last_name}
                                              name="rmo_last_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("R.M.O Contact No")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.rmo_number}
                                              name="rmo_number"
                                              onChange={selectMobileHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4} style={{marginTop: '28px'}}>
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Save")}
                                            </Button>
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("R.M.O Email ID")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.rmo_email}
                                              name="rmo_email"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4} style={{marginTop: '28px'}}>
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Save")}
                                            </Button>
                                          </Col>
                                        </Row>

                                        <div className="d-flex justify-content-center">
                                          {loading ? 
                                            <Button color="primary" disabled>
                                              <Spinner animation="border" size="sm" />
                                              {t("Please wait ...")}
                                            </Button>
                                          :
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Submit")}
                                            </Button>
                                          }
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey="0-3"
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Nursing Staff Details
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0-3">
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{t("Nursing Staff Salutation")}</Form.Label>
                                              <Select
                                                options={salutationList}
                                                values={salutationList?.filter(salutation => salutation?.id === formData?.nursing_salutation)}
                                                name="nursing_salutation"
                                                labelField={"name"}
                                                valueField={"id"}
                                                searchBy={"name"}
                                                loading={loadingMaster}
                                                onChange={(selectedData) => {
                                                  selectHandler2(selectedData[0]?.id, "nursing_salutation")
                                                }}
                                                styles={null}
                                              />
                                              
                                              {errorsHealth?.data?.nursing_salutation && (
                                                <div className="invalid-feedback d-block">
                                                  <p className="text-danger">{errorsHealth?.data?.nursing_salutation}</p>
                                                </div>
                                              )}
                                            </Form.Group>
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Nursing Staff First Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.rmo_first_name}
                                              name="rmo_first_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Nursing Staff Middle Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.rmo_middle_name}
                                              name="rmo_middle_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Nursing Staff Last Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.rmo_last_name}
                                              name="rmo_last_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Nursing Staff Contact No")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.staff_number}
                                              name="staff_number"
                                              onChange={selectMobileHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4} style={{marginTop: '28px'}}>
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Save")}
                                            </Button>
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Nursing Staff Email ID")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.staff_email}
                                              name="staff_email"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4} style={{marginTop: '28px'}}>
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Save")}
                                            </Button>
                                          </Col>
                                        </Row>

                                        <div className="d-flex justify-content-center">
                                          {loading ? 
                                            <Button color="primary" disabled>
                                              <Spinner animation="border" size="sm" />
                                              {t("Please wait ...")}
                                            </Button>
                                          :
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Submit")}
                                            </Button>
                                          }
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Hospital Owner Data
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Type of Owner")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Owner")}
                                        name="type_of_owner"
                                        value="Owner"
                                        checked={formData.type_of_owner === "Owner"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Management")}
                                        name="type_of_owner"
                                        value="Management"
                                        checked={formData.type_of_owner === "Management"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <br />
                                {formData.type_of_owner && (<>
                                  <Row>
                                    {formData.type_of_owner === "Management" && (
                                      <>
                                        <Col lg={4}>
                                          <Form.Group className="mb-3">
                                            <Form.Label>{t("Management Designation")}</Form.Label>
                                            <Select
                                              options={hospitalManagementList}
                                              values={hospitalManagementList?.filter(detail => detail?.id === formData?.hospital_management)}
                                              name="hospital_management"
                                              labelField={"name"}
                                              valueField={"id"}
                                              searchBy={"name"}
                                              loading={false}
                                              onChange={(selectedData) => {
                                                selectHandler2(selectedData[0]?.id, "hospital_management")
                                              }}
                                              styles={null}
                                            />
                                            
                                            {errorsHealth?.data?.hospital_management && (
                                              <div className="invalid-feedback d-block">
                                                <p className="text-danger">{errorsHealth?.data?.hospital_management}</p>
                                              </div>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </>
                                    )}

                                    <Col lg={4}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>{`${formData.type_of_owner} Salutation`}</Form.Label>
                                        <Select
                                          options={salutationList}
                                          values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                                          name="issuing_salutation"
                                          labelField={"name"}
                                          valueField={"id"}
                                          searchBy={"name"}
                                          loading={loadingMaster}
                                          onChange={(selectedData) => {
                                            selectHandler2(selectedData[0]?.id, "issuing_salutation")
                                          }}
                                          styles={null}
                                        />
                                        
                                        {errorsHealth?.data?.issuing_salutation && (
                                          <div className="invalid-feedback d-block">
                                            <p className="text-danger">{errorsHealth?.data?.issuing_salutation}</p>
                                          </div>
                                        )}
                                      </Form.Group>
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={`${formData.type_of_owner} First Name`}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.hospital_owner_first}
                                        name="hospital_owner_first"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={`${formData.type_of_owner} Middle Name`}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.hospital_owner_middle}
                                        name="hospital_owner_middle"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={`${formData.type_of_owner} Last Name`}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.hospital_owner_last}
                                        name="hospital_owner_last"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>{t(`${formData.type_of_owner} Degree`)}</Form.Label>
                                        <Select
                                          options={degreeList}
                                          values={degreeList?.filter(gree => gree?.id === formData?.hospital_owner_degree)}
                                          name="hospital_owner_degree"
                                          labelField={"name"}
                                          valueField={"id"}
                                          searchBy={"name"}
                                          loading={false}
                                          onChange={(selectedData) => {
                                            const selectedDegreeId = selectedData[0]?.id;
                                            const degreeType = degreeTypeMapping[selectedDegreeId];
                                            selectHandler2(selectedDegreeId, "hospital_owner_degree");
                                            selectHandler2(degreeType, "degree_type");
                                          }}
                                          styles={null}
                                        />
                                        
                                        {errorsHealth?.data?.hospital_owner_degree && (
                                          <div className="invalid-feedback d-block">
                                            <p className="text-danger">{errorsHealth?.data?.hospital_owner_degree}</p>
                                          </div>
                                        )}
                                      </Form.Group>
                                    </Col>

                                    <Col lg={4}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>{t(`${formData.type_of_owner} Degree Type`)}</Form.Label>
                                        <Select
                                          options={degreeTypeList}
                                          values={degreeTypeList?.filter(degr => degr?.id === formData?.degree_type)}
                                          name="degree_type"
                                          labelField={"name"}
                                          valueField={"id"}
                                          searchBy={"name"}
                                          loading={false}
                                          onChange={(selectedData) => {
                                            selectHandler2(selectedData[0]?.id, "degree_type")
                                          }}
                                          styles={null}
                                        />
                                        
                                        {errorsHealth?.data?.degree_type && (
                                          <div className="invalid-feedback d-block">
                                            <p className="text-danger">{errorsHealth?.data?.degree_type}</p>
                                          </div>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg={4}>
                                      <FormInput
                                        label={`${formData.type_of_owner} Contact No.`}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.management_contact}
                                        name="management_contact"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4} style={{marginTop: '28px'}}>
                                      <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                        {t("Save")}
                                      </Button>
                                    </Col>

                                    <Col lg={4}></Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={`${formData.type_of_owner} Email ID`}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.management_email}
                                        name="management_email"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={2} style={{marginTop: '28px'}}>
                                      <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                        {t("Save")}
                                      </Button>
                                    </Col>
                                  </Row>

                                  {formData.type_of_owner === "Owner" && (
                                    <Row>
                                      <Col lg={4}>
                                        <FormInput
                                          label={`Registration Certificate`}
                                          type="file"
                                          errors={errorsHealth?.data}
                                          value={formData.registration_certificate_file}
                                          name="registration_certificate_file"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </Row>
                                  )}

                                  <div className="d-flex justify-content-center">
                                    {loading ? 
                                      <Button color="primary" disabled>
                                        <Spinner animation="border" size="sm" />
                                        {t("Please wait ...")}
                                      </Button>
                                    :
                                      <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                        {t("Submit")}
                                      </Button>
                                    }
                                  </div>
                                </>)}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={3}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Hospital Account Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={3}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Hospital GST Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.gst_number}
                                      name="gst_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Hospital GST Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.gst_number_upload}
                                      name="gst_number_upload"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Hospital PAN Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.pan_number}
                                      name="pan_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Hospital PAN Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData?.pan_number_upload}
                                      name="pan_number_upload"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Other Document Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.other_document_number}
                                      name="other_document_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Other Document Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData?.other_document_upload}
                                      name="other_document_upload"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={4}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Registration Details Form C
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={4}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Registration Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.gst_number}
                                      name="gst_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Registration Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formattedRenewalDate}
                                      name="renewal_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Valid Upto Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formattedExpiryDate}
                                      name="expiry_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Registration Status")}</label>
                                    <div style={{ marginTop: '5%' }}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Valid")}
                                        name="registration_status"
                                        value="Valid"
                                        checked={formData.registration_status === "Valid"}
                                        onChange={(e) => {
                                          inputHandler(e);
                                        }}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("Expired")}
                                        name="registration_status"
                                        value="Expired"
                                        checked={formData.registration_status === "Expired"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData.registration_status === "Expired" && (<>
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Expired Date")}
                                        type="date"
                                        errors={errorsHealth?.data}
                                        value={formattedExpiryDate}
                                        name="renewal_date"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Renewal Date")}
                                        type="date"
                                        errors={errorsHealth?.data}
                                        value={formattedRenewalDate}
                                        name="expiry_date"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  </>)}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Certificate Issuing Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formattedCertificateDate}
                                      name="corticate_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Issuing Office Authority")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.issuing_authority}
                                      name="issuing_authority"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <div
                                      style={{ position: "relative" }}
                                      onClick={() => window.open(formData?.rohini_id_link || "https://rtionline.gov.in/", "_blank")}
                                    >
                                      <FormInput
                                        label={t("Online RTI Link")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData?.rohini_id_link || "https://rtionline.gov.in/"}
                                        name="rohini_id_link"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                        readOnly
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Hospital Registration Certificate")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.issuing_first_name}
                                      name="issuing_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey={4-1}
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Issuing Officer Details
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={4-1}>
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{t("Issuing Officer Salutation")}</Form.Label>
                                              <Select
                                                options={salutationList}
                                                values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                                                name="issuing_salutation"
                                                labelField={"name"}
                                                valueField={"id"}
                                                searchBy={"name"}
                                                loading={loadingMaster}
                                                onChange={(selectedData) => {
                                                  selectHandler2(selectedData[0]?.id, "issuing_salutation")
                                                }}
                                                styles={null}
                                              />
                                              
                                              {errorsHealth?.data?.issuing_salutation && (
                                                <div className="invalid-feedback d-block">
                                                  <p className="text-danger">{errorsHealth?.data?.issuing_salutation}</p>
                                                </div>
                                              )}
                                            </Form.Group>
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Issuing Officer First Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.issuing_first_name}
                                              name="issuing_first_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Issuing Officer Middle Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.issuing_middle_name}
                                              name="issuing_middle_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Issuing Officer Last Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.issuing_last_name}
                                              name="issuing_last_name"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Issuing Officer Contact Number")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData?.issuing_officer_number}
                                              name="issuing_officer_number"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={3} style={{marginTop: '28px'}}>
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Save")}
                                            </Button>
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Issuing Officer Email ID")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.issuing_officer_email}
                                              name="issuing_officer_email"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4} style={{marginTop: '28px'}}>
                                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                              {t("Save")}
                                            </Button>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Issuing Officer</span>
                                          <Col lg={4}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{t("Country")}</Form.Label>
                                              <Select
                                                options={countryList}
                                                values={countryList?.filter(country => country?.id === formData?.country_name)}
                                                name="country_name"
                                                labelField={"name"}
                                                valueField={"id"}
                                                searchBy={"name"}
                                                loading={loadingMaster}
                                                onChange={(selectedData) => {
                                                  selectHandler2(selectedData[0]?.id, "country_name")
                                                }}
                                                styles={null}
                                              />
                                              
                                              {errorsHealth?.data?.country_name && (
                                                <div className="invalid-feedback d-block">
                                                  <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                                </div>
                                              )}
                                            </Form.Group>
                                          </Col>

                                          <Col lg={4}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{t("State / Union Territory")}</Form.Label>
                                              <Select
                                                options={masterStateLists}
                                                values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                                name="state_code"
                                                labelField={"state_name"}
                                                valueField={"state_code"}
                                                searchBy={"state_name"}
                                                loading={loadingMaster}
                                                onChange={(selectedData) => {
                                                  selectHandler2(selectedData[0]?.state_code, "state_code")
                                                  setStateId(selectedData[0]?.state_code);
                                                }}
                                                styles={null}
                                              />
                                              
                                              {errorsHealth?.data?.state_code && (
                                                <div className="invalid-feedback d-block">
                                                  <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                                </div>
                                              )}
                                            </Form.Group>
                                          </Col>

                                          <Col lg={4}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{t("District")}</Form.Label>
                                              <Select
                                                options={masterCityStateWiseLists}
                                                values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                                name="city_code"
                                                labelField={"city_name"}
                                                valueField={"city_code"}
                                                searchBy={"city_name"}
                                                loading={loadingMaster}
                                                onChange={(selectedData) => {
                                                  selectHandler2(selectedData[0]?.city_code, "city_code")
                                                }}
                                                styles={null}
                                              />
                                              
                                              {errorsHealth?.data?.city_code && (
                                                <div className="invalid-feedback d-block">
                                                  <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                                </div>
                                              )}
                                            </Form.Group>
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Taluka / Sub-District")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_address}
                                              name="hospital_address"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Village / City / Town")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_address}
                                              name="hospital_address"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Flat / House Number / Building Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_address}
                                              name="hospital_address"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Street Name / Road Name")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_address}
                                              name="hospital_address"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Area / Locality")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_address}
                                              name="hospital_address"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Landmark")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_address}
                                              name="hospital_address"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Pincode")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_pincode}
                                              name="hospital_pincode"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={5}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Cashless Status Insurance Company Name
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={5}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <label>{t("Cashless Facility Available")}</label>
                                    <div style={{ marginTop: '5%' }}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="facility_available"
                                        value="Yes"
                                        checked={formData.facility_available === "Yes"}
                                        onChange={(e) => {
                                          inputHandler(e);
                                        }}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="facility_available"
                                        value="No"
                                        checked={formData.facility_available === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData.facility_available === "Yes" && (<>
                                    <Col lg={4}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>{t("Insurance Company")}</Form.Label>
                                        <Select
                                          options={masterInsuranceList}
                                          values={masterInsuranceList?.filter(insurance => insurance?.id === formData?.insurance_company)}
                                          name="insurance_company"
                                          labelField={"name"}
                                          valueField={"id"}
                                          searchBy={"name"}
                                          loading={loadingMaster}
                                          multi={true}
                                          onChange={(selectedData) => {
                                            selectHandler2(selectedData[0]?.id, "insurance_company")
                                          }}
                                          styles={null}
                                        />
                                        
                                        {errorsHealth?.data?.insurance_company && (
                                          <div className="invalid-feedback d-block">
                                            <p className="text-danger">{errorsHealth?.data?.insurance_company}</p>
                                          </div>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </>)}
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={6}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Rohini Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={6}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Rohini ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.rohini_id}
                                      name="rohini_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <div
                                      style={{ position: "relative" }}
                                      onClick={() => window.open(formData?.rohini_id_link || "https://rohini.iib.gov.in/HosExistings?status=p&search=Search", "_blank")}
                                    >
                                      <FormInput
                                        label={t("Rohini ID Link")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData?.rohini_id_link || "https://rohini.iib.gov.in/HosExistings?status=p&search=Search"}
                                        name="rohini_id_link"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                        readOnly
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Rohini ID Link Status")}</label>
                                    <div style={{ marginTop: '5%' }}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Found")}
                                        name="link_status"
                                        value="Found"
                                        checked={formData.link_status === "Found"}
                                        onChange={(e) => {
                                          inputHandler(e);
                                        }}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("Not Found")}
                                        name="link_status"
                                        value="Not Found"
                                        checked={formData.link_status === "Not Found"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Upload Rohini Search")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.google_map_link}
                                      name="google_map_link"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Google Map Link")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.google_map_link}
                                      name="google_map_link"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={7}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Hospital Profile
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={7}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Online Hospital Photo Link")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_photo_link}
                                      name="hospital_photo_link"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Online Field Photo")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData?.field_photo}
                                      name="field_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Staff Adequently")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.staff_adequently}
                                      name="staff_adequently"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Rooms Category")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.room_category)}
                                        name="room_category"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "room_category")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.room_category && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.room_category}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("No. Of Beds (As Per Form C)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.no_of_beds}
                                      name="no_of_beds"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("No. Of Beds (As Per Field Visit)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.no_of_beds}
                                      name="no_of_beds"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Operation Theatre")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="operation_theatre"
                                        value="Yes"
                                        checked={formData.operation_theatre === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="operation_theatre"
                                        value="No"
                                        checked={formData.operation_theatre === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Overall Infrastructure")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.overall_infrastructure}
                                      name="overall_infrastructure"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Tariff Display")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="tariff_display"
                                        value="Yes"
                                        checked={formData.tariff_display === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="tariff_display"
                                        value="No"
                                        checked={formData.tariff_display === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData.tariff_display === "Yes" && (
                                    <>
                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Tariff Update Date")}
                                          type="date"
                                          errors={errorsHealth?.data}
                                          value={formData?.field_photo}
                                          name="field_photo"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <Form.Group className="mb-3">
                                          <Form.Label>{t("Rooms Category")}</Form.Label>
                                          <Select
                                            options={countryList}
                                            values={countryList?.filter(country => country?.id === formData?.room_category)}
                                            name="room_category"
                                            labelField={"name"}
                                            valueField={"id"}
                                            searchBy={"name"}
                                            loading={loadingMaster}
                                            onChange={(selectedData) => {
                                              selectHandler2(selectedData[0]?.id, "room_category")
                                            }}
                                            styles={null}
                                          />
                                          
                                          {errorsHealth?.data?.room_category && (
                                            <div className="invalid-feedback d-block">
                                              <p className="text-danger">{errorsHealth?.data?.room_category}</p>
                                            </div>
                                          )}
                                        </Form.Group>
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Room Charges")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData?.field_photo}
                                          name="field_photo"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Treating Doctor Charges")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData?.field_photo}
                                          name="field_photo"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("RMO Charges")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData?.field_photo}
                                          name="field_photo"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Nursing Charges")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData?.field_photo}
                                          name="field_photo"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Tariff Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData?.field_photo}
                                      name="field_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey={7-1}
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Hospital Social Media
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={7-1}>
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Professional Website")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.professional_website}
                                              name="professional_website"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Whatsapp")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Instagram Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_instagram_link}
                                              name="hospital_instagram_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Facebook Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_facebook_link}
                                              name="hospital_facebook_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Linkdin Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_linkdin_link}
                                              name="hospital_linkdin_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Twitter Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_twitter_link}
                                              name="hospital_twitter_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Eyecon Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Truecaller Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_truecaller_link}
                                              name="hospital_truecaller_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Other Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_other_link}
                                              name="hospital_other_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={8}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Hospitalization Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={8}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Admission")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.date_of_admission}
                                      name="date_of_admission"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Admission")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.time_of_admission}
                                      name="time_of_admission"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Discharge")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.date_of_discharge}
                                      name="date_of_discharge"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Discharge")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.time_of_discharge}
                                      name="time_of_discharge"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Expected Date of Admission")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.expected_date_of_admission}
                                      name="expected_date_of_admission"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Expected Time of Admission")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.expected_time_of_admission}
                                      name="expected_time_of_admission"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Expected Date of Discharge")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.expected_date_of_discharge}
                                      name="expected_date_of_discharge"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Expected Time of Discharge")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.expected_time_of_discharge}
                                      name="expected_time_of_discharge"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Actual Time of Admission")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.actual_time_of_admission}
                                      name="actual_time_of_admission"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Actual Time of Discharge")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.actual_time_of_discharge}
                                      name="actual_time_of_discharge"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Actual Number of Days for Hospitalization")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.actual_number_days_hospitalization}
                                      name="actual_number_days_hospitalization"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Actual Hours of Hospitalization")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.actual_hours_hospitalization}
                                      name="actual_hours_hospitalization"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Estimated Number of Days for Hospitalization")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.estimated_number_days_hospitalization}
                                      name="estimated_number_days_hospitalization"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of First Consultation")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.date_first_consultation}
                                      name="date_first_consultation"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Complaint")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.date_of_complaint}
                                      name="date_of_complaint"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Provisional Diagnosis")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.provisional_diagnosis}
                                      name="provisional_diagnosis"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Diagnosis")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.diagnosis_description}
                                      name="diagnosis_description"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("ICD Details")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.icd_details}
                                      name="icd_details"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("ICD Description")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.icd_description}
                                      name="icd_description"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("CPT Details")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.cpt_details}
                                      name="cpt_details"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("CPT Code")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.cpt_code}
                                      name="cpt_code"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("CPT Description")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.cpt_description}
                                      name="cpt_description"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Proposed Line of Treatment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.proposed_line_treatment}
                                      name="proposed_line_treatment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Line of Treatment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.line_treatment}
                                      name="line_treatment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Surgery")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.name_of_surgery}
                                      name="name_of_surgery"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Total Bill Amount")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.total_bill_amount}
                                      name="total_bill_amount"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Payment Mode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.payment_mode}
                                      name="payment_mode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={9}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                MLC / FIR Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={9}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4} style={{ marginTop: "3%" }}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Is Medico Legal Done")}
                                      name="medico_legal_done"
                                      value="Yes"
                                      checked={formData.medico_legal_done}
                                      onChange={(e) => inputHandler({ target: { name: "medico_legal_done", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>

                                  {formData?.medico_legal_done === true  && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Reason MLC Not Done In Accidental Cases")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_mlc_not_done_accidental_cases}
                                        name="reason_mlc_not_done_accidental_cases"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4} style={{ marginTop: "3%" }}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Reported to Police")}
                                      name="reported_to_police"
                                      value="Yes"
                                      checked={formData.reported_to_police}
                                      onChange={(e) => inputHandler({ target: { name: "reported_to_police", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>

                                  {formData?.reported_to_police === true  && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Reason If Not Reported to Police")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_if_not_reported_to_police}
                                        name="reason_if_not_reported_to_police"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Police Station Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.police_station_name}
                                      name="police_station_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Police Station </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>
                                
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Police Officer name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.police_officer_name}
                                      name="police_officer_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Designation")}</Form.Label>
                                      <Select
                                        options={masterDesignationList}
                                        values={masterDesignationList?.filter(state => state.id === formData.mlc_designation)}
                                        name="mlc_designation"
                                        placeholder={t("Select Designation")}
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "mlc_designation")
                                        }}
                                      />
                                      
                                      {errorsHealth?.data?.mlc_designation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.mlc_designation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Bukkal No. / Badge No.")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.bukkal_badge_number}
                                      name="bukkal_badge_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`MLC Contact No.`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.mlc_contact_number}
                                      name="mlc_contact_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("MLC Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.mlc_number}
                                      name="mlc_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of MLC")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.mlc_date}
                                      name="mlc_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of MLC")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.mlc_time}
                                      name="mlc_time"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Doctor (As per MLC)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.name_of_doctor_as_per_mlc}
                                      name="name_of_doctor_as_per_mlc"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Registration No. (As per MLC)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_registration_no_as_per_mlc}
                                      name="doctor_registration_no_as_per_mlc"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Cause of Accident (As per MLC)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.cause_of_accident_as_per_mlc}
                                      name="cause_of_accident_as_per_mlc"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Is Alcohol Found in MLC")}
                                      name="alcohol_found_in_mlc"
                                      value="Yes"
                                      checked={formData.alcohol_found_in_mlc}
                                      onChange={(e) => inputHandler({ target: { name: "alcohol_found_in_mlc", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={10}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Hospital Visit Finding
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={10}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is Hospital Visit Done")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="hospital_visit_done"
                                        value="Yes"
                                        checked={formData.hospital_visit_done === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="hospital_visit_done"
                                        value="No"
                                        checked={formData.hospital_visit_done === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.hospital_visit_done === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Reason Hospital Visit Not Done")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.hospital_visit_not_remark}
                                        name="hospital_visit_not_remark"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Patient Appointment")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_date_appointment}
                                      name="patient_date_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Patient Appointment")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_time_appointment}
                                      name="patient_time_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Person Giving Appointment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_giving_appointment}
                                      name="person_giving_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Patient Visit")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_date_visit}
                                      name="patient_date_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Patient Visit")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.patient_time_visit}
                                      name="patient_time_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Location of Visit </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is it Match Hospital Location")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="hospital_match_hospital"
                                        value="Yes"
                                        checked={formData.hospital_match_hospital === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="hospital_match_hospital"
                                        value="No"
                                        checked={formData.hospital_match_hospital === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Is Planned Admission")}</label>
                                    <div style={{marginTop: '4%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="hospital_planned_admission"
                                        value="Yes"
                                        checked={formData.hospital_planned_admission === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="hospital_planned_admission"
                                        value="No"
                                        checked={formData.hospital_planned_admission === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Patient Present In Hospital")}</label>
                                    <div style={{marginTop: '4%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="patient_present_in_hospital"
                                        value="Yes"
                                        checked={formData.patient_present_in_hospital === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="patient_present_in_hospital"
                                        value="No"
                                        checked={formData.patient_present_in_hospital === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4} style={{marginTop: '2%'}}>
                                    <FormInput
                                      label={t("Number of Beds")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_number_of_beds}
                                      name="hospital_number_of_beds"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '2%'}}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Facility Available`}</Form.Label>
                                      <Select
                                        options={facilityAvailableList}
                                        values={facilityAvailableList?.filter(faci => faci?.id === formData?.facility_available)}
                                        name="facility_available"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "facility_available")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.facility_available && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.facility_available}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} style={{marginTop: '2%'}}>
                                    <FormInput
                                      label={t("Number of RMO & Nurses")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_number_of_rmo_and_nurses}
                                      name="hospital_number_of_rmo_and_nurses"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Duration of Current Ailment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.duration_of_current_ailment}
                                      name="duration_of_current_ailment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Any Past History")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.any_past_history}
                                      name="any_past_history"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Duration of Past History")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.duration_of_past_history}
                                      name="duration_of_past_history"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Hospital Staff Details Who Met at Hospital and Who Provided Records of Patient for Verification</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Salutation`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.hospital_staff_salutation)}
                                        name="hospital_staff_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "hospital_staff_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.hospital_staff_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.hospital_staff_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`First Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_staff_first}
                                      name="hospital_staff_first"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Middle Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_staff_middle}
                                      name="hospital_staff_middle"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Last Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_staff_last}
                                      name="hospital_staff_last"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Designation")}</Form.Label>
                                      <Select
                                        options={masterDesignationList}
                                        values={masterDesignationList?.filter(state => state.id === formData.hospital_staff_designation)}
                                        name="hospital_staff_designation"
                                        placeholder={t("Select Designation")}
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "hospital_staff_designation")
                                        }}
                                      />
                                      
                                      {errorsHealth?.data?.hospital_staff_designation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.hospital_staff_designation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Hospital Registration Certificate Provided")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="hospital_registration_certificate_provided"
                                        value="Yes"
                                        checked={formData.hospital_registration_certificate_provided === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="hospital_registration_certificate_provided"
                                        value="No"
                                        checked={formData.hospital_registration_certificate_provided === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Number of Beds`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_staff_no_of_beds}
                                      name="hospital_staff_no_of_beds"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={11}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Tariff List
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={11}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Tariff List Provided")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="tariff_list_provided"
                                        value="Yes"
                                        checked={formData.tariff_list_provided === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="tariff_list_provided"
                                        value="No"
                                        checked={formData.tariff_list_provided === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.tariff_list_provided === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Why Tariff List Not Provided")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.why_tariff_list_not_provided}
                                        name="why_tariff_list_not_provided"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Tariff Remarks")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.tariff_remarks}
                                      name="tariff_remarks"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={12}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                IPD Register
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={12}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("IPD Register Shown")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="ipd_register_shown"
                                        value="Yes"
                                        checked={formData.ipd_register_shown === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="ipd_register_shown"
                                        value="No"
                                        checked={formData.ipd_register_shown === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.ipd_register_shown === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Why IPD Register Not Provided")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.why_ipd_register_not_provided}
                                        name="why_ipd_register_not_provided"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <label>{t("Any Discrepancy in IPD Register")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="any_discrepancy_ipd_register"
                                        value="Yes"
                                        checked={formData.any_discrepancy_ipd_register === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="any_discrepancy_ipd_register"
                                        value="No"
                                        checked={formData.any_discrepancy_ipd_register === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("What Discrepancy Noted in IPD Register")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.what_discrepancy_noted_ipd_register}
                                      name="what_discrepancy_noted_ipd_register"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("IPD Register Photograph Enclosed")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="ipd_register_photograph_enclosed"
                                        value="Yes"
                                        checked={formData.ipd_register_photograph_enclosed === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="ipd_register_photograph_enclosed"
                                        value="No"
                                        checked={formData.ipd_register_photograph_enclosed === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("General IPD Register Verification Remarks")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.general_ipd_register_verification_remarks}
                                      name="general_ipd_register_verification_remarks"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={13}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Hospital Bill
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={13}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Hospital Record Bills Shown")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="hospital_record_bills_shown"
                                        value="Yes"
                                        checked={formData.hospital_record_bills_shown === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="hospital_record_bills_shown"
                                        value="No"
                                        checked={formData.hospital_record_bills_shown === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Any Discrepancy Noted in Hospital Records Bill")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="any_discrepancy_noted_hospital_record_bill"
                                        value="Yes"
                                        checked={formData.any_discrepancy_noted_hospital_record_bill === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="any_discrepancy_noted_hospital_record_bill"
                                        value="No"
                                        checked={formData.any_discrepancy_noted_hospital_record_bill === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("What Discrepancy Noted In Hospital Bill")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.what_discrepancy_noted_hospital_bill}
                                      name="what_discrepancy_noted_hospital_bill"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Hospital Record Deposit Receipt Shown")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="hospital_record_deposit_receipt_shown"
                                        value="Yes"
                                        checked={formData.hospital_record_deposit_receipt_shown === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="hospital_record_deposit_receipt_shown"
                                        value="No"
                                        checked={formData.hospital_record_deposit_receipt_shown === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Hospital Bill Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_bill_upload}
                                      name="hospital_bill_upload"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("General Hospital Bill Verification Remarks")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.general_hospital_bill_verification_remarks}
                                      name="general_hospital_bill_verification_remarks"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane eventKey="treatingDoctor">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Basic Details of Treating Doctor
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Treating Doctor Salutation`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.family_doctor_salutation)}
                                        name="family_doctor_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "family_doctor_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.family_doctor_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.family_doctor_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Treating Doctor First Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.family_doctor_first_name}
                                      name="family_doctor_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Treating Doctor Middle Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.family_doctor_middle_name}
                                      name="family_doctor_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Treating Doctor Last Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.family_doctor_last_name}
                                      name="family_doctor_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t(`Treating Doctor Gender`)}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Male")}
                                        name="family_doctor_gender"
                                        value="Male"
                                        checked={formData.family_doctor_gender === "Male"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Female")}
                                        name="family_doctor_gender"
                                        value="Female"
                                        checked={formData.family_doctor_gender === "Female"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Others")}
                                        name="family_doctor_gender"
                                        value="Others"
                                        checked={formData.family_doctor_gender === "Others"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Treating Doctor Qualification </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Treating Doctor Degree`)}</Form.Label>
                                      <Select
                                        options={degreeList}
                                        values={degreeList?.filter(gree => gree?.id === formData?.doctor_degree)}
                                        name="doctor_degree"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          const degreeType = degreeTypeMapping[selectedData[0]?.id];
                                          selectHandler2(selectedData[0]?.id, "doctor_degree");
                                          selectHandler2(degreeType, "degree_type");
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.doctor_degree && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.doctor_degree}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Treating Doctor Degree Type`)}</Form.Label>
                                      <Select
                                        options={degreeTypeList}
                                        values={degreeTypeList?.filter(degr => degr?.id === formData?.degree_type)}
                                        name="degree_type"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "degree_type")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.degree_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.degree_type}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Treating Doctor Contact No`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_number}
                                      name="hospital_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Treating Doctor Email ID`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_email}
                                      name="doctor_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Treating Doctor Live Photo`)}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_photo}
                                      name="doctor_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Treating Doctor Handwritting Sample`)}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_handwritting}
                                      name="doctor_handwritting"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Registration Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Doctor Registration No.")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_owner}
                                      name="hospital_owner"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Registration Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formattedRenewalDate}
                                      name="renewal_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Valid Upto Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formattedExpiryDate}
                                      name="expiry_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Doctor Registration Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Valid")}
                                        name="registration_status"
                                        value="Valid"
                                        checked={formData.registration_status === "Valid"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Expired")}
                                        name="registration_status"
                                        value="Expired"
                                        checked={formData.registration_status === "Expired"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData.registration_status === "Expired" && (
                                    <>
                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Doctor Expiry Date")}
                                          type="date"
                                          errors={errorsHealth?.data}
                                          value={formattedExpiryDate}
                                          name="expiry_date"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Doctor Renewal Date")}
                                          type="date"
                                          errors={errorsHealth?.data}
                                          value={formattedRenewalDate}
                                          name="renewal_date"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Issuing Office Authority")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.issuing_authority}
                                      name="issuing_authority"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <div
                                      style={{ position: "relative" }}
                                      onClick={() => window.open(formData?.rohini_id_link || "https://rtionline.gov.in/", "_blank")}
                                    >
                                      <FormInput
                                        label={t("Online RTI Link")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData?.rohini_id_link || "https://rtionline.gov.in/"}
                                        name="rohini_id_link"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                        readOnly
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Registration Certificate Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formattedExpiryDate}
                                      name="corticate_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey={1-1}
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Treating Doctor Social Media
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={1-1}>
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Whatsapp")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Instagram Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_instagram_link}
                                              name="hospital_instagram_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Facebook Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_facebook_link}
                                              name="hospital_facebook_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Linkdin Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_linkdin_link}
                                              name="hospital_linkdin_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Twitter Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_twitter_link}
                                              name="hospital_twitter_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Truecaller Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_truecaller_link}
                                              name="hospital_truecaller_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Justdial Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_justdial_link}
                                              name="hospital_justdial_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Eyecon Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Other Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={2}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Treating Doctor Related Data Search
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Data Related Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.data_related_policy}
                                      name="data_related_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={3}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Alert
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={3}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator Username")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_username}
                                      name="alert_creator_username"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_id}
                                      name="alert_creator_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Date & Time of Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.date_time_alert}
                                      name="date_time_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Comment on Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.comment_alert}
                                      name="comment_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={4}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Blacklisted
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={4}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <label>{t("Backlisted Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="backlisted_status"
                                        value="Yes"
                                        checked={formData.backlisted_status === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="backlisted_status"
                                        value="No"
                                        checked={formData.backlisted_status === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Data Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.blacklisted_insurance_data}
                                      name="blacklisted_insurance_data"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={5}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grievance Management
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={5}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Grievance and Escalation")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Grievance")}
                                        name="grievance_status"
                                        value="Grievance"
                                        checked={formData.grievance_status === "Grievance"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Escalation")}
                                        name="grievance_status"
                                        value="Escalation"
                                        checked={formData.grievance_status === "Escalation"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <br />
                                {formData.grievance_status && (<>
                                  <Row>
                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Type of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.type_of_grievance}
                                        name="type_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Date of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.date_of_grievance}
                                        name="date_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Notification of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.notification_of_grievance}
                                        name="notification_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Reason of the ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_of_grievance}
                                        name="reason_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Status of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.status_of_grievance}
                                        name="status_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Final Conclusion of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.final_of_grievance}
                                        name="final_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  </Row>
                                </>)}
                                
                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={6}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grading
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={6}>
                              <Card.Body>
                                
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={7}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Treating Doctor Visit Finding
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={7}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is Treating Doctor Visit Done")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="treating_doctor_visit_done"
                                        value="Yes"
                                        checked={formData.treating_doctor_visit_done === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="treating_doctor_visit_done"
                                        value="No"
                                        checked={formData.treating_doctor_visit_done === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.treating_doctor_visit_done === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Reason Corporate Visit Not Done")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.treating_doctor_visit_not_remark}
                                        name="treating_doctor_visit_not_remark"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Treating Doctor Appointment")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_date_appointment}
                                      name="treating_doctor_date_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Treating Doctor Appointment")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_time_appointment}
                                      name="treating_doctor_time_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Person Giving Appointment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_giving_appointment}
                                      name="person_giving_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Treating Doctor Visit")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_date_visit}
                                      name="treating_doctor_date_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Treating Doctor Visit")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_time_visit}
                                      name="treating_doctor_time_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Location of Visit </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is it Match Hospital Location")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="treating_doctor_match_hospital"
                                        value="Yes"
                                        checked={formData.treating_doctor_match_hospital === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="treating_doctor_match_hospital"
                                        value="No"
                                        checked={formData.treating_doctor_match_hospital === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={6}>
                                    <label>{t("Has the Treating Doctor Provided the Medical Certificate?")}</label>
                                    <div style={{marginTop: '4%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="treating_doctor_medical_certificate"
                                        value="Yes"
                                        checked={formData.treating_doctor_medical_certificate === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="treating_doctor_medical_certificate"
                                        value="No"
                                        checked={formData.treating_doctor_medical_certificate === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.treating_doctor_medical_certificate === "No" && (
                                    <Col lg={8} style={{marginTop: '2%'}}>
                                      <FormInput
                                        label={t("Remark on Why the Treating Doctor has Not Provided the Medical Certificate")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.treating_doctor_medical_certificate_remarks}
                                        name="treating_doctor_medical_certificate_remarks"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4} style={{marginTop: '2%'}}>
                                    <FormInput
                                      label={t("Upload Medical Certificate")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_medical_certificate_upload}
                                      name="treating_doctor_medical_certificate_upload"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={8}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Assistant Certificate Collapse Field
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={8}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Certificate Issue by Any Other Doctor")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.certificate_issue_other_doctor}
                                      name="certificate_issue_other_doctor"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Doctor Salutation`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.other_doctor_salutation)}
                                        name="other_doctor_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "other_doctor_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.other_doctor_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.other_doctor_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Doctor First Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_doctor_first_name}
                                      name="other_doctor_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Doctor Middle Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_doctor_middle_name}
                                      name="other_doctor_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Doctor Last Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_doctor_last_name}
                                      name="other_doctor_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Registration Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_doctor_registration_number}
                                      name="other_doctor_registration_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Mobile Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_doctor_mobile_number}
                                      name="other_doctor_mobile_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Treating Doctor Degree`)}</Form.Label>
                                      <Select
                                        options={degreeList}
                                        values={degreeList?.filter(gree => gree?.id === formData?.doctor_degree)}
                                        name="doctor_degree"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          const degreeType = degreeTypeMapping[selectedData[0]?.id];
                                          selectHandler2(selectedData[0]?.id, "doctor_degree");
                                          selectHandler2(degreeType, "degree_type");
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.doctor_degree && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.doctor_degree}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Treating Doctor Degree Type`)}</Form.Label>
                                      <Select
                                        options={degreeTypeList}
                                        values={degreeTypeList?.filter(degr => degr?.id === formData?.degree_type)}
                                        name="degree_type"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "degree_type")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.degree_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.degree_type}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Does the treating doctor’s handwriting match with the ICP Notes")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="other_doctor_handwritting_match"
                                        value="Yes"
                                        checked={formData.other_doctor_handwritting_match === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="other_doctor_handwritting_match"
                                        value="No"
                                        checked={formData.other_doctor_handwritting_match === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.other_doctor_handwritting_match === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("General Remarks of Treating Doctor Verification")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.treating_doctor_verification_remark_other}
                                        name="treating_doctor_verification_remark_other"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane eventKey="referralDoctor">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Basic Details of Referral Doctor
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Referral Doctor Salutation`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.referral_doctor_salutation)}
                                        name="referral_doctor_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "referral_doctor_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.referral_doctor_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.referral_doctor_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Referral Doctor First Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.referral_doctor_first_name}
                                      name="referral_doctor_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Referral Doctor Middle Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.referral_doctor_middle_name}
                                      name="referral_doctor_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Referral Doctor Last Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.referral_doctor_last_name}
                                      name="referral_doctor_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t(`Referral Doctor Gender`)}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Male")}
                                        name="referral_doctor_gender"
                                        value="Male"
                                        checked={formData.referral_doctor_gender === "Male"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Female")}
                                        name="referral_doctor_gender"
                                        value="Female"
                                        checked={formData.referral_doctor_gender === "Female"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Others")}
                                        name="referral_doctor_gender"
                                        value="Others"
                                        checked={formData.referral_doctor_gender === "Others"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Referral Doctor Qualification </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Referral Doctor Degree`)}</Form.Label>
                                      <Select
                                        options={degreeList}
                                        values={degreeList?.filter(gree => gree?.id === formData?.doctor_degree)}
                                        name="doctor_degree"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          const degreeType = degreeTypeMapping[selectedData[0]?.id];
                                          selectHandler2(selectedData[0]?.id, "doctor_degree");
                                          selectHandler2(degreeType, "degree_type");
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.doctor_degree && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.doctor_degree}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Referral Doctor Degree Type`)}</Form.Label>
                                      <Select
                                        options={degreeTypeList}
                                        values={degreeTypeList?.filter(degr => degr?.id === formData?.degree_type)}
                                        name="degree_type"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "degree_type")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.degree_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.degree_type}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Referral Doctor Contact No`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.referral_doctor_contact_number}
                                      name="referral_doctor_contact_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Referral Doctor Email ID`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.referral_doctor_email}
                                      name="referral_doctor_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Referral Doctor Live Photo`)}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_photo}
                                      name="doctor_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Referral Doctor Handwritting Sample`)}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_handwritting}
                                      name="doctor_handwritting"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Registration Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Doctor Registration No.")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_owner}
                                      name="hospital_owner"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Registration Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formattedRenewalDate}
                                      name="renewal_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Registration Valid Upto Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formattedExpiryDate}
                                      name="expiry_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Doctor Registration Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Valid")}
                                        name="registration_status"
                                        value="Valid"
                                        checked={formData.registration_status === "Valid"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Expired")}
                                        name="registration_status"
                                        value="Expired"
                                        checked={formData.registration_status === "Expired"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData.registration_status === "Expired" && (
                                    <>
                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Doctor Registration Expiry Date")}
                                          type="date"
                                          errors={errorsHealth?.data}
                                          value={formattedExpiryDate}
                                          name="expiry_date"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Doctor Registration Renewal Date")}
                                          type="date"
                                          errors={errorsHealth?.data}
                                          value={formattedRenewalDate}
                                          name="renewal_date"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Issuing Office Authority")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.issuing_authority}
                                      name="issuing_authority"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <div
                                      style={{ position: "relative" }}
                                      onClick={() => window.open(formData?.rohini_id_link || "https://rtionline.gov.in/", "_blank")}
                                    >
                                      <FormInput
                                        label={t("Online RTI Link")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData?.rohini_id_link || "https://rtionline.gov.in/"}
                                        name="rohini_id_link"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                        readOnly
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Registration Certificate Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formattedExpiryDate}
                                      name="corticate_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey={1-1}
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Referral Doctor Social Media
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={1-1}>
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Whatsapp")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Instagram Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_instagram_link}
                                              name="hospital_instagram_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Facebook Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_facebook_link}
                                              name="hospital_facebook_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Linkdin Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_linkdin_link}
                                              name="hospital_linkdin_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Twitter Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_twitter_link}
                                              name="hospital_twitter_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Truecaller Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_truecaller_link}
                                              name="hospital_truecaller_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Justdial Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_justdial_link}
                                              name="hospital_justdial_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Eyecon Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Other Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={2}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Referral Doctor Related Data Search
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Data Related Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.data_related_policy}
                                      name="data_related_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={3}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Alert
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={3}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator Username")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_username}
                                      name="alert_creator_username"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_id}
                                      name="alert_creator_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Date & Time of Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.date_time_alert}
                                      name="date_time_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Comment on Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.comment_alert}
                                      name="comment_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={4}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Blacklisted
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={4}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <label>{t("Backlisted Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="backlisted_status"
                                        value="Yes"
                                        checked={formData.backlisted_status === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="backlisted_status"
                                        value="No"
                                        checked={formData.backlisted_status === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Data Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.blacklisted_insurance_data}
                                      name="blacklisted_insurance_data"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={5}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grievance Management
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={5}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Grievance and Escalation")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Grievance")}
                                        name="grievance_status"
                                        value="Grievance"
                                        checked={formData.grievance_status === "Grievance"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Escalation")}
                                        name="grievance_status"
                                        value="Escalation"
                                        checked={formData.grievance_status === "Escalation"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <br />
                                {formData.grievance_status && (<>
                                  <Row>
                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Type of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.type_of_grievance}
                                        name="type_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Date of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.date_of_grievance}
                                        name="date_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Notification of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.notification_of_grievance}
                                        name="notification_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Reason of the ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_of_grievance}
                                        name="reason_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Status of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.status_of_grievance}
                                        name="status_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Final Conclusion of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.final_of_grievance}
                                        name="final_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  </Row>
                                </>)}
                                
                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={6}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grading
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={6}>
                              <Card.Body>
                                
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane eventKey="pathologyDetails">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Basic Details of Pathology
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Pathology Type")}</Form.Label>
                                      <Select
                                        options={pathologyTypeList}
                                        values={pathologyTypeList?.filter(path => path?.id === formData?.pathology_type)}
                                        name="pathology_type"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "pathology_type")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.pathology_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.pathology_type}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pathology Lab Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_first_name}
                                      name="treating_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Pathology (As Per Document)</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Pathology (As Per Field)</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country (As Per Field)")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory (As Per Field)")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District (As Per Field)")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>
                                
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pathology Phone No.`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_last_name}
                                      name="treating_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>
                                  
                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pathology Contact No.`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_last_name}
                                      name="treating_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>
                                  
                                  <Col lg={4}>
                                    
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pathology Email ID`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pathology GPS Photo Map`}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pathology Photo (As Per Field Visit)`}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>`

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Lab Owner Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Pathology Owner Salutation")}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.owner_salutation)}
                                        name="owner_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "owner_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.owner_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.owner_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pathology Owner First Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.tpa_first_name}
                                      name="tpa_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pathology Owner Middle Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.tpa_middle_name}
                                      name="tpa_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pathology Owner Last Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.tpa_last_name}
                                      name="tpa_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pathology Owner Mobile Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.tpa_number}
                                      name="tpa_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pathology Owner Email ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.tpa_email}
                                      name="tpa_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Pathology Degree`)}</Form.Label>
                                      <Select
                                        options={degreeList}
                                        values={degreeList?.filter(gree => gree?.id === formData?.pathology_degree)}
                                        name="pathology_degree"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          const selectedDegreeId = selectedData[0]?.id;
                                          const degreeType = degreeTypeMapping[selectedDegreeId];
                                          selectHandler2(selectedDegreeId, "pathology_degree");
                                          selectHandler2(degreeType, "degree_type");
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.pathology_degree && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.pathology_degree}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Pathology Degree Type`)}</Form.Label>
                                      <Select
                                        options={degreeTypeList}
                                        values={degreeTypeList?.filter(degr => degr?.id === formData?.degree_type)}
                                        name="degree_type"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "degree_type")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.degree_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.degree_type}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={2}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Referral Doctor Related Data Search
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Data Related Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.data_related_policy}
                                      name="data_related_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={3}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Alert
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={3}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator Username")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_username}
                                      name="alert_creator_username"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_id}
                                      name="alert_creator_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Date & Time of Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.date_time_alert}
                                      name="date_time_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Comment on Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.comment_alert}
                                      name="comment_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={4}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Blacklisted
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={4}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <label>{t("Backlisted Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="backlisted_status"
                                        value="Yes"
                                        checked={formData.backlisted_status === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="backlisted_status"
                                        value="No"
                                        checked={formData.backlisted_status === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Data Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.blacklisted_insurance_data}
                                      name="blacklisted_insurance_data"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={5}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grievance Management
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={5}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Grievance and Escalation")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Grievance")}
                                        name="grievance_status"
                                        value="Grievance"
                                        checked={formData.grievance_status === "Grievance"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Escalation")}
                                        name="grievance_status"
                                        value="Escalation"
                                        checked={formData.grievance_status === "Escalation"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <br />
                                {formData.grievance_status && (<>
                                  <Row>
                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Type of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.type_of_grievance}
                                        name="type_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Date of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.date_of_grievance}
                                        name="date_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Notification of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.notification_of_grievance}
                                        name="notification_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Reason of the ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_of_grievance}
                                        name="reason_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Status of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.status_of_grievance}
                                        name="status_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Final Conclusion of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.final_of_grievance}
                                        name="final_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  </Row>
                                </>)}
                                
                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={6}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grading
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={6}>
                              <Card.Body>
                                
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={7}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Pathology Visit Finding
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={7}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is Pathology Visit Done")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="pathology_visit_done"
                                        value="Yes"
                                        checked={formData.pathology_visit_done === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="pathology_visit_done"
                                        value="No"
                                        checked={formData.pathology_visit_done === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.pathology_visit_done === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Reason Pathology Visit Not Done")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.pathology_visit_not_remark}
                                        name="pathology_visit_not_remark"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Pathology Appointment")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.pathology_date_appointment}
                                      name="pathology_date_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Pathology Appointment")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.pathology_time_appointment}
                                      name="pathology_time_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Person Giving Appointment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_giving_appointment}
                                      name="person_giving_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Pathology Visit")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.pathology_date_visit}
                                      name="pathology_date_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Pathology Visit")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.pathology_time_visit}
                                      name="pathology_time_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Location of Visit </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is it Match Hospital Location")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="pathology_match_hospital"
                                        value="Yes"
                                        checked={formData.pathology_match_hospital === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="pathology_match_hospital"
                                        value="No"
                                        checked={formData.pathology_match_hospital === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Statement Issue By")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.pathology_statement_issue}
                                      name="pathology_statement_issue"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Lab entry Registrer Verify")}</label>
                                    <div style={{marginTop: '4%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="lab_entry_register_verify"
                                        value="Yes"
                                        checked={formData.lab_entry_register_verify === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="lab_entry_register_verify"
                                        value="No"
                                        checked={formData.lab_entry_register_verify === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Check Entry In Cell Counter")}</label>
                                    <div style={{marginTop: '4%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="entry_cell_counter"
                                        value="Yes"
                                        checked={formData.entry_cell_counter === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="entry_cell_counter"
                                        value="No"
                                        checked={formData.entry_cell_counter === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={8}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Owner Of Lab Collapse
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={8}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Lab Salutation`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.other_lab_salutation)}
                                        name="other_lab_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "other_lab_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.other_lab_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.other_lab_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Lab First Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_lab_first_name}
                                      name="other_lab_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Lab Middle Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_lab_middle_name}
                                      name="other_lab_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Lab Last Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_lab_last_name}
                                      name="other_lab_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Mobile Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_doctor_mobile_number}
                                      name="other_doctor_mobile_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Lab Owner Degree`)}</Form.Label>
                                      <Select
                                        options={degreeList}
                                        values={degreeList?.filter(gree => gree?.id === formData?.doctor_degree)}
                                        name="doctor_degree"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          const degreeType = degreeTypeMapping[selectedData[0]?.id];
                                          selectHandler2(selectedData[0]?.id, "doctor_degree");
                                          selectHandler2(degreeType, "degree_type");
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.doctor_degree && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.doctor_degree}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Lab Owner Degree Type`)}</Form.Label>
                                      <Select
                                        options={degreeTypeList}
                                        values={degreeTypeList?.filter(degr => degr?.id === formData?.degree_type)}
                                        name="degree_type"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "degree_type")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.degree_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.degree_type}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("General Remarks of Pathology Verification")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.pathology_verification_remark_other}
                                      name="pathology_verification_remark_other"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>
                      
                      <Tab.Pane eventKey="pathologistDetails">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Basic Details of Pathologist Doctor
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Pathologist Doctor Salutation`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.family_doctor_salutation)}
                                        name="family_doctor_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "family_doctor_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.family_doctor_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.family_doctor_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Pathologist Doctor First Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.family_doctor_first_name}
                                      name="family_doctor_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Pathologist Doctor Middle Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.family_doctor_middle_name}
                                      name="family_doctor_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Pathologist Doctor Last Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.family_doctor_last_name}
                                      name="family_doctor_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t(`Pathologist Doctor Gender`)}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Male")}
                                        name="family_doctor_gender"
                                        value="Male"
                                        checked={formData.family_doctor_gender === "Male"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Female")}
                                        name="family_doctor_gender"
                                        value="Female"
                                        checked={formData.family_doctor_gender === "Female"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Others")}
                                        name="family_doctor_gender"
                                        value="Others"
                                        checked={formData.family_doctor_gender === "Others"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Pathologist Doctor Qualification </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Pathologist Doctor Degree`)}</Form.Label>
                                      <Select
                                        options={degreeList}
                                        values={degreeList?.filter(gree => gree?.id === formData?.doctor_degree)}
                                        name="doctor_degree"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          const degreeType = degreeTypeMapping[selectedData[0]?.id];
                                          selectHandler2(selectedData[0]?.id, "doctor_degree");
                                          selectHandler2(degreeType, "degree_type");
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.doctor_degree && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.doctor_degree}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Pathologist Doctor Degree Type`)}</Form.Label>
                                      <Select
                                        options={degreeTypeList}
                                        values={degreeTypeList?.filter(degr => degr?.id === formData?.degree_type)}
                                        name="degree_type"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "degree_type")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.degree_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.degree_type}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Pathologist Doctor Contact No`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_number}
                                      name="hospital_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Pathologist Doctor Email ID`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_email}
                                      name="doctor_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Pathologist Doctor Live Photo`)}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_photo}
                                      name="doctor_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Pathologist Doctor Handwritting Sample`)}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_handwritting}
                                      name="doctor_handwritting"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Registration Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Doctor Registration No.")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_owner}
                                      name="hospital_owner"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Registration Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formattedRenewalDate}
                                      name="renewal_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Valid Upto Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formattedExpiryDate}
                                      name="expiry_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Doctor Registration Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Valid")}
                                        name="registration_status"
                                        value="Valid"
                                        checked={formData.registration_status === "Valid"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Expired")}
                                        name="registration_status"
                                        value="Expired"
                                        checked={formData.registration_status === "Expired"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData.registration_status === "Expired" && (
                                    <>
                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Doctor Expiry Date")}
                                          type="date"
                                          errors={errorsHealth?.data}
                                          value={formattedExpiryDate}
                                          name="expiry_date"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Doctor Renewal Date")}
                                          type="date"
                                          errors={errorsHealth?.data}
                                          value={formattedRenewalDate}
                                          name="renewal_date"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Issuing Office Authority")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.issuing_authority}
                                      name="issuing_authority"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <div
                                      style={{ position: "relative" }}
                                      onClick={() => window.open(formData?.rohini_id_link || "https://rtionline.gov.in/", "_blank")}
                                    >
                                      <FormInput
                                        label={t("Online RTI Link")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData?.rohini_id_link || "https://rtionline.gov.in/"}
                                        name="rohini_id_link"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                        readOnly
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Registration Certificate Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formattedExpiryDate}
                                      name="corticate_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey={1-1}
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Pathologist Doctor Social Media
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={1-1}>
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Whatsapp")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Instagram Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_instagram_link}
                                              name="hospital_instagram_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Facebook Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_facebook_link}
                                              name="hospital_facebook_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Linkdin Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_linkdin_link}
                                              name="hospital_linkdin_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Twitter Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_twitter_link}
                                              name="hospital_twitter_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Truecaller Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_truecaller_link}
                                              name="hospital_truecaller_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Justdial Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_justdial_link}
                                              name="hospital_justdial_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Eyecon Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Other Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={2}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Pathologist Doctor Related Data Search
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Data Related Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.data_related_policy}
                                      name="data_related_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={3}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Alert
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={3}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator Username")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_username}
                                      name="alert_creator_username"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_id}
                                      name="alert_creator_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Date & Time of Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.date_time_alert}
                                      name="date_time_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Comment on Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.comment_alert}
                                      name="comment_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={4}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Blacklisted
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={4}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <label>{t("Backlisted Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="backlisted_status"
                                        value="Yes"
                                        checked={formData.backlisted_status === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="backlisted_status"
                                        value="No"
                                        checked={formData.backlisted_status === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Data Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.blacklisted_insurance_data}
                                      name="blacklisted_insurance_data"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={5}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grievance Management
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={5}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Grievance and Escalation")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Grievance")}
                                        name="grievance_status"
                                        value="Grievance"
                                        checked={formData.grievance_status === "Grievance"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Escalation")}
                                        name="grievance_status"
                                        value="Escalation"
                                        checked={formData.grievance_status === "Escalation"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <br />
                                {formData.grievance_status && (<>
                                  <Row>
                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Type of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.type_of_grievance}
                                        name="type_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Date of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.date_of_grievance}
                                        name="date_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Notification of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.notification_of_grievance}
                                        name="notification_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Reason of the ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_of_grievance}
                                        name="reason_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Status of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.status_of_grievance}
                                        name="status_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Final Conclusion of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.final_of_grievance}
                                        name="final_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  </Row>
                                </>)}
                                
                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={6}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grading
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={6}>
                              <Card.Body>
                                
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={7}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Pathologist Doctor Visit Finding
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={7}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is Pathologist Doctor Visit Done")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="treating_doctor_visit_done"
                                        value="Yes"
                                        checked={formData.treating_doctor_visit_done === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="treating_doctor_visit_done"
                                        value="No"
                                        checked={formData.treating_doctor_visit_done === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.treating_doctor_visit_done === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Reason Corporate Visit Not Done")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.treating_doctor_visit_not_remark}
                                        name="treating_doctor_visit_not_remark"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Pathologist Doctor Appointment")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_date_appointment}
                                      name="treating_doctor_date_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Pathologist Doctor Appointment")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_time_appointment}
                                      name="treating_doctor_time_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Person Giving Appointment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_giving_appointment}
                                      name="person_giving_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Pathologist Doctor Visit")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_date_visit}
                                      name="treating_doctor_date_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Pathologist Doctor Visit")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_time_visit}
                                      name="treating_doctor_time_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Location of Visit </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is it Match Hospital Location")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="treating_doctor_match_hospital"
                                        value="Yes"
                                        checked={formData.treating_doctor_match_hospital === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="treating_doctor_match_hospital"
                                        value="No"
                                        checked={formData.treating_doctor_match_hospital === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("General Remarks of Pathologist Doctor Verification ")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_medical_certificate_remarks}
                                      name="treating_doctor_medical_certificate_remarks"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane eventKey="radiologyDetails">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Basic Details of Radiology
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Radiology Type")}</Form.Label>
                                      <Select
                                        options={pathologyTypeList}
                                        values={pathologyTypeList?.filter(path => path?.id === formData?.pathology_type)}
                                        name="pathology_type"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "pathology_type")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.pathology_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.pathology_type}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Radiology Center Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_first_name}
                                      name="treating_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Radiology (As Per Document)</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Radiology (As Per Field)</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country (As Per Field)")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory (As Per Field)")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District (As Per Field)")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>
                                
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={`Radiology Phone No.`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_last_name}
                                      name="treating_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>
                                  
                                  <Col lg={4}>
                                    <FormInput
                                      label={`Radiology Contact No.`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_last_name}
                                      name="treating_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>
                                  
                                  <Col lg={4}>
                                    
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Radiology Email ID`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={`Radiology GPS Photo Map`}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Radiology Photo (As Per Field Visit)`}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>`

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Center Owner Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Radiology Owner Salutation")}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.owner_salutation)}
                                        name="owner_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "owner_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.owner_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.owner_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Radiology Owner First Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.tpa_first_name}
                                      name="tpa_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Radiology Owner Middle Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.tpa_middle_name}
                                      name="tpa_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Radiology Owner Last Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.tpa_last_name}
                                      name="tpa_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Radiology Owner Mobile Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.tpa_number}
                                      name="tpa_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Radiology Owner Email ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.tpa_email}
                                      name="tpa_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Radiology Degree`)}</Form.Label>
                                      <Select
                                        options={degreeList}
                                        values={degreeList?.filter(gree => gree?.id === formData?.pathology_degree)}
                                        name="pathology_degree"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          const selectedDegreeId = selectedData[0]?.id;
                                          const degreeType = degreeTypeMapping[selectedDegreeId];
                                          selectHandler2(selectedDegreeId, "pathology_degree");
                                          selectHandler2(degreeType, "degree_type");
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.pathology_degree && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.pathology_degree}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Radiology Degree Type`)}</Form.Label>
                                      <Select
                                        options={degreeTypeList}
                                        values={degreeTypeList?.filter(degr => degr?.id === formData?.degree_type)}
                                        name="degree_type"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "degree_type")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.degree_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.degree_type}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={2}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Referral Doctor Related Data Search
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Data Related Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.data_related_policy}
                                      name="data_related_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={3}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Alert
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={3}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator Username")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_username}
                                      name="alert_creator_username"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_id}
                                      name="alert_creator_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Date & Time of Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.date_time_alert}
                                      name="date_time_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Comment on Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.comment_alert}
                                      name="comment_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={4}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Blacklisted
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={4}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <label>{t("Backlisted Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="backlisted_status"
                                        value="Yes"
                                        checked={formData.backlisted_status === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="backlisted_status"
                                        value="No"
                                        checked={formData.backlisted_status === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Data Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.blacklisted_insurance_data}
                                      name="blacklisted_insurance_data"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={5}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grievance Management
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={5}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Grievance and Escalation")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Grievance")}
                                        name="grievance_status"
                                        value="Grievance"
                                        checked={formData.grievance_status === "Grievance"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Escalation")}
                                        name="grievance_status"
                                        value="Escalation"
                                        checked={formData.grievance_status === "Escalation"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <br />
                                {formData.grievance_status && (<>
                                  <Row>
                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Type of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.type_of_grievance}
                                        name="type_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Date of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.date_of_grievance}
                                        name="date_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Notification of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.notification_of_grievance}
                                        name="notification_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Reason of the ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_of_grievance}
                                        name="reason_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Status of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.status_of_grievance}
                                        name="status_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Final Conclusion of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.final_of_grievance}
                                        name="final_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  </Row>
                                </>)}
                                
                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={6}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grading
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={6}>
                              <Card.Body>
                                
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={7}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Radiology Visit Finding
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={7}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is Radiology Visit Done")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="pathology_visit_done"
                                        value="Yes"
                                        checked={formData.pathology_visit_done === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="pathology_visit_done"
                                        value="No"
                                        checked={formData.pathology_visit_done === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.pathology_visit_done === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Reason Radiology Visit Not Done")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.pathology_visit_not_remark}
                                        name="pathology_visit_not_remark"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Radiology Appointment")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.pathology_date_appointment}
                                      name="pathology_date_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Radiology Appointment")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.pathology_time_appointment}
                                      name="pathology_time_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Person Giving Appointment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_giving_appointment}
                                      name="person_giving_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Radiology Visit")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.pathology_date_visit}
                                      name="pathology_date_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Radiology Visit")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.pathology_time_visit}
                                      name="pathology_time_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Location of Visit </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is it Match Hospital Location")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="pathology_match_hospital"
                                        value="Yes"
                                        checked={formData.pathology_match_hospital === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="pathology_match_hospital"
                                        value="No"
                                        checked={formData.pathology_match_hospital === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Statement Issue By")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.pathology_statement_issue}
                                      name="pathology_statement_issue"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Center entry Registrer Verify")}</label>
                                    <div style={{marginTop: '4%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="lab_entry_register_verify"
                                        value="Yes"
                                        checked={formData.lab_entry_register_verify === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="lab_entry_register_verify"
                                        value="No"
                                        checked={formData.lab_entry_register_verify === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Check Entry In Cell Counter")}</label>
                                    <div style={{marginTop: '4%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="entry_cell_counter"
                                        value="Yes"
                                        checked={formData.entry_cell_counter === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="entry_cell_counter"
                                        value="No"
                                        checked={formData.entry_cell_counter === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={8}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Owner Of Center Collapse
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={8}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Center Salutation`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.other_lab_salutation)}
                                        name="other_lab_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "other_lab_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.other_lab_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.other_lab_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Center First Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_lab_first_name}
                                      name="other_lab_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Center Middle Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_lab_middle_name}
                                      name="other_lab_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Center Last Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_lab_last_name}
                                      name="other_lab_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Mobile Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.other_doctor_mobile_number}
                                      name="other_doctor_mobile_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Center Owner Degree`)}</Form.Label>
                                      <Select
                                        options={degreeList}
                                        values={degreeList?.filter(gree => gree?.id === formData?.doctor_degree)}
                                        name="doctor_degree"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          const degreeType = degreeTypeMapping[selectedData[0]?.id];
                                          selectHandler2(selectedData[0]?.id, "doctor_degree");
                                          selectHandler2(degreeType, "degree_type");
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.doctor_degree && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.doctor_degree}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Center Owner Degree Type`)}</Form.Label>
                                      <Select
                                        options={degreeTypeList}
                                        values={degreeTypeList?.filter(degr => degr?.id === formData?.degree_type)}
                                        name="degree_type"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "degree_type")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.degree_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.degree_type}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("General Remarks of Radiology Verification")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.pathology_verification_remark_other}
                                      name="pathology_verification_remark_other"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane eventKey="radiologistDetails">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Basic Details of Radiologist Doctor
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Radiologist Doctor Salutation`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.family_doctor_salutation)}
                                        name="family_doctor_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "family_doctor_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.family_doctor_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.family_doctor_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Radiologist Doctor First Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.family_doctor_first_name}
                                      name="family_doctor_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Radiologist Doctor Middle Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.family_doctor_middle_name}
                                      name="family_doctor_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Radiologist Doctor Last Name`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.family_doctor_last_name}
                                      name="family_doctor_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t(`Radiologist Doctor Gender`)}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Male")}
                                        name="family_doctor_gender"
                                        value="Male"
                                        checked={formData.family_doctor_gender === "Male"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Female")}
                                        name="family_doctor_gender"
                                        value="Female"
                                        checked={formData.family_doctor_gender === "Female"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Others")}
                                        name="family_doctor_gender"
                                        value="Others"
                                        checked={formData.family_doctor_gender === "Others"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Radiologist Doctor Qualification </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Radiologist Doctor Degree`)}</Form.Label>
                                      <Select
                                        options={degreeList}
                                        values={degreeList?.filter(gree => gree?.id === formData?.doctor_degree)}
                                        name="doctor_degree"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          const degreeType = degreeTypeMapping[selectedData[0]?.id];
                                          selectHandler2(selectedData[0]?.id, "doctor_degree");
                                          selectHandler2(degreeType, "degree_type");
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.doctor_degree && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.doctor_degree}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Radiologist Doctor Degree Type`)}</Form.Label>
                                      <Select
                                        options={degreeTypeList}
                                        values={degreeTypeList?.filter(degr => degr?.id === formData?.degree_type)}
                                        name="degree_type"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "degree_type")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.degree_type && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.degree_type}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Radiologist Doctor Contact No`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_number}
                                      name="hospital_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Radiologist Doctor Email ID`)}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_email}
                                      name="doctor_email"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Radiologist Doctor Live Photo`)}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_photo}
                                      name="doctor_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t(`Radiologist Doctor Handwritting Sample`)}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.doctor_handwritting}
                                      name="doctor_handwritting"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Registration Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Doctor Registration No.")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_owner}
                                      name="hospital_owner"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Registration Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formattedRenewalDate}
                                      name="renewal_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Valid Upto Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formattedExpiryDate}
                                      name="expiry_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Doctor Registration Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Valid")}
                                        name="registration_status"
                                        value="Valid"
                                        checked={formData.registration_status === "Valid"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Expired")}
                                        name="registration_status"
                                        value="Expired"
                                        checked={formData.registration_status === "Expired"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData.registration_status === "Expired" && (
                                    <>
                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Doctor Expiry Date")}
                                          type="date"
                                          errors={errorsHealth?.data}
                                          value={formattedExpiryDate}
                                          name="expiry_date"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Doctor Renewal Date")}
                                          type="date"
                                          errors={errorsHealth?.data}
                                          value={formattedRenewalDate}
                                          name="renewal_date"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Issuing Office Authority")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.issuing_authority}
                                      name="issuing_authority"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <div
                                      style={{ position: "relative" }}
                                      onClick={() => window.open(formData?.rohini_id_link || "https://rtionline.gov.in/", "_blank")}
                                    >
                                      <FormInput
                                        label={t("Online RTI Link")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData?.rohini_id_link || "https://rtionline.gov.in/"}
                                        name="rohini_id_link"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                        readOnly
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Doctor Registration Certificate Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formattedExpiryDate}
                                      name="corticate_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey={1-1}
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Radiologist Doctor Social Media
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={1-1}>
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Whatsapp")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Instagram Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_instagram_link}
                                              name="hospital_instagram_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Facebook Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_facebook_link}
                                              name="hospital_facebook_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Linkdin Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_linkdin_link}
                                              name="hospital_linkdin_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Twitter Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_twitter_link}
                                              name="hospital_twitter_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Truecaller Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_truecaller_link}
                                              name="hospital_truecaller_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Justdial Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_justdial_link}
                                              name="hospital_justdial_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Eyecon Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Other Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={2}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Radiologist Doctor Related Data Search
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Data Related Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.data_related_policy}
                                      name="data_related_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={3}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Alert
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={3}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator Username")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_username}
                                      name="alert_creator_username"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_id}
                                      name="alert_creator_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Date & Time of Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.date_time_alert}
                                      name="date_time_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Comment on Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.comment_alert}
                                      name="comment_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={4}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Blacklisted
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={4}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <label>{t("Backlisted Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="backlisted_status"
                                        value="Yes"
                                        checked={formData.backlisted_status === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="backlisted_status"
                                        value="No"
                                        checked={formData.backlisted_status === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Data Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.blacklisted_insurance_data}
                                      name="blacklisted_insurance_data"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={5}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grievance Management
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={5}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Grievance and Escalation")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Grievance")}
                                        name="grievance_status"
                                        value="Grievance"
                                        checked={formData.grievance_status === "Grievance"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Escalation")}
                                        name="grievance_status"
                                        value="Escalation"
                                        checked={formData.grievance_status === "Escalation"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <br />
                                {formData.grievance_status && (<>
                                  <Row>
                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Type of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.type_of_grievance}
                                        name="type_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Date of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.date_of_grievance}
                                        name="date_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Notification of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.notification_of_grievance}
                                        name="notification_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Reason of the ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_of_grievance}
                                        name="reason_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Status of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.status_of_grievance}
                                        name="status_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Final Conclusion of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.final_of_grievance}
                                        name="final_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  </Row>
                                </>)}
                                
                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={6}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grading
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={6}>
                              <Card.Body>
                                
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={7}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Radiologist Doctor Visit Finding
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={7}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is Radiologist Doctor Visit Done")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="treating_doctor_visit_done"
                                        value="Yes"
                                        checked={formData.treating_doctor_visit_done === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="treating_doctor_visit_done"
                                        value="No"
                                        checked={formData.treating_doctor_visit_done === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.treating_doctor_visit_done === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Reason Corporate Visit Not Done")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.treating_doctor_visit_not_remark}
                                        name="treating_doctor_visit_not_remark"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Radiologist Doctor Appointment")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_date_appointment}
                                      name="treating_doctor_date_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Radiologist Doctor Appointment")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_time_appointment}
                                      name="treating_doctor_time_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Person Giving Appointment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_giving_appointment}
                                      name="person_giving_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Radiologist Doctor Visit")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_date_visit}
                                      name="treating_doctor_date_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Radiologist Doctor Visit")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_time_visit}
                                      name="treating_doctor_time_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Location of Visit </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is it Match Hospital Location")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="treating_doctor_match_hospital"
                                        value="Yes"
                                        checked={formData.treating_doctor_match_hospital === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="treating_doctor_match_hospital"
                                        value="No"
                                        checked={formData.treating_doctor_match_hospital === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("General Remarks of Radiologist Doctor Verification ")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_doctor_medical_certificate_remarks}
                                      name="treating_doctor_medical_certificate_remarks"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane eventKey="pharmacyData">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Basic Details of Pharmacy
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pharmacy Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_first_name}
                                      name="treating_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Pharmacy (As Per Document)</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Pharmacy (As Per Field)</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country (As Per Field)")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory (As Per Field)")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District (As Per Field)")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode (As Per Field)")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={`Contact No.`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_last_name}
                                      name="treating_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Telephone No.`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Email ID`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={`GPS Photo Map`}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Photo (As Per Field Visit)`}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey="0-1"
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Pharmacy Social Media
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0-1">
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Whatsapp")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Instagram Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_instagram_link}
                                              name="hospital_instagram_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Facebook Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_facebook_link}
                                              name="hospital_facebook_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Linkdin Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_linkdin_link}
                                              name="hospital_linkdin_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Twitter Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_twitter_link}
                                              name="hospital_twitter_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Justdial Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_jusrtdial_link}
                                              name="hospital_jusrtdial_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Truecaller Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_truecaller_link}
                                              name="hospital_truecaller_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Eyecon Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Other Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                DL Verification
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("DL (Drug License) Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.dl_number}
                                      name="dl_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pharmacy Council Registration Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.council_number}
                                      name="council_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <div
                                      style={{ position: "relative" }}
                                      onClick={() => window.open(formData?.dl_verification || "https://parivahan.gov.in/rcdlstatus/?pur_cd=101", "_blank")}
                                    >
                                      <FormInput
                                        label={t("Online DL Verification")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData?.dl_verification || "https://parivahan.gov.in/rcdlstatus/?pur_cd=101"}
                                        name="dl_verification"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                        readOnly
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Verification Photo")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.verification_photo}
                                      name="verification_photo"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Verification Comment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.verification_comment}
                                      name="verification_comment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={2}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Owner of Pharmacy
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Pharmacy Owner Salutation`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.holder_salutation)}
                                        name="holder_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "holder_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.holder_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.holder_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pharmacy Owner First Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.holder_first_name}
                                      name="holder_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pharmacy Owner Middle Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.holder_middle_name}
                                      name="holder_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pharmacy Owner Last Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.holder_last_name}
                                      name="holder_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Pharmacy Owner</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={8}>
                                    
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pharmacy Owner Contact No.`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_last_name}
                                      name="treating_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pharmacy Owner Email ID`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Pharmacy Owner Education`)}</Form.Label>
                                      <Select
                                        options={masterQualificationList}
                                        values={masterQualificationList?.filter(qual => qual?.id === formData?.doctor_qualification)}
                                        name="doctor_qualification"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "doctor_qualification")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.doctor_qualification && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.doctor_qualification}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey={2-1}
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Pharmacy Owner Social Media
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={2-1}>
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Whatsapp")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Instagram Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_instagram_link}
                                              name="hospital_instagram_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Facebook Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_facebook_link}
                                              name="hospital_facebook_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Linkdin Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_linkdin_link}
                                              name="hospital_linkdin_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Twitter Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_twitter_link}
                                              name="hospital_twitter_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Truecaller Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_truecaller_link}
                                              name="hospital_truecaller_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Justdial Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_justdial_link}
                                              name="hospital_justdial_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Eyecon Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Other Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={4}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Pharmacist Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={4}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Pharmacist Salutation`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.holder_salutation)}
                                        name="holder_salutation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "holder_salutation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.holder_salutation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.holder_salutation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pharmacist First Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.holder_first_name}
                                      name="holder_first_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pharmacist Middle Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.holder_middle_name}
                                      name="holder_middle_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pharmacist Last Name`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.holder_last_name}
                                      name="holder_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Pharmacist</span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={8}>
                                    
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pharmacist Contact No.`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.treating_last_name}
                                      name="treating_last_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Pharmacist Email ID`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.lab_name}
                                      name="lab_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4} style={{marginTop: '28px'}}>
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Save")}
                                    </Button>
                                  </Col>

                                  <Col lg={4}></Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t(`Pharmacist Education`)}</Form.Label>
                                      <Select
                                        options={masterQualificationList}
                                        values={masterQualificationList?.filter(qual => qual?.id === formData?.doctor_qualification)}
                                        name="doctor_qualification"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "doctor_qualification")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.doctor_qualification && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.doctor_qualification}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Accordion>
                                  <Card className="mt-3">
                                    <Card.Header>
                                      <CustomToggle
                                        eventKey={3-1}
                                        containerClass="m-0"
                                        linkClass="text-dark"
                                      >
                                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                        Pharmacist Social Media
                                      </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={3-1}>
                                      <Card.Body>
                                        <Row>
                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Whatsapp")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_whatsapp_link}
                                              name="hospital_whatsapp_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Instagram Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_instagram_link}
                                              name="hospital_instagram_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Facebook Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_facebook_link}
                                              name="hospital_facebook_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Linkdin Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_linkdin_link}
                                              name="hospital_linkdin_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Twitter Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_twitter_link}
                                              name="hospital_twitter_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Truecaller Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_truecaller_link}
                                              name="hospital_truecaller_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Justdial Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_justdial_link}
                                              name="hospital_justdial_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Eyecon Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>

                                          <Col lg={4}>
                                            <FormInput
                                              label={t("Other Link")}
                                              type="text"
                                              errors={errorsHealth?.data}
                                              value={formData.hospital_eyecon_link}
                                              name="hospital_eyecon_link"
                                              onChange={inputHandler}
                                              containerClass={"mb-3"}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={5}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                GST No.
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={5}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("GST Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.gst_number}
                                      name="gst_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("GST Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.gst_upload}
                                      name="gst_upload"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={6}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Pharmacy Located inside the Hospital
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={6}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t(`Pharmacy Located Inside The Hospital`)}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="inside_hospital"
                                        value="Yes"
                                        checked={formData.inside_hospital === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="inside_hospital"
                                        value="No"
                                        checked={formData.inside_hospital === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData.inside_hospital === "Yes" && (
                                    <>
                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Hospital Name")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData.tpa_first_name}
                                          name="tpa_first_name"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </>
                                  )}
                                </Row>

                                {formData.inside_hospital === "Yes" && (
                                  <>
                                    <Row>
                                      <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Address of Hospital</span>
                                      <Col lg={4}>
                                        <Form.Group className="mb-3">
                                          <Form.Label>{t("Country")}</Form.Label>
                                          <Select
                                            options={countryList}
                                            values={countryList?.filter(country => country?.id === formData?.country_name)}
                                            name="country_name"
                                            labelField={"name"}
                                            valueField={"id"}
                                            searchBy={"name"}
                                            loading={loadingMaster}
                                            onChange={(selectedData) => {
                                              selectHandler2(selectedData[0]?.id, "country_name")
                                            }}
                                            styles={null}
                                          />
                                          
                                          {errorsHealth?.data?.country_name && (
                                            <div className="invalid-feedback d-block">
                                              <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                            </div>
                                          )}
                                        </Form.Group>
                                      </Col>

                                      <Col lg={4}>
                                        <Form.Group className="mb-3">
                                          <Form.Label>{t("State / Union Territory")}</Form.Label>
                                          <Select
                                            options={masterStateLists}
                                            values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                            name="state_code"
                                            labelField={"state_name"}
                                            valueField={"state_code"}
                                            searchBy={"state_name"}
                                            loading={loadingMaster}
                                            onChange={(selectedData) => {
                                              selectHandler2(selectedData[0]?.state_code, "state_code")
                                              setStateId(selectedData[0]?.state_code);
                                            }}
                                            styles={null}
                                          />
                                          
                                          {errorsHealth?.data?.state_code && (
                                            <div className="invalid-feedback d-block">
                                              <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                            </div>
                                          )}
                                        </Form.Group>
                                      </Col>

                                      <Col lg={4}>
                                        <Form.Group className="mb-3">
                                          <Form.Label>{t("District")}</Form.Label>
                                          <Select
                                            options={masterCityStateWiseLists}
                                            values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                            name="city_code"
                                            labelField={"city_name"}
                                            valueField={"city_code"}
                                            searchBy={"city_name"}
                                            loading={loadingMaster}
                                            onChange={(selectedData) => {
                                              selectHandler2(selectedData[0]?.city_code, "city_code")
                                            }}
                                            styles={null}
                                          />
                                          
                                          {errorsHealth?.data?.city_code && (
                                            <div className="invalid-feedback d-block">
                                              <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                            </div>
                                          )}
                                        </Form.Group>
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Taluka / Sub-District")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData.hospital_address}
                                          name="hospital_address"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Village / City / Town")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData.hospital_address}
                                          name="hospital_address"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Flat / House Number / Building Name")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData.hospital_address}
                                          name="hospital_address"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Street Name / Road Name")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData.hospital_address}
                                          name="hospital_address"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Area / Locality")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData.hospital_address}
                                          name="hospital_address"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Landmark")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData.hospital_address}
                                          name="hospital_address"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Pincode")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData.hospital_pincode}
                                          name="hospital_pincode"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                )}

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={7}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Pharmacy Related Data Search
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={7}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <FormInput
                                      label={t("Data Related Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.data_related_policy}
                                      name="data_related_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={8}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Hospitalisation Details
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={8}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Bill Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.bill_number}
                                      name="bill_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.data_related_policy}
                                      name="data_related_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Amount")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospitalisation_amount}
                                      name="hospitalisation_amount"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={9}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Alert
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={9}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Alert Creator Username")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_username}
                                      name="alert_creator_username"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Alert Creator ID")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.alert_creator_id}
                                      name="alert_creator_id"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Date & Time of Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.date_time_alert}
                                      name="date_time_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={3}>
                                    <FormInput
                                      label={t("Comment on Alert")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.comment_alert}
                                      name="comment_alert"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={10}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Blacklisted
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={10}>
                              <Card.Body>
                                <Row>
                                  <Col lg={3}>
                                    <label>{t("Backlisted Status")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="backlisted_status"
                                        value="Yes"
                                        checked={formData.backlisted_status === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="backlisted_status"
                                        value="No"
                                        checked={formData.backlisted_status === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Data Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.blacklisted_insurance_data}
                                      name="blacklisted_insurance_data"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={11}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grievance Management
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={11}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Grievance and Escalation")}</label>
                                    <div style={{marginTop: '5%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Grievance")}
                                        name="grievance_status"
                                        value="Grievance"
                                        checked={formData.grievance_status === "Grievance"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("Escalation")}
                                        name="grievance_status"
                                        value="Escalation"
                                        checked={formData.grievance_status === "Escalation"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <br />
                                {formData.grievance_status && (<>
                                  <Row>
                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Type of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.type_of_grievance}
                                        name="type_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Date of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.date_of_grievance}
                                        name="date_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Notification of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.notification_of_grievance}
                                        name="notification_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Reason of the ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.reason_of_grievance}
                                        name="reason_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Status of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.status_of_grievance}
                                        name="status_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Final Conclusion of ${formData.grievance_status}`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.final_of_grievance}
                                        name="final_of_grievance"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  </Row>
                                </>)}
                                
                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={12}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Grading
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={12}>
                              <Card.Body>
                                
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={13}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Pharmacy Visit Finding
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={13}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is Pharmacy Visit Done")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check
                                        type="radio"
                                        label={t("Yes")}
                                        name="pharmacy_visit_done"
                                        value="Yes"
                                        checked={formData.pharmacy_visit_done === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check
                                        type="radio"
                                        label={t("No")}
                                        name="pharmacy_visit_done"
                                        value="No"
                                        checked={formData.pharmacy_visit_done === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.pharmacy_visit_done === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Reason Pharmacy Visit Not Done")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.pharmacy_visit_not_remark}
                                        name="pharmacy_visit_not_remark"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Pharmacy Appointment")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.pharmacy_date_appointment}
                                      name="pharmacy_date_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Pharmacy Appointment")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.pharmacy_time_appointment}
                                      name="pharmacy_time_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name of Person Giving Appointment")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.person_giving_appointment}
                                      name="person_giving_appointment"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Pharmacy Visit")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.pharmacy_date_visit}
                                      name="pharmacy_date_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Time of Pharmacy Visit")}
                                      type="time"
                                      errors={errorsHealth?.data}
                                      value={formData.pharmacy_time_visit}
                                      name="pharmacy_time_visit"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <span style={{fontSize: "17pt",fontWeight: 600,color: "#b80b0b",marginBottom: "22px", marginTop: "14px"}}>Location of Visit </span>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Country")}</Form.Label>
                                      <Select
                                        options={countryList}
                                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                                        name="country_name"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "country_name")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.country_name && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.country_name}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                                      <Select
                                        options={masterStateLists}
                                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                        name="state_code"
                                        labelField={"state_name"}
                                        valueField={"state_code"}
                                        searchBy={"state_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.state_code, "state_code")
                                          setStateId(selectedData[0]?.state_code);
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.state_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.state_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("District")}</Form.Label>
                                      <Select
                                        options={masterCityStateWiseLists}
                                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                        name="city_code"
                                        labelField={"city_name"}
                                        valueField={"city_code"}
                                        searchBy={"city_name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.city_code, "city_code")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.city_code && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.city_code}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Taluka / Sub-District")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Village / City / Town")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Flat / House Number / Building Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Street Name / Road Name")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Area / Locality")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Landmark")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_address}
                                      name="hospital_address"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Pincode")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.hospital_pincode}
                                      name="hospital_pincode"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Is it Match Hospital Location")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="pharmacy_match_hospital"
                                        value="Yes"
                                        checked={formData.pharmacy_match_hospital === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="pharmacy_match_hospital"
                                        value="No"
                                        checked={formData.pharmacy_match_hospital === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <label>{t("Drug license Verify")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="drug_license_verify"
                                        value="Yes"
                                        checked={formData.drug_license_verify === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="drug_license_verify"
                                        value="No"
                                        checked={formData.drug_license_verify === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.drug_license_verify === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Remarks")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.drug_license_verify_remarks}
                                        name="drug_license_verify_remarks"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={14}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Pharmacy Collapse Field
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={14}>
                              <Card.Body>
                                <Row>
                                  <Col lg={6}>
                                    <label>{t("Are the pharmacy owner and the pharmacist different?")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="pharmacy_pharmacist_different"
                                        value="Yes"
                                        checked={formData.pharmacy_pharmacist_different === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="pharmacy_pharmacist_different"
                                        value="No"
                                        checked={formData.pharmacy_pharmacist_different === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                {formData?.pharmacy_pharmacist_different === "No" && (
                                  <Row style={{ marginTop: "3%" }}>
                                    <Col lg={4}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>{`Pharmacist Salutation`}</Form.Label>
                                        <Select
                                          options={salutationList}
                                          values={salutationList?.filter(salutation => salutation?.id === formData?.other_pharmacist_salutation)}
                                          name="other_pharmacist_salutation"
                                          labelField={"name"}
                                          valueField={"id"}
                                          searchBy={"name"}
                                          loading={loadingMaster}
                                          onChange={(selectedData) => {
                                            selectHandler2(selectedData[0]?.id, "other_pharmacist_salutation")
                                          }}
                                          styles={null}
                                        />
                                        
                                        {errorsHealth?.data?.other_pharmacist_salutation && (
                                          <div className="invalid-feedback d-block">
                                            <p className="text-danger">{errorsHealth?.data?.other_pharmacist_salutation}</p>
                                          </div>
                                        )}
                                      </Form.Group>
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Pharmacist First Name`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.other_pharmacist_first_name}
                                        name="other_pharmacist_first_name"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Pharmacist Middle Name`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.other_pharmacist_middle_name}
                                        name="other_pharmacist_middle_name"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Pharmacist Last Name`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.other_pharmacist_last_name}
                                        name="other_pharmacist_last_name"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <FormInput
                                        label={t("Pharmacist Mobile Number")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.other_pharmacist_mobile_number}
                                        name="other_pharmacist_mobile_number"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>{t(`Pharmacist Degree`)}</Form.Label>
                                        <Select
                                          options={degreeList}
                                          values={degreeList?.filter(gree => gree?.id === formData?.other_pharmacist_degree)}
                                          name="other_pharmacist_degree"
                                          labelField={"name"}
                                          valueField={"id"}
                                          searchBy={"name"}
                                          loading={false}
                                          onChange={(selectedData) => {
                                            const degreeType = degreeTypeMapping[selectedData[0]?.id];
                                            selectHandler2(selectedData[0]?.id, "other_pharmacist_degree");
                                            selectHandler2(degreeType, "degree_type");
                                          }}
                                          styles={null}
                                        />
                                        
                                        {errorsHealth?.data?.other_pharmacist_degree && (
                                          <div className="invalid-feedback d-block">
                                            <p className="text-danger">{errorsHealth?.data?.other_pharmacist_degree}</p>
                                          </div>
                                        )}
                                      </Form.Group>
                                    </Col>

                                    <Col lg={4}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>{t(`Pharmacist Degree Type`)}</Form.Label>
                                        <Select
                                          options={degreeTypeList}
                                          values={degreeTypeList?.filter(degr => degr?.id === formData?.other_pharmacist_degree_type)}
                                          name="other_pharmacist_degree_type"
                                          labelField={"name"}
                                          valueField={"id"}
                                          searchBy={"name"}
                                          loading={false}
                                          onChange={(selectedData) => {
                                            selectHandler2(selectedData[0]?.id, "other_pharmacist_degree_type")
                                          }}
                                          styles={null}
                                        />
                                        
                                        {errorsHealth?.data?.other_pharmacist_degree_type && (
                                          <div className="invalid-feedback d-block">
                                            <p className="text-danger">{errorsHealth?.data?.other_pharmacist_degree_type}</p>
                                          </div>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                )}

                                <Row style={{ marginTop: "3%" }}>
                                  <Col lg={4}>
                                    <label>{t("Pharmacy Bill Entry Verify")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="pharmacy_bill_entry_verify"
                                        value="Yes"
                                        checked={formData.pharmacy_bill_entry_verify === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="pharmacy_bill_entry_verify"
                                        value="No"
                                        checked={formData.pharmacy_bill_entry_verify === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.pharmacy_bill_entry_verify === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Remarks`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.pharmacy_bill_entry_verify_remarks}
                                        name="pharmacy_bill_entry_verify_remarks"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}
                                </Row>

                                <Row style={{ marginTop: "3%" }}>
                                  <Col lg={4}>
                                    <label>{t("Carbon Copy of Bill Verified?")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="carbon_copy_bill_verified"
                                        value="Yes"
                                        checked={formData.carbon_copy_bill_verified === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="carbon_copy_bill_verified"
                                        value="No"
                                        checked={formData.carbon_copy_bill_verified === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.carbon_copy_bill_verified === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`Remarks`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.carbon_copy_bill_verified_remarks}
                                        name="carbon_copy_bill_verified_remarks"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}
                                </Row>

                                <Row style={{ marginTop: "3%" }}>
                                  <Col lg={4}>
                                    <label>{t("Any Previous Bill Found?")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="any_previous_bill_found"
                                        value="Yes"
                                        checked={formData.any_previous_bill_found === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="any_previous_bill_found"
                                        value="No"
                                        checked={formData.any_previous_bill_found === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  {formData?.any_previous_bill_found === "No" && (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t(`General Remarks of Pharmacy Verification`)}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.any_previous_bill_found_remarks}
                                        name="any_previous_bill_found_remarks"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  )}
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane eventKey="triggerManagement"> 
                      </Tab.Pane>

                      <Tab.Pane eventKey="allocationManagement"> 
                      </Tab.Pane>

                      <Tab.Pane eventKey="reportManagement"> 
                      </Tab.Pane>

                      <Tab.Pane eventKey="submission"> 
                      </Tab.Pane>

                      <Tab.Pane eventKey="billing">
                        <Accordion id="accordion" className="mb-3">
                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey="0"
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Invoice Number
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Invoice Number")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.invoice_number}
                                      name="invoice_number"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Date of Invoice")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.date_of_invoice}
                                      name="date_of_invoice"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={1}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Single Invoices
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Member Details Available")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="member_available"
                                        value="Yes"
                                        checked={formData.member_available === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="member_available"
                                        value="No"
                                        checked={formData.member_available === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Relation With Patient")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData?.member_relation_patient}
                                      name="member_relation_patient"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{`Salutation of Member`}</Form.Label>
                                      <Select
                                        options={salutationList}
                                        values={salutationList?.filter(salutation => salutation?.id === formData?.salutation_member)}
                                        name="salutation_member"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={loadingMaster}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "salutation_member")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.salutation_member && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.salutation_member}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`First Name of Member`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_first}
                                      name="member_first"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Middle Name of Member`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_middle}
                                      name="member_middle"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={`Last Name of Member`}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_last}
                                      name="member_last"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Date of Birth")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_dob}
                                      name="member_dob"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Age")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_age}
                                      name="member_age"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Member Gender")}</Form.Label>
                                      <Select
                                        options={genderList}
                                        values={genderList?.filter(gen => gen?.id === formData?.member_gender)}
                                        name="member_gender"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "member_gender")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.member_gender && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.member_gender}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("KYC Authentication Type")}</Form.Label>
                                      <Select
                                        options={authentificationList}
                                        values={authentificationList?.filter((authen) => authen?.id === formData?.kyc_authentication)}
                                        name="kyc_authentication"
                                        placeholder={t("Select KYC Authentication Type")}
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          setFormData(prevFormData => ({
                                            ...prevFormData,
                                            'kyc_authentication': selectedData[0]?.id
                                          }));
                                        }}
                                        styles={null}
                                      />
                                      {errorsHealth?.data?.kyc_authentication && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">
                                            {errorsHealth?.data?.kyc_authentication}
                                          </p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("KYC Document Upload")}
                                      type="file"
                                      errors={errorsHealth?.data}
                                      value={formData.document_upload}
                                      name="document_upload"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  {formData?.kyc_authentication === "Other Document" ? (
                                    <Col lg={4}>
                                      <FormInput
                                        label={t("KYC Document Name")}
                                        type="text"
                                        errors={errorsHealth?.data}
                                        value={formData.kyc_document_name}
                                        name="kyc_document_name"
                                        onChange={inputHandler}
                                        containerClass={"mb-3"}
                                      />
                                    </Col>
                                  ) : (
                                    <>
                                      <Col lg={4}>
                                        <div
                                          style={{ position: "relative" }}
                                          onClick={() => window.open(`${linksMap[formData?.kyc_authentication]}` || "", "_blank")}
                                        >
                                          <FormInput
                                            label={t("KYC Authentication Check")}
                                            type="text"
                                            errors={errorsHealth?.data}
                                            value={linksMap[formData?.kyc_authentication] || ""}
                                            name="kyc_authentification_link"
                                            onChange={linksMap[formData?.kyc_authentication]}
                                            containerClass={"mb-3"}
                                            readOnly
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("KYC Authentication Photo")}
                                          type="file"
                                          errors={errorsHealth?.data}
                                          value={formData.link_verification_upload}
                                          name="link_verification_upload"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormInput
                                          label={t("Comment")}
                                          type="text"
                                          errors={errorsHealth?.data}
                                          value={formData.link_verification_comment}
                                          name="link_verification_comment"
                                          onChange={inputHandler}
                                          containerClass={"mb-3"}
                                        />
                                      </Col>
                                    </>
                                  )}

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Inception Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.member_inception_date}
                                      name="member_inception_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Inception Date Portability")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_inception_date_portability}
                                      name="member_inception_date_portability"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Member Occupation")}</Form.Label>
                                      <Select
                                        options={categoryList}
                                        values={categoryList?.filter(cate => cate?.id === formData?.member_occupation)}
                                        name="member_occupation"
                                        labelField={"name"}
                                        valueField={"id"}
                                        searchBy={"name"}
                                        loading={false}
                                        onChange={(selectedData) => {
                                          selectHandler2(selectedData[0]?.id, "member_occupation")
                                        }}
                                        styles={null}
                                      />
                                      
                                      {errorsHealth?.data?.member_occupation && (
                                        <div className="invalid-feedback d-block">
                                          <p className="text-danger">{errorsHealth?.data?.member_occupation}</p>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Joining Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.member_joining_date}
                                      name="member_joining_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Member Leaving Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.member_leaving_date}
                                      name="member_leaving_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("No of Members Covered Under Policy")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.member_covered_under_policy}
                                      name="member_covered_under_policy"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Name Of Corporate")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.corporate_name}
                                      name="corporate_name"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mb-1">
                            <Card.Header>
                              <CustomToggle
                                eventKey={2}
                                containerClass="m-0"
                                linkClass="text-dark"
                              >
                                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                Details of Port
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={2}>
                              <Card.Body>
                                <Row>
                                  <Col lg={4}>
                                    <label>{t("Portability Status")}</label>
                                    <div style={{marginTop: '7%'}}>
                                      <Form.Check 
                                        type="radio"
                                        label={t("Yes")}
                                        name="portability_status"
                                        value="Yes"
                                        checked={formData.portability_status === "Yes"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                      <Form.Check 
                                        type="radio"
                                        label={t("No")}
                                        name="portability_status"
                                        value="No"
                                        checked={formData.portability_status === "No"}
                                        onChange={inputHandler}
                                        inline
                                      />
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Portability Flag")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.portability_flag}
                                      name="portability_flag"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Portability Reason")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.portability_reason}
                                      name="portability_reason"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Previous Insured")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.previous_insured}
                                      name="previous_insured"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Previous Sum Insured")}
                                      type="text"
                                      errors={errorsHealth?.data}
                                      value={formData.previous_sum_insured}
                                      name="previous_sum_insured"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Previous Effective Start Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.previous_effective_start_date}
                                      name="previous_effective_start_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormInput
                                      label={t("Previous Expiry Date")}
                                      type="date"
                                      errors={errorsHealth?.data}
                                      value={formData.previous_expiry_date}
                                      name="previous_expiry_date"
                                      onChange={inputHandler}
                                      containerClass={"mb-3"}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <Form.Check
                                      type="checkbox"
                                      label={t("Previously Covered by Any Other Mediclaim / Health Insurance?")}
                                      name="previously_other_health_insurance"
                                      value="Yes"
                                      checked={formData.previously_other_health_insurance}
                                      onChange={(e) => inputHandler({ target: { name: "previously_other_health_insurance", value: e.target.checked } })}
                                      inline
                                    />
                                  </Col>
                                </Row>

                                <div className="d-flex justify-content-center">
                                  {loading ? 
                                    <Button color="primary" disabled>
                                      <Spinner animation="border" size="sm" />
                                      {t("Please wait ...")}
                                    </Button>
                                  :
                                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                                      {t("Submit")}
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane eventKey="dispatch"> 
                      </Tab.Pane>

                      <Tab.Pane eventKey="foPayment"> 
                      </Tab.Pane>
                    </Tab.Content>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
      {loadingHealth && (<Preloader/>)}
    </>
  );
};

export default Dashboard;
