import axios from "axios";

export const getAgentBrokerList =
  (token, limit = 10, page = 1, search = "", sort = "desc",filters) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_AGENTBROKERLIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Agent-Broker`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
            filters:filters,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_AGENTBROKERLIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
        permissions: response.data?.permissions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_AGENTBROKERLIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveAgentBrokerData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_AGENTBROKER_PENDING" });
  
      const response = await axios.post(
        `/api/admin/v1/Add-Agent-Broker`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_AGENTBROKER_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_AGENTBROKER_REJECTED", payload: error.response });
    }
  };
  

  export const getAgentBrokerListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_AGENTBROKERLISTBYID_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Agent-Broker/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_AGENTBROKERLISTBYID_FULFILLED",
        payload: response.data?.data,
        vehicleCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_AGENTBROKERLISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };


export const updateAgentBrokerData = (token, data, id) => async (dispatch) => {
  try {
    console.log('token, data', token, data);
    dispatch({ type: "UPDATE_AGENTBROKER_PENDING" });

    const response = await axios.post(
      `/api/admin/v1/Update-Agent-Broker/${id}`, // Endpoint URL with AGENTBROKER ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_AGENTBROKER_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_AGENTBROKER_REJECTED", payload: error.response });
  }
};

export const updateAgentBrokerStatusData = (token, id, status) => async (dispatch) => {
  try {
    //console.log('token, data', token, data);
    dispatch({ type: "UPDATE_AGENTBROKER_STATUS_PENDING" });
    const data={
      "status":status
    }
    const response = await axios.post(
      `/api/admin/v1/Update-Agent-Broker-Status/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_AGENTBROKER_STATUS_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_AGENTBROKER_STATUS_REJECTED", payload: error.response });
  }
};


export const deleteAgentBrokerData = (token, id) => async (dispatch) => {
  try {
    console.log('token, id', token, id);
    dispatch({ type: "DELETE_AGENTBROKER_PENDING" });

    const response = await axios.delete(
      `/api/admin/v1/Delete-Agent-Broker/${id}`, // Endpoint URL with AGENTBROKER ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DELETE_AGENTBROKER_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "DELETE_AGENTBROKER_REJECTED", payload: error.response });
  }
};