import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Table, Pagination, Button, Badge } from "react-bootstrap";
import PageTitle from "../../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { deleteHealthData, getHealthList, updateHealthStatusData } from "../../../redux/actions/HealthActions";
import RoundedPagination from "../../../Components/Paginate";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../../redux/actions/AuthActions";
import useDebounce from "../../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from "../../../util/generalUtills";
import Preloader from "../../../Components/Preloader";
import AddCaseBasicSave from "../../../Components/FormComponent/AddCaseBasicSave";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import FilterModal from "../../../Components/FilterModal";
import CountUp from "react-countup";



const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [editHealthId, setEditHealthId] = useState(null);
  const {
    AuthReducer: { auth,errors,loading },
    healthReducer: {
      healthPermissions,
      healthLists,
      loadingHealth,
      errorsHealth,
      healthCount,
      changedHealthStatus,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  

  const hideModel = () => {
    setShowModal(false);
    setEditHealthId(null);
    dispatch(getHealthList(auth.token, paginate, page, search, sort,filters));
  };

  const fetchHealthList = useCallback(() => {
    if (auth?.token) {
      dispatch(getHealthList(auth.token, paginate, page, search, sort, filters));
    }
  }, [auth, paginate, page, search, sort, dispatch]);

  useDebounce(fetchHealthList, 500, [auth, paginate, page, search, sort]);


  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])


  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? healthCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1);
  };


  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };


  const handleDeleteRequest = (id) => {
    swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, delete it!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteHealthData(auth?.token,id))
      }
    });
  };


  const handleStatusChangeRequest = (id, newStatus) => {
    swal.fire({
      title: "Are you sure?",
      text: `Do you want to change the status to ${newStatus}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateHealthStatusData(auth.token, id, newStatus));
      }
    });
  };


  useEffect(() => {
    if(changedHealthStatus?.status)
    {
      swal.fire({
        show: true,
        title: "Deleted!",
        text: changedHealthStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_HEALTH_STATUS' });
      dispatch(getHealthList(auth?.token, paginate, page, search, sort,filters));
    }
    else if(changedHealthStatus.success)
    {
      swal.fire({
        show: true,
        title: "Great !",
        text: changedHealthStatus.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_HEALTH_STATUS' });
      dispatch(getHealthList(auth?.token, paginate, page, search, sort,filters));
    }
  },[changedHealthStatus])


  const [filters, setFilters] = useState({});
  const [modal, setModal] = useState({ show: false, key: "", type: "", value: "" });


  const handleFilterClick = (key) => {
    if (!filters[key]) {
      setFilters((prev) => ({
        ...prev,
        [key]: { type: "", value: "" },
      }));
    }
    setModal({ show: true, key, type: filters[key]?.type || "", value: filters[key]?.value || "" });
  };


  const handleModalChange = (field, value) => {
    setModal((prev) => ({ ...prev, [field]: value }));
  };


  const handleApplyFilter = () => {
    setFilters((prev) => ({
      ...prev,
      [modal.key]: { type: modal.type, value: modal.value },
    }));
    setModal({ show: false, key: "", type: "", value: "" });
  };


  const handleRemoveFilter = (key) => {
    setFilters((prev) => {
      const updatedFilters = { ...prev };
      delete updatedFilters[key];
      return updatedFilters;
    });
    setModal({ show: false, key: "", type: "", value: "" });
  };


  useEffect(() => {
    console.log(filters);
    dispatch(getHealthList(auth.token, paginate, page, search, sort,filters));
  },[filters])


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Case Management"), path: "/Health" },
          { label: t("Health"), path: "/Health", active: true },
        ]}
        title={t("Health Dashboard")}
      />


      <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
        <Row style={{ display: "inline-flex", flexWrap: "nowrap" }}>
          <Col md={2} lg={1} style={{ width: "8%" }}>
            <Card>
              <Card.Body>
                <h4 className="mt-0 font-16">FO Count</h4>
                <h2 className="text-primary my-3 text-right">
                  <span>
                    <CountUp
                      duration={1}
                      end="31570"
                    />
                  </span>
                </h2>
              </Card.Body>
            </Card>
          </Col>

          <Col md={2} lg={1} style={{ width: "8%" }}>
            <Card>
              <Card.Body>
                <h4 className="mt-0 font-16">FO Count</h4>
                <h2 className="text-primary my-3 text-right">
                  <span>
                    <CountUp
                      duration={1}
                      end="31570"
                    />
                  </span>
                </h2>
              </Card.Body>
            </Card>
          </Col>

          <Col md={2} lg={1} style={{ width: "8%" }}>
            <Card>
              <Card.Body>
                <h4 className="mt-0 font-16">FO Count</h4>
                <h2 className="text-primary my-3 text-right">
                  <span>
                    <CountUp
                      duration={1}
                      end="31570"
                    />
                  </span>
                </h2>
              </Card.Body>
            </Card>
          </Col>

          <Col md={2} lg={1} style={{ width: "8%" }}>
            <Card>
              <Card.Body>
                <h4 className="mt-0 font-16">FO Count</h4>
                <h2 className="text-primary my-3 text-right">
                  <span>
                    <CountUp
                      duration={1}
                      end="31570"
                    />
                  </span>
                </h2>
              </Card.Body>
            </Card>
          </Col>

          <Col md={2} lg={1} style={{ width: "8%" }}>
            <Card>
              <Card.Body>
                <h4 className="mt-0 font-16">FO Count</h4>
                <h2 className="text-primary my-3 text-right">
                  <span>
                    <CountUp
                      duration={1}
                      end="31570"
                    />
                  </span>
                </h2>
              </Card.Body>
            </Card>
          </Col>

          <Col md={2} lg={1} style={{ width: "8%" }}>
            <Card>
              <Card.Body>
                <h4 className="mt-0 font-16">FO Count</h4>
                <h2 className="text-primary my-3 text-right">
                  <span>
                    <CountUp
                      duration={1}
                      end="31570"
                    />
                  </span>
                </h2>
              </Card.Body>
            </Card>
          </Col>

          <Col md={2} lg={1} style={{ width: "8%" }}>
            <Card>
              <Card.Body>
                <h4 className="mt-0 font-16">FO Count</h4>
                <h2 className="text-primary my-3 text-right">
                  <span>
                    <CountUp
                      duration={1}
                      end="31570"
                    />
                  </span>
                </h2>
              </Card.Body>
            </Card>
          </Col>

          <Col md={2} lg={1} style={{ width: "8%" }}>
            <Card>
              <Card.Body>
                <h4 className="mt-0 font-16">FO Count</h4>
                <h2 className="text-primary my-3 text-right">
                  <span>
                    <CountUp
                      duration={1}
                      end="31570"
                    />
                  </span>
                </h2>
              </Card.Body>
            </Card>
          </Col>

          <Col md={2} lg={1} style={{ width: "8%" }}>
            <Card>
              <Card.Body>
                <h4 className="mt-0 font-16">FO Count</h4>
                <h2 className="text-primary my-3 text-right">
                  <span>
                    <CountUp
                      duration={1}
                      end="31570"
                    />
                  </span>
                </h2>
              </Card.Body>
            </Card>
          </Col>

          <Col md={2} lg={1} style={{ width: "8%" }}>
            <Card>
              <Card.Body>
                <h4 className="mt-0 font-16">FO Count</h4>
                <h2 className="text-primary my-3 text-right">
                  <span>
                    <CountUp
                      duration={1}
                      end="31570"
                    />
                  </span>
                </h2>
              </Card.Body>
            </Card>
          </Col>

          <Col md={2} lg={1} style={{ width: "8%" }}>
            <Card>
              <Card.Body>
                <h4 className="mt-0 font-16">FO Count</h4>
                <h2 className="text-primary my-3 text-right">
                  <span>
                    <CountUp
                      duration={1}
                      end="31570"
                    />
                  </span>
                </h2>
              </Card.Body>
            </Card>
          </Col>

          <Col md={2} lg={1} style={{ width: "8%" }}>
            <Card>
              <Card.Body>
                <h4 className="mt-0 font-16">FO Count</h4>
                <h2 className="text-primary my-3 text-right">
                  <span>
                    <CountUp
                      duration={1}
                      end="31570"
                    />
                  </span>
                </h2>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}></Col>
                  <Col lg={9}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="button-list">
                        {(Object.keys(filters).length>0) && (
                          <>
                            <Button variant="warning" onClick={() => setFilters({})}>
                              <i className="mdi mdi-filter-off me-1"></i>Clear All Filter
                            </Button>
                          </>
                        )}

                        {(healthPermissions.add ) && (
                          <>
                            <Button variant="success" onClick={() => {
                              setShowModal(true)
                              setEditHealthId(null)
                            }} >
                              <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                            </Button>
                          </>
                        )}

                        <Button variant="success" onClick={() => {
                          setShowModal(true)
                          setEditHealthId(null)
                        }} >
                          <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                        </Button>

                        {(healthPermissions.print ) && (
                          <>
                            <Button variant="dark">
                              <i className="mdi mdi-printer me-1"></i>Print
                            </Button>
                          </>
                        )}

                        {(healthPermissions.excel ) && (
                          <>
                            <Button variant="info">
                              <i className="mdi mdi-file-excel me-1"></i>Excel
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div>
                <Table className="mb-0 table-sm" responsive="sm" striped bordered hover >
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>
                        Health Name<br />
                        {healthPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("pharmacy_name")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                      </th>

                      <th>
                        Email<br />
                        {healthPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("email")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                      </th>

                      <th>
                        Mobile<br />
                        {healthPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("mobile_number")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>

                      <th>
                        T.P.A. Name<br />
                        {healthPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("tpa_name")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>

                      <th>
                        T.P.A. Number<br />
                        {healthPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("tpa_number")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>

                      <th>
                        State<br />
                        {healthPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("state_name")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>

                      <th>
                        City<br />
                        {healthPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("city_name")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>

                      <th>
                        Address<br />
                        {healthPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("address")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>
                      <th>Status<br /></th>
                      <th>Registered At<br /></th>
                      {(healthPermissions.edit || healthPermissions.delete) && (
                        <>
                          <th>Action</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr key="1"
                      onClick={() => navigate(`/Other-Details/1`)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>1</td>
                      <td>Demo</td>
                      <td>Demo123@gmail.com</td>
                      <td>1234567891</td>
                      <td>Demo Name</td>
                      <td>1987654321</td>
                      <td>Maharashtra</td>
                      <td>Pune</td>
                      <td>Pune</td>
                      <td><Badge className={"bg-success"}>Active</Badge></td>
                      <td>17 Jan 2025 09:45:01</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
                <div class="d-inline-block me-3">
                  <pharmacyel class="me-1">Display :</pharmacyel>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(healthCount / paginate)}</strong> </span>
                <RoundedPagination currentPage={page} totalPages={Math.ceil(healthCount / paginate)} onPageChange={(pageNumber) => setPage(pageNumber)}/>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddCaseBasicSave show={showModal} onHide={() => hideModel()} pharmacyId={editHealthId}  />

      <FilterModal
        show={modal.show}
        onHide={() => setModal({ show: false, key: "", type: "", value: "" })}
        modalData={modal}
        onApplyFilter={handleApplyFilter}
        onRemoveFilter={handleRemoveFilter}
        onChange={handleModalChange}
      />
      {loadingHealth && (<Preloader/>)}
    </>
  );
};

export default Dashboard;
