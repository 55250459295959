const initialState = {
    pathologyPermissions:{},
    pathologyLists: [],
    pathologyStatus: [],
    changedPathologyStatus : [],
    loadingPathology: false,
    errorsPathology: {}
}

const  pathologyReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PATHOLOGYLIST_PENDING":
        case "ADD_PATHOLOGY_PENDING":
        case "UPDATE_PATHOLOGY_PENDING":
        case "UPDATE_PATHOLOGY_STATUS_PENDING":
        case "DELETE_PATHOLOGY_PENDING":
        case "GET_PATHOLOGYLISTBYID_PENDING":
            return { ...state, loadingPathology: true };

        case "GET_PATHOLOGYLIST_FULFILLED":
            return {
                ...state,
                loadingPathology: false,
                pathologyLists: action.payload,
                pathologyCount: action.count,
                pathologyPermissions:action.permissions,
            };

        case "ADD_PATHOLOGY_FULFILLED":
            return {
                ...state,
                loadingPathology: false,
                changedPathologyStatus: action.payload,
            };

        case "UPDATE_PATHOLOGY_FULFILLED":
            return {
                ...state,
                loadingPathology: false,
                changedPathologyStatus: action.payload,
            };

        case "UPDATE_PATHOLOGY_STATUS_FULFILLED":
            return {
                ...state,
                loadingPathology: false,
                changedPathologyStatus: action.payload,
            };

        case "GET_PATHOLOGYLISTBYID_FULFILLED":
            return {
                ...state,
                loadingPathology: false,
                pathologyLists: action.payload
            };

        case "DELETE_PATHOLOGY_FULFILLED":
            return {
                ...state,
                loadingPathology: false,
                changedPathologyStatus: action.payload
            };

        case "RESET_DELETE_PATHOLOGYS_STATUS":
            return {
                ...state,
                loadingPathology: false,
                changedPathologyStatus: [],
                errorsPathology:{}
            };

        case "GET_PATHOLOGYLIST_REJECTED":
        case "ADD_PATHOLOGY_REJECTED":
        case "UPDATE_PATHOLOGY_REJECTED":
        case "UPDATE_PATHOLOGY_STATUS_REJECTED":
        case "DELETE_PATHOLOGY_REJECTED":
        case "GET_PATHOLOGYLISTBYID_REJECTED":
            return {
                ...state,
                loadingPathology: false,
                errorsPathology: action.payload
            };

        default:
            return state;
    }
}

export default pathologyReducer;