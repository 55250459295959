import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Table, Button, Accordion, Spinner, Form, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getBasicSaveListById, saveBasicSaveData, updateBasicSaveData } from "../../redux/actions/BasicSaveActions";
import FormInput from "../FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllCityStateWiseList, getAllStateList, getMasterDesignationList, getMasterInsuranceList, 
  getMasterQualificationList, getMasterClaimTypeList, getMasterHospitalList } from "../../redux/actions/MasterActions";
import Preloader from "../Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import CustomToggle from "../CustomToggle";
import '../../styles/common.css'

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddBasicSave = ({ show, onHide, basicSaveId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [formData, setFormData] = useState({});

  const clientTypeList = [
    {
      id: "Insurance Company",
      name: 'Insurance Company'
    },
    {
      id: "TPA",
      name: 'TPA'
    },
    {
      id: "Vendor",
      name: 'Vendor'
    },
    {
      id: "Others",
      name: 'Others'
    }
  ]

  const insuredNameList = [
    {
      id: "Demo",
      name: 'Demo'
    },
    {
      id: "Demo1",
      name: 'Demo1'
    },
    {
      id: "Demo2",
      name: 'Demo2'
    }
  ]

  const salutationList = [
    {
      id: "Mr",
      name: 'Mr'
    },
    {
      id: "Mrs",
      name: 'Mrs'
    }
  ]

  const countryList = [
    {
      id: "India",
      name: 'India'
    }
  ]
  

  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    patientDataReducer: {
      loadingPatientData,
      errorsPatientData,
      patientDataLists,
      changedPatientDataStatus,
    },
    masterReducer: {
      loadingMaster,
      masterStateLists,
      masterCityStateWiseLists,
      masterInsuranceList,
      masterClaimTypeList,
      masterHospitalList,
      masterDesignationList,
    },
  } = useSelector((state) => state);


  useEffect(() => {
    if (auth?.token)
    {
      dispatch(getAllStateList(auth.token));
      dispatch(getMasterInsuranceList(auth.token));
      dispatch(getMasterQualificationList(auth.token));
      dispatch(getMasterDesignationList(auth.token));
      dispatch(getMasterClaimTypeList(auth.token));
      dispatch(getMasterHospitalList(auth.token));
    }
  }, [auth?.token]);

  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);


  const [validated, setValidated] = useState(false);
  const autocompleteRef = useRef(null);
  

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(basicSaveId){
      dispatch(updateBasicSaveData(auth?.token, formData, basicSaveId));
    } else {
      dispatch(saveBasicSaveData(auth?.token, formData));
    }
  };


  useEffect(() => {
    setFormData({})
    if(basicSaveId) {
      dispatch(getBasicSaveListById(auth?.token, basicSaveId));
    }
  }, [auth, basicSaveId]);


  useEffect(() => {
    if(basicSaveId && patientDataLists && Object.keys(patientDataLists).length > 0) {
      setFormData({
        lab_name: patientDataLists?.lab_name,
        lat: patientDataLists?.lat,
        lng: patientDataLists?.lng,
        address: patientDataLists?.address,
        email: patientDataLists?.email || "",
        mobile_number: patientDataLists?.mobile_number,
        tpa_name: patientDataLists?.tpa_name,
        tpa_number: patientDataLists?.tpa_number,
        state_code: patientDataLists?.lab_cities?.cities_state?.state_code,
        city_code: patientDataLists?.lab_cities?.city_code,
      });
    }
  }, [patientDataLists]);


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };


  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };


  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);


  const handleInputChange = async (e) => {
    const input = e.target.value;
    setFormData({ ...formData, search_name: input });

    if (input.length > 2)
    {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({
        input,
        componentRestrictions: { country: "IN" },
        types: ["doctor"],
      }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
          setShowSuggestions(true);
        }
      });
    }
    else
    {
      setShowSuggestions(false);
    }
  };


  useEffect(() => {
    if (changedPatientDataStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedPatientDataStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }, [changedPatientDataStatus, dispatch]);


  const formattedExpiryDate = formData.expiry_date ? formData.expiry_date.split('T')[0] : '';
  const formattedRenewalDate = formData.renewal_date ? formData.renewal_date.split('T')[0] : '';


  return (
    <Modal show={show} onHide={onHide} size="xl" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add or Edit Basic Save")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorsPatientData?.data?.message && (
          <div className="alert alert-danger">
            {errorsPatientData?.data?.message}
          </div>
        )}

        <Card className="mb-1">
          <Card.Body>
            <Row>
              <Col lg={3}>
                <FormInput
                  label={t("Date of Appointment")}
                  type="date"
                  errors={errorsPatientData?.data}
                  value={formData.appointment_date}
                  name="appointment_date"
                  onChange={inputHandler}
                  containerClass={"mb-3"}
                />
              </Col>

              <Col lg={3}>
                <FormInput
                  label={t("Comment of Appointment")}
                  type="text"
                  errors={errorsPatientData?.data}
                  value={formData.appointment_remarks}
                  name="appointment_remarks"
                  onChange={inputHandler}
                  containerClass={"mb-3"}
                />
              </Col>

              <Col lg={3}>
                <FormInput
                  label={t("Date of Visit")}
                  type="date"
                  errors={errorsPatientData?.data}
                  value={formData.visit_date}
                  name="visit_date"
                  onChange={inputHandler}
                  containerClass={"mb-3"}
                />
              </Col>

              <Col lg={3}>
                <FormInput
                  label={t("Date of Revisit")}
                  type="date"
                  errors={errorsPatientData?.data}
                  value={formData.revisit_date}
                  name="revisit_date"
                  onChange={inputHandler}
                  containerClass={"mb-3"}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Accordion id="accordion" className="mb-3">
          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey="0"
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Basic Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Patient Name")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.patient_name}
                      name="patient_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Name of Insured")}</Form.Label>
                      <Select
                        options={insuredNameList}
                        values={insuredNameList?.filter(insured => insured?.id === formData?.insured_name)}
                        name="insured_name"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "insured_name")
                        }}
                        styles={null}
                      />
                      
                      {errorsPatientData?.data?.insured_name && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsPatientData?.data?.insured_name}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Salutation")}</Form.Label>
                      <Select
                        options={salutationList}
                        values={salutationList?.filter(salutation => salutation?.id === formData?.owner_salutation)}
                        name="owner_salutation"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "owner_salutation")
                        }}
                        styles={null}
                      />
                      
                      {errorsPatientData?.data?.owner_salutation && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsPatientData?.data?.owner_salutation}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Client First Name")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.tpa_first_name}
                      name="tpa_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Client Middle Name")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.tpa_middle_name}
                      name="tpa_middle_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Client Last Name")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.tpa_last_name}
                      name="tpa_last_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <label>{t("Gender")}</label>
                    <div style={{marginTop: '7%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Male")}
                        name="gender"
                        value="Male"
                        checked={formData.gender === "Male"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("Female")}
                        name="gender"
                        value="Female"
                        checked={formData.gender === "Female"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("Others")}
                        name="gender"
                        value="Others"
                        checked={formData.gender === "Others"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={1}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Age Verification
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={1}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Date of Birth")}
                      type="date"
                      errors={errorsPatientData?.data}
                      value={formData.date_of_birth}
                      name="date_of_birth"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Age")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.age_text}
                      name="age_text"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Any Discrepancy in Age")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.discrepancy_age}
                      name="discrepancy_age"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col><Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Country")}</Form.Label>
                      <Select
                        options={countryList}
                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                        name="country_name"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "country_name")
                        }}
                        styles={null}
                      />
                      
                      {errorsPatientData?.data?.country_name && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsPatientData?.data?.country_name}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                      <Select
                        options={masterStateLists}
                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                        name="state_code"
                        labelField={"state_name"}
                        valueField={"state_code"}
                        searchBy={"state_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.state_code, "state_code")
                          setStateId(selectedData[0]?.state_code);
                        }}
                        styles={null}
                      />
                      
                      {errorsPatientData?.data?.state_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsPatientData?.data?.state_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("District")}</Form.Label>
                      <Select
                        options={masterCityStateWiseLists}
                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                        name="city_code"
                        labelField={"city_name"}
                        valueField={"city_code"}
                        searchBy={"city_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.city_code, "city_code")
                        }}
                        styles={null}
                      />
                      
                      {errorsPatientData?.data?.city_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsPatientData?.data?.city_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Taluka / Sub-District")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Village / City / Town")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Flat / House Number / Building Name")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Street Name / Road Name")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Area / Locality")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Landmark")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Pincode")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.hospital_pincode}
                      name="hospital_pincode"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Permanent Address")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData?.permanent_address}
                      name="permanent_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Address (As Per Field)")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData?.address_per_field}
                      name="address_per_field"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Latitude")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData?.hospital_lat}
                      name="hospital_lat"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Longitude")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData?.hospital_lng}
                      name="hospital_lng"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Patient UHID")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData?.patient_uhid}
                      name="patient_uhid"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Patient Live Photo")}
                      type="file"
                      errors={errorsPatientData?.data}
                      value={formData?.patient_live_photo}
                      name="patient_live_photo"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Patient Photo (As Per Aadhaar Card)")}
                      type="file"
                      errors={errorsPatientData?.data}
                      value={formData?.patient_photo_aadhaar}
                      name="patient_photo_aadhaar"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Residential Photo")}
                      type="file"
                      errors={errorsPatientData?.data}
                      value={formData?.residential_photo}
                      name="residential_photo"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Wallmount")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData?.wallmount}
                      name="wallmount"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Face Match")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData?.face_match}
                      name="face_match"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <label>{t("Marital Status")}</label>
                    <div style={{marginTop: '7%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Married")}
                        name="marital_status"
                        value="Married"
                        checked={formData.marital_status === "Married"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("Unmarried")}
                        name="marital_status"
                        value="Unmarried"
                        checked={formData.marital_status === "Unmarried"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={2}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Contact Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={2}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Contact Number 1")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.contact_number_one}
                      name="contact_number_one"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Contact Number 2")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.contact_number_two}
                      name="contact_number_two"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Email ID")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.email_id}
                      name="email_id"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={3}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                KYC Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={3}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Aadhaar Card No.")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.aadhaar_card_number}
                      name="aadhaar_card_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Pan Card No.")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.pan_card_number}
                      name="pan_card_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Ration Card No.")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.ration_card_number}
                      name="ration_card_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Voter ID Card No.")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.voter_id_number}
                      name="voter_id_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Driving License")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.driving_license}
                      name="driving_license"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Passport No.")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.passport_number}
                      name="passport_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={4}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Task Management
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={4}>
              <Card.Body>
                <Row>
                  
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={5}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Bullet Management
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={5}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Claim Team")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.claim_team}
                      name="claim_team"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Allocation Team")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.allocation_team}
                      name="allocation_team"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={6}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Status
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={6}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Status")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.bucket}
                      name="bucket"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Bucket")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.bucket}
                      name="bucket"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={7}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Red Alert
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={7}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator Username")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.alert_creator_username}
                      name="alert_creator_username"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator ID")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.alert_creator_id}
                      name="alert_creator_id"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Date & Time of Alert")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.date_time_alert}
                      name="date_time_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Comment on Alert")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.comment_alert}
                      name="comment_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={8}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                User Remarks
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={8}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Comments")}
                      type="text"
                      errors={errorsPatientData?.data}
                      value={formData.comments}
                      name="comments"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <div className="d-flex justify-content-center">
          {loading ? 
            <Button color="primary" disabled>
              <Spinner animation="border" size="sm" />
              {t("Please wait ...")}
            </Button>
          :
            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
              {t("Submit")}
            </Button>
          }
        </div>
      </Modal.Body>
      {loadingPatientData && <Preloader />}
    </Modal>
  );
};

export default AddBasicSave;
