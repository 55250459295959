const initialState = {
    pharmacyPermissions:{},
    pharmacyLists: [],
    pharmacyStatus: [],
    changedPharmacyStatus : [],
    loadingPharmacy: false,
    errorsPharmacy: {}
}

const  pharmacyReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PHARMACYLIST_PENDING":
        case "ADD_PHARMACY_PENDING":
        case "UPDATE_PHARMACY_PENDING":
        case "UPDATE_PHARMACY_STATUS_PENDING":
        case "DELETE_PHARMACY_PENDING":
        case "GET_PHARMACYLISTBYID_PENDING":
            return { ...state, loadingPharmacy: true };

        case "GET_PHARMACYLIST_FULFILLED":
            return {
                ...state,
                loadingPharmacy: false,
                pharmacyLists: action.payload,
                pharmacyCount: action.count,
                pharmacyPermissions:action.permissions,
            };

        case "ADD_PHARMACY_FULFILLED":
            return {
                ...state,
                loadingPharmacy: false,
                changedPharmacyStatus: action.payload,
            };

        case "UPDATE_PHARMACY_FULFILLED":
            return {
                ...state,
                loadingPharmacy: false,
                changedPharmacyStatus: action.payload,
            };

        case "UPDATE_PHARMACY_STATUS_FULFILLED":
            return {
                ...state,
                loadingPharmacy: false,
                changedPharmacyStatus: action.payload,
            };

        case "GET_PHARMACYLISTBYID_FULFILLED":
            return {
                ...state,
                loadingPharmacy: false,
                pharmacyLists: action.payload
            };

        case "DELETE_PHARMACY_FULFILLED":
            return {
                ...state,
                loadingPharmacy: false,
                changedPharmacyStatus: action.payload
            };

        case "RESET_DELETE_PHARMACYS_STATUS":
            return {
                ...state,
                loadingPharmacy: false,
                changedPharmacyStatus: [],
                errorsPharmacy:{}
            };

        case "GET_PHARMACYLIST_REJECTED":
        case "ADD_PHARMACY_REJECTED":
        case "UPDATE_PHARMACY_REJECTED":
        case "UPDATE_PHARMACY_STATUS_REJECTED":
        case "DELETE_PHARMACY_REJECTED":
        case "GET_PHARMACYLISTBYID_REJECTED":
            return {
                ...state,
                loadingPharmacy: false,
                errorsPharmacy: action.payload
            };

        default:
            return state;
    }
}

export default pharmacyReducer;